$content-tile-paragraph-margin-top: $base-spacing-2;
$content-tile-content-margin-top:   $base-spacing-2;

$content-tile-bg-min-height:        $base-spacing-14; //112px
$content-tile-bg-content-padding:   $base-spacing-6 $base-spacing-7;
$content-tile-bg-1-bg-color:        $color-white;
$content-tile-text-color:           $color-black;

.c-content-tile {
    .content-tile-container {
        background-color: $content-tile-bg-1-bg-color;
        display: flex;
        flex-direction: column;
        min-height: $content-tile-bg-min-height;
        padding: $content-tile-bg-content-padding;
        height: 100%;

        .title {
            margin: 0 0 $base-spacing-2 0;
        }

        .btn-tag {
            align-self: flex-start;
            margin-bottom: $base-spacing-2;
        }

        .description {
            color: $content-tile-text-color;
        }

        .link {
            @include body-normal-heavy;
            color: $color-primary-1;
            margin-top: auto;
            text-decoration: none;

            svg {
                color: $color-primary-2;
                vertical-align: middle;
            }

            &:hover,
            &:focus {
                color: $color-primary-2;
            }
        }
    }
}

@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    .c-content-tile {
        .content-tile-container {
            padding: $base-spacing-3 $base-spacing-4;
        }
    }
}
