.c-pagination-minimal {
    width: 100%;

    .previous {
        width: 50%;

        a,
        button {
            justify-content: flex-start;
            padding-left: $base-spacing-2;
            margin-right: 1px;

            .svg-icon {
                margin-right: $base-spacing-1;
            }
        }
    }

    .next {
        width: 50%;

        a,
        button {
            justify-content: flex-end;
            padding-right: $base-spacing-2;
            margin-left: 1px;

            .svg-icon {
                margin-left: $base-spacing-1;
            }
        }
    }
}
