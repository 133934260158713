.s-three-col-articles {
    padding-top: $base-spacing-16;
    padding-bottom: $base-spacing-16;

    h2 {
        text-align: center;
        @include display-medium;
        margin-bottom: $base-spacing-6;
        text-transform: none;
    }

    a[class$='-article'] {
        @include stack();
    }
}

@media (min-width: $screen-sm) {
    .s-three-col-articles {
        a[class*='-article'] {
            margin-bottom: $base-spacing-3;
            @include col(6);
        }
    }
}

@media (min-width: $screen-md-max) {
    .s-three-col-articles {
        a[class*='-article'] {
            @include col(4);
        }
    }
}
