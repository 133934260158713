$multi-checkbox-filter-bg-color:      $color-white;
$multi-checkbox-filter-panel-padding: $base-spacing-2;

.multi-checkbox-filter {
    position: relative;
    width: 100%;
    height: auto;
    background-color: $multi-checkbox-filter-bg-color;
    z-index: 1;

    .multi-checkbox-filter-title-text {
        margin: 0;
    }

    .multi-checkbox-filter-panel {
        @include list-reset;
        padding-left: $multi-checkbox-filter-panel-padding;
        padding-right: $multi-checkbox-filter-panel-padding;
        height: 0;
        width: 100%;
        border: 1px solid $input-border-color;
        border-top: none;
        background-color: $input-bg;
        z-index: 10;
        overflow: hidden;
        overflow-y: auto;
        visibility: hidden;
        transition: all 200ms ease-in-out;
        position: static;
    }

    &.is-open {
        z-index: 2;

        .multi-checkbox-filter-panel {
            padding: $multi-checkbox-filter-panel-padding;
            height: auto;
            visibility: visible;
        }

        .input-wrapper::after {
            transform: rotate(180deg);
        }
    }
}

@media (min-width: $screen-sm) {
    .multi-checkbox-filter {
        .multi-checkbox-filter-panel {
            position: absolute;
        }
    }
}
