$primary-link-height:               56px;
$primary-link-color:                $color-black;
$primary-link-hover-color:          $color-primary-2;
$primary-link-background:     transparent;

$has-submenu-icon-width:            24px;
$has-submenu-icon-color:            $color-primary-4;
$has-submenu-icon-hover-color:      $color-primary-2;

$menu-transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1), visibility 0s linear 0s, -webkit-transform 0.3s ease;

.mobile-nav .c-primary-links {
    width: 100%;
    height: 100%;
    transition: $menu-transition;
    margin: $base-spacing-3 0 0;

    li {
        // Fix position issue across mobile menu
        position: static;
    }

    .link-holder {
        @include list-reset;
        max-width: 368px;
        width: 100%;
        margin: 0 auto;
    }

    .link-breaker {
        border-top: 1px solid $color-shade-2;
        width: 100%;
        height: 1px;
        margin: $base-spacing-2 0;
    }

    .submenu-child-container {
        max-width: 368px;
        padding: 0 $base-spacing-3;
        width: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
    }

    .submenu-doc-icon {
        margin-right: $base-spacing-1;
    }

    .submenu-title {
        flex-grow: 1;
        @include display-heading-medium;
        color: $color-black;
        text-transform: unset; // Override uppercase setting of display heading small

        &.light-link {
            @include body-normal;
            color: $color-shade-3;
        }
    }

    .submenu-container {
        display: flex;
        align-items: center;
        height: $primary-link-height;
        max-width: 368px;
        width: 100%;
        margin: 0 auto;
        padding: $base-spacing-2 $base-spacing-3;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            width: calc(100% - 2 * #{$base-spacing-3});
        }
    }

    a {
        display: flex;
        align-items: center;
        color: $primary-link-color;
        @include body-small;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $primary-link-hover-color;
        }
    }

    .submenu-container {
        justify-content: space-between;
    }

    .sub-menu-toggle {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        color: $has-submenu-icon-color;

        &:hover,
        &:focus {
            color: $primary-link-hover-color;
        }
    }

    .back-link-holder {
        position: absolute;
        top: -60px;
        width: 100%;
    }

    .back {
        @include display-heading-medium ;
        border-radius: 0;
        padding: $base-spacing-5 0 $base-spacing-3;
        background-color: $color-primary-1;
        color: $color-white;
        text-transform: unset;

        svg {
            width: 24px;
            margin-right: $base-spacing-2;
            color: $color-white;
        }

        &:hover,
        &:focus {
            svg {
                color: $primary-link-hover-color;
            }
        }
    }

    // submenu at any level
    .submenu-child-nav {
        top: 0;
        display: none;
        left: 100%;
        width: 100%;
        position: fixed;
        transition: $menu-transition;
        margin: 0;
        padding-top: $base-spacing-6;

        &.submenu-active {
            display: block;
            position: absolute;
        }
    }
}
