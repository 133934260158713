$overlap-content-position: -$base-spacing-1 * 18;
$overlap-content-position-xs: $base-spacing-5;
$social-links-width: 87px;

.s-content-block {
    position: relative;

    .content {
        @include vertical-rhythm;

        h2 {
            @include display-heading-xx-large;
        }
    }

    &.bg-theme-4 .content,
    &.bg-theme-5 .content {
        ul > li::before {
            background-color: $color-black;
        }

        ol > li::before {
            color: $color-black;
        }
    }

    &.rounded-corners {
        border-radius: $base-spacing-3;
    }

    &.overlap-content {
        padding: 0;

        .content {
            margin-top: $overlap-content-position-xs;
        }
    }

    .c-print-social-share {
        background-color: $color-primary-1;
    }

    .social-share-mobile {
        background-color: $color-primary-3;
        position: sticky;
        bottom: 0;
        z-index: 1;
    }

    .container {
        position: relative;
    }
}

.s-content-block + .s-content-block:not(.no-spacer),
.s-content-block + a + .s-content-block:not(.no-spacer) {
    .content {
        position: relative;

        &::before {
            content: '';
            height: 1px;
            background-color: $color-primary-shade-2;
            position: absolute;
            top: -$base-section-padding;
            left: 0;
            right: 0;
        }
    }
}

@media (min-width: $screen-sm) {
    .s-content-block {
        .content {
            @include col(12);
        }

        &.overlap-content {
            .content {
                background-color: $color-white;
                margin-top: $overlap-content-position;
                padding-bottom: $base-spacing-10;
                padding-left: $base-spacing-10;
                padding-right: $base-spacing-10;
            }
        }

        .c-article-header p {
            margin-top: $base-spacing-3;
        }
    }

    .s-content-block + .s-content-block,
    .s-content-block + a + .s-content-block {
        .content {
            &::before {
                top: -$base-section-desktop-padding;
            }
        }
    }

    .s-banner-article + .s-content-block > .container {
        background-color: $color-white;
        margin-top: -144px;
        padding-top: $base-spacing-5;
        border-top-left-radius: $base-spacing-4;
        border-top-right-radius: $base-spacing-4;
    }
}

@media (max-width: $screen-md-max) {
    .s-content-block {
        .social-share-desktop {
            display: none;
        }
    }
}

@media (min-width: $screen-md) {
    .s-content-block {
        .social-share-desktop {
            display: block;
            right: 0;
            position: absolute;
            top: 0;
            opacity: 1;
            transition: opacity $base-transition-slow $base-transition-curve;

            &.sticky {
                position: fixed;
                top: 190px;
                bottom: auto;
                opacity: 1;
            }

            &.stop {
                opacity: 0;
            }
        }

        &.overlap-content .social-share-desktop {
            top: 190px;
            margin-top: 0;
            transition:
                opacity $base-transition-slow $base-transition-curve,
                margin $base-transition-slow $base-transition-curve;

            &.sticky {
                margin-top: 190px;
                top: 0;
            }
        }

        .social-share-mobile {
            display: none;
        }

        .content {
            @include col(12);
            position: relative;

            &.has-social-share {
                padding-right: 90px;
            }
        }
    }

    .s-banner-article + .s-content-block {
        > .container {
            padding: $base-spacing-8 $base-spacing-5 $base-spacing-5;
            border-top-left-radius: $base-spacing-4;
            border-top-right-radius: $base-spacing-4;
        }

        .social-share-desktop {
            right: -172px;
            top: 118px;
        }
    }
}

@media (min-width: $screen-lg) {
    .s-banner-article + .s-content-block {
        > .container {
            max-width: 1155px;
            margin: -200px auto 0;
            padding: $base-spacing-8 $base-spacing-10 $base-spacing-6;
        }

        .social-share-desktop {
            right: -172px;
            top: 118px;

            &.sticky {
                top: 46px;
            }
        }
    }
}
