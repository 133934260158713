$dot-size:                          $base-spacing-2;
$dots-spacing:                      0;
$dot-margin-right:                  $base-spacing-2;
$dot-bg:                            $color-shade-3;
$dot-border-color:                  transparent;
$dot-active-bg:                     transparent;
$dot-active-border-color:           $color-primary-1;

// Custom slider dots
.slick-dots {
    position: absolute;
    bottom: $dots-spacing;
    width: 100%;
    margin: 0;
    list-style-type: none;
    text-align: center;
    margin-bottom: 0 !important;

    > li {
        margin: 0;
        padding: 0;
        display: inline-block;

        &::before {
            display: none;
        }
    }
}

.slick-dots li {
    position: relative;
    width: $dot-size;
    height: $dot-size;
    margin-right: $dot-margin-right;

    &:last-of-type {
        margin-right: 0;
    }
}

.slick-dots button {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    background-color: $dot-bg;
    width: $dot-size;
    height: $dot-size;
    border: 2px solid $dot-border-color;
    border-radius: 50%;
    text-align: center;
    font-size: 0;

    &:active,
    &:focus {
        outline: none;
    }

    &:focus {
        background-color: $dot-active-bg;
        border-color: $dot-active-border-color;
    }
}

.slick-active > button {
    background-color: $dot-active-bg;
    border-color: $dot-active-border-color;
}
