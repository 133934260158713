$lifestage-bullet-width:                            $base-spacing-1;
$lifestage-circle-border-width:                     $sublist-bullet-height * 2;
$lifestage-section-padding:                         $base-spacing-8;
$lifestage-background-color:                        $color-primary-1;
$lifestage-border:                                  1px solid rgba($color-black, 0.3);
$lifestage-circle-size:                             $base-spacing-3;

// BEZIER LOGIC
// Get the t value based on the x position of the left edge of the nth card based on size of outer padding of p and container width of c.
@function position-t ($p, $c, $n) {
    @return ($p + ($c - $p*2)/12 + ($c - $p*2)*5/24*$n) / $c; // Returns a scalar value (no units)
}

// Get the y position based on t.
@function position-y ($t, $h) {
    @return (3*$t*$t - 2*$t*$t*$t) * $h;
}

@mixin circle-container($x) {
    position: absolute;
    width: 100%;
    height: calc(#{$lifestage-section-padding + $lifestage-circle-size / 2} + #{position-y(position-t($container-padding-sm, $screen-lg, $x), 100vw*17/180)});
    bottom: 100%;
    border-left: $lifestage-border;

    // Curve stops scaling after 1440px width ($screen-lg)
    @media (min-width: $screen-lg) {
        height: calc(#{$lifestage-section-padding + $lifestage-circle-size / 2} + #{position-y(position-t($container-padding-xlg, $screen-lg, $x), $screen-lg*17/180)});
    }
}

section.s-lifestage-diagram {
    padding-top: $lifestage-section-padding;
    padding-bottom: 0;
    @include bg-theme-1;
    margin-bottom: 0;
    position: relative;
    border-bottom: 1px solid $color-primary-shade-6;

    .lifestage-curve {
        position: absolute;
        width: 100%;
        bottom: 100%;
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        -ms-grid-columns: 1fr auto 1fr;
        overflow: hidden;

        .curve-container {
            grid-column: 2/3;
            -ms-grid-column: 2;
            -ms-grid-column-span: 1;
            max-height: calc(136 / 1440 * 100vw);
        }

        svg {
            display: block;
            width: 100vw;
            height: 100%;
            max-width: 1440px;
            max-height: 136px;
            color: $color-primary-1;
        }

        .curve-right {
            grid-column: 3/4;
            -ms-grid-column: 3;
            -ms-grid-column-span: 1;
            height: 100%;
            background-color: $lifestage-background-color;
        }
    }

    .container {
        .lifestage-cards {
            .title {
                display: flex;

                // .circle {
                //     height: $lifestage-circle-size;
                //     width: $lifestage-circle-size;
                //     box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
                //     border: $lifestage-circle-border-width solid $color-white;
                //     border-radius: 50%;
                //     margin-left: -$lifestage-circle-size / 2;
                //     margin-right: 20px;
                //     transition: border-color 0.5s;
                // }

                h4 {
                    margin-bottom: $base-spacing-4;
                }
            }

            .column-content {
                margin-bottom: $base-spacing-6;

                ul {
                    margin-left: 0;

                    li {
                        margin-bottom: $base-spacing-2;
                        padding-left: $base-spacing-4;

                        a {
                            text-decoration: none;
                        }

                        a:hover,
                        a:focus {
                            text-decoration: underline;
                        }

                        &:last-of-type {
                            margin-bottom: 0;
                        }

                        &::before {
                            width: $lifestage-bullet-width;
                            height: $lifestage-bullet-width;
                            background-color: $color-primary-1;
                            border: 2px solid $color-white;
                            margin-left: -$lifestage-bullet-width/2;
                        }
                    }
                }
            }

            .card:first-of-type {
                .circle {
                    background-color: $color-lifestage-start;
                }

                ul li::before {
                    border-color: $color-lifestage-start;
                }
            }

            .card:nth-of-type(2) {
                .circle {
                    background-color: $color-lifestage-build;
                }

                ul li::before {
                    border-color: $color-lifestage-build;
                }
            }

            .card:nth-of-type(3) {
                .circle {
                    background-color: $color-lifestage-plan;
                }

                ul li::before {
                    border-color: $color-lifestage-plan;
                }
            }

            .card:last-of-type {
                .circle {
                    background-color: $color-lifestage-retire;
                }

                ul li::before {
                    border-color: $color-lifestage-retire;
                }
            }
        }
    }
}

@media (max-width: $screen-xs-max) {
    section.s-lifestage-diagram {
        .row {
            display: flex;
            justify-content: center;

            .lifestage-cards {
                max-width: 250px;
                border-left: $lifestage-border;
            }
        }
    }
}

@media (min-width: $screen-sm) {
    section.s-lifestage-diagram {
        .lifestage-cards {
            @include col(12);
            display: grid;
            grid-gap: $base-spacing-4;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            @include ie-grid(1fr 1fr 1fr 1fr);
            width: 100%;

            .card {
                border-left: $lifestage-border;

                .column-content {
                    margin-bottom: $base-section-desktop-padding;
                }
            }
        }
    }
}

@media (min-width: $screen-md) {
    section.s-lifestage-diagram {
        .lifestage-cards {
            .card {
                position: relative;

                .title h4 {
                    padding-left: $base-spacing-4;
                }
            }

            .card:first-of-type .circle-container {
                @include circle-container(0);
            }

            .card:nth-of-type(2) .circle-container {
                @include circle-container(1);
            }

            .card:nth-of-type(3) .circle-container {
                @include circle-container(2);
            }

            .card:last-of-type .circle-container {
                @include circle-container(3);
            }
        }
    }
}
