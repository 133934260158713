.form-review-item {
    @include base-mobile-form-layout;
    border: 1px solid $color-shade-2;
    border-radius: 4px;
    padding: $base-spacing-3;

    .review-content {
        @include vertical-rhythm;
        margin: $base-spacing-3 0;

        h5 {
            margin-bottom: 0;
        }
    }

    .edit {
        display: flex;
        align-items: center;
        text-decoration: none;

        svg {
            margin-right: $base-spacing-1;
            transition: 0.3s;
        }

        &:hover,
        &:focus {
            svg {
                fill: $color-primary-1;
            }
        }
    }
}


@media (min-width: $screen-sm) {
    .form-review-item {
        @include base-desktop-form-layout;
    }
}
