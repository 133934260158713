$social-link-width:                       $base-spacing-4;

.c-social-links {
    display: flex;
    justify-content: flex-start;

    .social-link {
        height: $social-link-width;
        width: $social-link-width;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-primary-2;
        margin-right: $base-spacing-2;
        border-radius: 50%;

        > .svg-icon {
            width: 100%;
            height: 100%;
            color: $color-primary-1;
        }

        > .svg-icon.icon-instagram {
            width: 60%;
            height: 60%;
        }

        &:hover,
        &:focus {
            background-color: $color-white;

            > .svg-icon {
                color: $color-primary-2;
            }
        }

        .share-email {
            width: $base-spacing-2;
        }
    }

    .social-content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}
