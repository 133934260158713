$calculator-box-shadow:     0 0 6px 0 rgba($color-primary-2, 0.3);

.s-calculator {
    background-color: $color-primary-shade-1;

    .calculator-heading {
        text-align: center;

        h2 {
            margin-bottom: $base-spacing-3;
        }
    }

    .calculator-container {
        margin-top: $base-spacing-7;
    }

    .calculator-row {
        background-color: $color-white;
        margin-bottom: $base-spacing-1;
        padding: $base-spacing-4 / 2;

        &:last-child {
            background-color: $color-primary-1;
            color: $color-white;
        }

        .calculator-contribution {
            span,
            .main {
                color: $color-white;
            }

            .input-value {
                @include display-heading-large;
                color: $color-primary-2;
            }
        }
    }

    .calculator-input {
        border-top: 1px solid $color-primary-shade-2;
        padding: $base-spacing-5 0;

        &:first-child {
            border-top: 0 none;
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }

    .input-title {
        margin-bottom: $base-spacing-1;
        @include body-light;

        span {
            display: block;
            @include body-small-heavy;
            color: $color-primary-shade-3;
        }

        &.main {
            color: $base-heading-colour-1;
            @include body-normal-medium;
        }
    }

    .input-value {
        color: $color-primary-2;
        padding-left: $base-spacing-2;
        position: relative;
        @include body-normal-medium;

        input[type="text"] {
            -moz-appearance: textfield;

            &:focus {
                border: 1px solid $color-primary-2;
                box-shadow: $calculator-box-shadow;
            }

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }

    .spouse,
    .personal,
    .income {
        padding-left: 0;

        input[type="text"] {
            color: $color-shade-3;
            padding-left: $base-spacing-3 + 2;
            @include body-normal-light;
        }

        &::before {
            content: '$';
            color: $color-shade-3;
            left: $base-spacing-2;
            position: absolute;
            top: 17px;
            @include body-normal-light;
        }
    }
}

@media (min-width: $screen-sm) {
    .s-calculator {
        .calculator-heading {
            margin: 0 auto;
        }

        .calculator-row {
            padding: $base-spacing-4;
        }

        .calculator-input,
        .calculator-contribution {
            display: flex;

            .input-title {
                align-self: center;
                margin-bottom: 0;
                margin-right: $base-spacing-4;
                width: 80%;
            }

            .input-value {
                align-self: center;
                width: 20%;
            }
        }
    }
}

@media (min-width: $screen-md) {
    .s-calculator {
        .calculator-heading {
            @include col(6);
            margin: 0 auto;
        }
    }
}

