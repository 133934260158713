$tag-filter-item-padding:                    $base-spacing-1;
$tag-filter-item-margin:                     $base-spacing-2;
$tag-filter-font-size:                       $base-font-size;
$tag-filter-border-sm:                       1px solid $color-shade-1;
$tag-filter-item-radius:                     4px;

$tag-filter-item-color:                      $color-black;
$tag-filter-item-color-active:               $color-white;
$tag-filter-item-bg-color:                   $color-shade-2;
$tag-filter-item-bg-color-active:            $color-primary-1;


.tag-filter-dt {
    display: none;
}

@media (min-width: $screen-sm) {
    .tag-filter-m {
        display: none;
    }

    .tag-filter-dt {
        display: block;

        ul {
            display: flex;
            flex-direction: row;
            @include list-reset;
        }

        .tag-filter-item {
            margin-right: $tag-filter-item-margin;
            padding: $tag-filter-item-padding;
            border-radius: $tag-filter-item-radius;
            color: $tag-filter-item-color;
            background-color: $tag-filter-item-bg-color;
            cursor: pointer;

            &::before {
                display: none;
            }

            &:hover {
                background-color: $tag-filter-item-bg-color;
                color: $tag-filter-item-color;
            }

            &.active {
                background-color: $tag-filter-item-bg-color-active;
                color: $tag-filter-item-color-active;
            }
        }
    }
}
