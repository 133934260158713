$placeholder-color:                 $color-shade-4;

@mixin placeholder($color) {
    &::-webkit-input-placeholder { /* Chrome, Safari, Opera */
        color: $color;
    }

    &:-moz-placeholder { /* Firefox 18- */
        color: $color;
    }

    &::-moz-placeholder { /* Firefox 19+ */
        color: $color;
    }

    &:-ms-input-placeholder { /* IE 10 - 11 */
        color: $color !important;
    }

    &::-ms-input-placeholder { /* EDGE */
        color: $color;
    }

    &::placeholder { /* CSS Working Draft */
        color: $color;
    }
}

@include placeholder($placeholder-color);

select:invalid {
    color: $placeholder-color;
}
