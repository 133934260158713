@media print {
    body {
        font: 12pt Georgia, "Times New Roman", Times, serif;
        line-height: 1.3;
    }

    h1 {
        font-size: 24pt;
    }

    h2 {
        font-size: 14pt;
        margin-top: 25px;
    }

    aside h2 {
        font-size: 18pt;
    }

    header,
    nav,
    footer {
        display: none;
    }

    blockquote,
    ul {
        margin: 0;
    }

    img {
        border: none;
    }

    header {
        margin-bottom: 40px;
    }
}
