@import "pickaday";

$pikaday-font-color:                $color-primary-1;
$pikaday-font-color-header:         $color-black;
$pikaday-font-color-hover:          $color-white;

$pikaday-background-color-active:   $color-primary-1;
$pikaday-background-color-selected: $color-primary-2;
$pikaday-background-color-hover:    lighten($color-primary-1, 5%);
$pikaday-background-color-iddle:    $color-primary-3;

$pikaday-font-color-other-month:    $color-primary-2;
$pikaday-background-other-month:    $color-primary-shade-1;

$pikaday-select-month-background:   $color-white;
$pikaday-select-month-border-color: $color-primary-1;

.datepicker-wrapper {
    .input-wrapper {
        position: relative;

        @include bg-icon('calendar', after, $select-icon-color) {
            position: absolute;
            top: calc(#{$input-height} / 4);
            right: $base-spacing-2;
            width: $select-icon-width;
            height: $select-icon-width;
            content: "";
        }
    }

    &.is-disabled .input-wrapper {
        @include bg-icon('calendar', after, $color-shade-2);
    }
}

.pika-single {
    .pika-lendar {
        width: 290px;
        padding: $base-spacing-1;
    }

    .pika-table abbr {
        @include font-size($normal-type);
        color: $pikaday-font-color-header;
        border-bottom: none;
        cursor: inherit;
        text-decoration: none;
    }

    .pika-table td {
        padding: 1px;
    }

    .pika-day {
        @include font-size($normal-type);
        color: $pikaday-font-color;
        background-color: $pikaday-background-color-iddle;
        display: flex;
        justify-content: center;
        border-radius: 0;

        &:hover {
            background-color: $pikaday-background-color-hover;
            color: $pikaday-font-color-hover;
        }
    }

    .is-disabled {
        .pika-day {
            opacity: 0.2;
        }
    }

    .is-outside-current-month.is-selection-disabled {
        background-color: $pikaday-background-other-month;

        button {
            color: $pikaday-font-color-other-month;
            background-color: transparent;
        }
    }

    .is-today .pika-day {
        background-color: $pikaday-background-color-active;
        color: $pikaday-font-color-hover;
    }

    .is-selected .pika-day {
        background-color: $pikaday-background-color-selected;
        color: $pikaday-font-color-hover;
    }

    .pika-title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: $base-spacing-1;
    }

    .pika-title .pika-prev,
    .pika-title .pika-next {
        width: $select-icon-width;
        height: $select-icon-width;
        text-indent: $select-icon-width;
        background: none;
    }

    .pika-title .pika-prev {
        @include bg-icon('chevron-left', after, $select-icon-color) {
            position: absolute;
            z-index: 100;
            top: 0;
            left: -6px;
            width: $select-icon-width;
            height: $select-icon-width;
            content: "";
            text-align: center;
        }
    }

    .pika-title .pika-next {
        order: 4;

        @include bg-icon('chevron-right', after, $select-icon-color) {
            position: absolute;
            z-index: 100;
            top: 0;
            right: -6px;
            width: $select-icon-width;
            height: $select-icon-width;
            content: "";
            text-align: center;
        }
    }

    .pika-label {
        line-height: 20px;
        order: 1;

        @include bg-icon($select-icon, after, $select-icon-color) {
            position: absolute;
            z-index: 100;
            top: $select-icon-width / 2;
            right: 12px;
            width: $select-icon-width;
            height: $select-icon-width;
            content: "";
            text-align: center;
        }
    }

    .pika-title select {
        opacity: 1;
        height: $base-spacing-3 * 2;
        position: relative;
        z-index: 99;
        padding: $base-spacing-3 / 2 $base-spacing-3 / 2 * 3 $base-spacing-3 / 2 $base-spacing-3 / 2;
        border: solid 1px $pikaday-select-month-border-color;
        border-radius: 0;
        -webkit-appearance: none;
        background: $pikaday-select-month-background;
        font-size: 16px;
        display: block;
        margin-top: -30px;
        width: 100%;
    }
}
