// Header
//----------------------------------------
$desktop-header-height:                     118px;
$desktop-header-bg-color:                   $color-white;
$desktop-header-border-color:               $color-shade-1;

$desktop-utility-bar-height:                $base-spacing-7;
$desktop-utility-bar-bg:                    $color-white;

$desktop-menu-bg:                           $color-white;

$desktop-search-icon-size:                  24px;
$desktop-search-icon-color:                 $color-primary-1;
$desktop-search-icon-focus:                 1px dotted $color-primary-1;

$mega-menu-box-bg-color:                    $color-white;
$mega-menu-box-shadow:                      3px 5px 8px -1px rgba($color-black, 0.09), -3px 5px 8px -1px rgba($color-black, 0.09);
$mega-menu-link-color:                      $color-shade-2;

@media screen and (max-width: $screen-sm-max) {
    .s-desktop-header {
        display: none;
    }
}

@media screen and (min-width: $screen-md) {
    .s-desktop-header {
        position: relative;
        background-color: $desktop-header-bg-color;
        border-bottom-left-radius: $base-spacing-4;
        border-bottom-right-radius: $base-spacing-4;

        .logo {
            height: 70px;
            margin: $base-spacing-3 0;

            img {
                display: block;
            }

            &:focus {
                outline: 1px dotted $color-shade-2;
            }
        }

        .desktop-utility-bar .desktop-utility-menu,
        .desktop-header-content {
            max-width: 100%;
        }

        .desktop-utility-bar {
            height: $desktop-utility-bar-height;
            background-color: $desktop-utility-bar-bg;
            display: flex;
            justify-content: space-between;
            max-width: 1440px;
            margin: 0 auto;
        }

        .member-links {
            height: 100%;

            ul {
                @include list-reset;
                display: flex;
                justify-content: flex-start;
                height: 100%;
            }

            li {
                position: relative;
                margin-bottom: 0;
                height: 100%;
            }

            svg {
                width: $base-spacing-2;
                height: $base-spacing-2;
            }
        }

        .utility-bar-child {
            position: absolute;
            display: none;
            flex-direction: column;
            top: $base-spacing-7;
            left: 0%;
            z-index: 1000;
            width: 264px;
            background: $color-shade-1;
            box-shadow: $mega-menu-box-shadow;
            border-bottom-left-radius: $base-spacing-2;
            border-bottom-right-radius: $base-spacing-2;
            padding: $base-spacing-2 $base-spacing-3;

            &.active {
                display: flex;
            }

            a {
                @include body-normal;
                font-family: $Avenir-Medium;
                font-weight: 500;
                padding: $base-spacing-1 0;
                color: $color-shade-3;
                text-decoration: none;

                &:hover,
                &:focus {
                    color: $color-primary-4;
                }
            }
        }

        .utility-item {
            @include body-small;
            display: flex;
            align-items: center;
            color: $color-shade-2;
            text-decoration: none;
            border-bottom: 4px solid $color-white;
            padding: 0 $base-spacing-2;
            height: 100%;

            &:focus,
            &:hover {
                color: $color-black;
                border-color: $color-primary-1;
            }
        }

        .utility-links {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-right: $base-spacing-3;
        }

        .desktop-header-content {
            position: relative;
            display: flex;
            align-items: center;
            border-top: $color-shade-1;
            z-index: 999;
            padding: 0;
            height: $desktop-header-height;
        }

        .desktop-menu {
            display: flex;
            background-color: $desktop-menu-bg;
            height: 100%;
            z-index: 102;// ensures over search on 404 page

            ul {
                @include list-reset;
                display: flex;
                z-index: 2;

                li {
                    margin-bottom: 0;
                    margin-left: $base-spacing-6;
                }
            }

            .c-desktop-menu-search {
                position: relative;
                width: $desktop-search-icon-size;
                height: 18px;

                .svg-icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                    max-height: $desktop-search-icon-size;
                    fill: $desktop-search-icon-color;

                    &:focus {
                        border: $desktop-search-icon-focus;
                    }
                }
            }
        }

        .menu-trigger {
            @include display-heading-small();
            position: relative;
            text-decoration: none;
            color: $color-black;
            height: 100%;
            padding: 0;
            display: flex;
            align-items: center;
            transition: color 0.15s ease-in-out;

            .svg-icon {
                position: absolute;
                top: 68px;
                display: none;
                width: 100%;
            }

            &.active,
            &:hover,
            &:focus {
                color: $color-primary-2;
                text-decoration: none;

                .svg-icon {
                    position: absolute;
                    display: block;
                    color: $color-accent-2;
                }
            }
        }

        .desktop-mega-menu-child {
            box-shadow: $mega-menu-box-shadow;
            position: absolute;
            top: $desktop-header-height;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 999;
            max-width: 1440px;
            background: $mega-menu-box-bg-color;
            display: none;
            grid-template-columns: 3fr 2fr;
            @include ie-grid(3fr 2fr);
            height: fit-content;
            border-radius: 0 0 $base-spacing-10 $base-spacing-10;
            margin: 0 $base-spacing-2;

            &.active {
                display: grid;
            }

            .content {
                display: grid;
                grid-gap: $base-spacing-5;
                grid-auto-flow: row;
                grid-template-columns: 1fr 1fr;
                @include ie-grid(1fr 1fr, $max-row: 2);
                background: $mega-menu-box-bg-color;
                border-bottom-left-radius: $base-spacing-4;
            }
        }

        .sub-nav {
            display: flex;
            flex-direction: column;
            position: relative;
            color: $mega-menu-link-color;
        }

        .sub-nav-item {
            @include body-light;
            color: $color-black;
            margin-bottom: $base-spacing-2;
            text-decoration: none;

            &:last-of-type {
                margin-bottom: 0;
            }

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

        .sub-nav-item-heading {
            @include display-heading-medium;
            margin-bottom: $base-spacing-2;
            color: $color-primary-1;

            a {
                text-decoration: none;

                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }

        .news-feed-item-heading {
            @include display-heading-xx-large;
            margin-bottom: $base-spacing-2;
            color: $color-black;
        }

        .news-browse {
            &:hover,
            &:focus {
                color: $color-primary-2;
            }
        }

        .news-feed {
            display: flex;
            flex-direction: column;
            border-bottom-right-radius: $base-spacing-4;
            background: $color-parchment-shade-1;
            border-top-left-radius: $base-spacing-4;
            border-bottom-left-radius: $base-spacing-4;
        }

        .news-text {
            @include body-normal-light;
            margin: $base-spacing-1 0 $base-spacing-4;
            color: $color-black;
        }

        .desktop-header-simple {
            .desktop-header-content {
                @include col(8);
                flex-direction: row;
                align-items: center;
                margin: 0 auto;
            }
        }

        .utility-btn {
            margin-left: $base-spacing-1;
            white-space: nowrap;
            min-height: $base-spacing-5;
            padding: 0 $base-spacing-3;
        }

        .utility-links .search-bar {
            margin-right: 0;
            margin-left: $base-spacing-1;
        }
    }
}

@media (min-width: $screen-md) {
    .s-desktop-header {
        margin: 0 $base-spacing-1;
        z-index: 200;

        > .container {
            padding: 0 $base-spacing-2;
        }

        .desktop-mega-menu-child {
            .content {
                padding: $base-spacing-10;
            }
        }

        .news-feed {
            padding: $base-spacing-6 $base-spacing-11;
        }
    }
}

@media (min-width: $screen-lg) {
    .s-desktop-header {
        .utility-trigger {
            position: relative;
            padding-left: $base-spacing-4;
            padding-right: $base-spacing-4;
        }

        .menu-trigger {
            .svg-icon {
                width: 50px;
            }
        }

        .desktop-menu {
            margin-left: $base-spacing-6;

            ul li a {
                padding: 0 $base-spacing-4;
            }
        }

        .desktop-mega-menu-child {
            margin: 0 auto;
        }
    }
}

@media (min-width: $screen-xlg) {
    .s-desktop-header {
        margin: 0 $base-spacing-6;

        > .container {
            padding: 0 $base-spacing-3;
        }
    }
}
