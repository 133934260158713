$engagement-video-mobile-height:        300px;
$engagement-video-desktop-height:       400px;
$engagement-video-bg:                   $color-black;
$engagement-video-play-icon-width:      $base-spacing-7;
$engagement-video-icon-color:           $color-white;
$engagement-video-btn-bg:               $color-black;
$engagement-video-btn-hover-bg:         $color-primary-1;
$engagement-video-content-padding:      $base-spacing-3;
$engagement-video-text-color:           $color-white;
$engagement-video-player-bg:            $color-primary-2;

.s-engagement-video {
    .engagement-video-wrapper {
        background-color: $engagement-video-bg;
        position: relative;
        min-height: $engagement-video-mobile-height;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        overflow: hidden;

        .video-content-background {
            background-position: 50% 50%;
            background-size: cover;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            opacity: $input-disabled-opacity;
        }

        .video-content {
            padding: $engagement-video-content-padding;
            transition: opacity $base-transition-medium $base-transition-curve;
            position: relative;
            z-index: 2;
            opacity: 1;
            max-width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            h3 {
                max-width: 80%;
                color: $engagement-video-text-color;
            }

            &.is-hidden {
                opacity: 0;
            }

            .btn-play {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: $base-spacing-3;
                width: $engagement-video-play-icon-width;
                height: $engagement-video-play-icon-width;
                border-radius: 50%;
                background-color: $engagement-video-btn-bg;
                color: $engagement-video-icon-color;
                transition: 0.2s all ease-in-out;

                &:hover,
                &:focus {
                    background-color: $engagement-video-btn-hover-bg;
                }
            }
        }

        .video-player {
            position: absolute;
            z-index: -1;
            width: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
            transition: all $base-transition-medium $base-transition-curve;
            display: flex;
            justify-content: center;
            align-items: center;
            @include row;
            background-color: $color-primary-2;

            &.is-visible {
                z-index: 2;
                opacity: 1;
            }

            .video-wrapper {
                width: 75%;
                position: relative;
                display: none;

                .btn-close {
                    position: absolute;
                    top: 0;
                    right: -$base-spacing-3 - $base-spacing-2;
                    color: $engagement-video-icon-color;
                    opacity: $input-disabled-opacity;
                }

                .btn-close:hover,
                .btn-close:focus {
                    opacity: 1;
                }
            }

            &.is-visible .video-wrapper {
                display: block;
            }
        }
    }

    .extra-flex-wrapper {
        display: flex; //IE has problems with flex items and min-height, this fixes it.
    }
}

@media (min-width: $screen-xs) {
    .s-engagement-video {
        .engagement-video-wrapper {
            .video-player {
                .video-wrapper {
                    width: 75%;
                }
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .s-engagement-video {
        .engagement-video-container {
            @include container;
        }

        .engagement-video-wrapper {
            min-height: $engagement-video-desktop-height;

            .video-player {
                .video-wrapper {
                    @include col(8);
                }
            }
        }
    }
}

@media (min-width: $screen-md) {
    .s-engagement-video {
        .engagement-video-wrapper .video-player {
            .video-wrapper {
                @include col(6);
            }
        }
    }
}
