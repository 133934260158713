$quick-link-icon-size: 128px;
$quick-link-size: 200px;

.c-quick-links {
    margin-top: $base-spacing-2;
    display: grid;
    gap: $base-spacing-3;
    grid-template-columns: repeat(4, 1fr);

    .quick-link-item {
        grid-column: span 4;
        background: $color-white;
        border-radius: $base-spacing-2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 20px;
        border: 1px $color-shade-2 solid;
        padding: $base-spacing-6;
        text-decoration: none;

        .icon-wrapper {
            margin: 0 auto;
            width: $quick-link-icon-size;
            height: auto;

            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }

        h5.quick-link-title {
            @include body-large;
            font-family: $Avenir-Black;
            display: block;
            width: 100%;
        }

        &:hover {
            background-color: $color-parchment-shade-1;
        }

        .cta-icon {
            color: $color-primary-2;

            .svg-icon {
                width: $base-spacing-6;
                height: $base-spacing-6;
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .c-quick-links {
        .quick-link-item {
            grid-column: span 2;
        }
    }
}

@media (min-width: $screen-md) {
    .c-quick-links {
        .quick-link-item {
            grid-column: span 1;
        }
    }
}
