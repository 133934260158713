.s-search-results-kentico {
    .search-wrapper {
        width: 100%;
    }

    .search-form {
        margin-bottom: $base-spacing-5;

        .form-horizontal {
            display: flex;
        }

        .form-group:first-of-type {
            flex-grow: 1;
        }

        .form-group-submit {
            position: relative;
            margin-left: $base-spacing-2;

            @include bg-icon('search', after, $color-primary-2) {
                position: absolute;
                left: $base-spacing-2;
                top: 12px;
                width: 24px;
                height: 24px;
                content: "";
            }

            input[type="submit"] {
                padding-left: $base-spacing-6;
            }
        }

        .editing-form-label-cell {
            display: none;
        }

        .editing-form-value-cell {
            input {
                @include base-input-styles;
                min-height: 50px;
                padding: 12px;
            }
        }
    }

    .search-result-item {
        display: inline-block;
        padding-bottom: $base-spacing-4;
        margin-bottom: $base-spacing-4;
        border-bottom: solid 1px $color-primary-3;
        width: 100%;

        .search-result-title {
            display: flex;
            flex-wrap: wrap;
            gap: $base-spacing-1;
            align-items: center;
            margin-bottom: $base-spacing-2;

            a {
                text-decoration: none;
            }

            > a {
                color: $color-shade-3;
                @include body-small;

                &:hover,
                &:focus {
                    color: $color-black;
                }
            }
        }

        p {
            margin-bottom: 0;
        }

        &.file {
            .search-result-download .btn-skin-4 {
                width: 100%;
                justify-content: center;
                margin-top: $base-spacing-3;
            }
        }
    }

    .c-pagination-simple {
        margin: $base-spacing-3 0 $base-spacing-5;
    }

    input[type="submit"] {
        @include btn-skin-1;
    }
}

@media (min-width: $screen-sm) {
    .s-search-results-kentico {
        .search-wrapper {
            @include col(10);
            @include col-offset(1);
        }

        .search-form {
            margin-bottom: $base-spacing-7;
        }

        .search-result-item {
            padding-bottom: $base-spacing-5;
            margin-bottom: $base-spacing-6;

            &.file {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: $base-spacing-4;

                .search-result-info {
                    display: flex;
                    flex-direction: column;
                }

                .search-result-download {
                    display: flex;
                    align-items: center;
                }
            }
        }

        .c-pagination-simple {
            margin: $base-spacing-4 0 $base-spacing-7;
        }
    }
}

@media (min-width: $screen-md) {
    .s-search-results-kentico {
        .search-wrapper {
            @include col(8);
            @include col-offset(2);
        }
    }
}
