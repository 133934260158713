section.s-left-header-text-box {
    padding: 0;

    &.no-top-padding {
        .container {
            padding-top: 0;
        }
    }

    &.no-bottom-padding {
        .container {
            padding-bottom: 0;
        }
    }

    .container {
        @include base-section-padding;

        .heading-wrapper {
            @include vertical-rhythm;

            .section-title {
                @include display-heading-xx-large;
                display: flex;
                align-items: flex-start;
            }

            .c-react-chart {
                width: 100%;
                margin-bottom: $base-spacing-3;
            }

            > h2 {
                display: flex;

                > .svg-icon {
                    margin-right: $base-spacing-2;
                }
            }
        }

        .content-wrapper {
            @include vertical-rhythm;

            *:last-child {
                margin-bottom: 0;
            }

            .content {
                @include vertical-rhythm;
            }
        }

        //! Temp zone, remove when Charts are live.
        .wizzy-wig-zone {
            > div {
                margin-top: $base-spacing-4;
            }

            .zone-one {
                h2:nth-of-type(2) {
                    margin-top: $base-spacing-3;
                }

                h5 {
                    margin-top: $base-spacing-2;
                    @include display-heading-small;
                }

                h1:last-of-type {
                    @include display-medium;
                    margin-bottom: $base-spacing-2;
                    text-transform: none;
                }

                table {
                    width: 100%;
                }

                a.cta-secondary {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: $base-spacing-2;

                    svg {
                        margin-left: $base-spacing-1;
                    }
                }
            }
        }
    }

    .c-accordion {
        border-bottom: 0 none;

        .accordion-item:first-child {
            .accordion-heading {
                border-top: 0 none;
            }
        }
    }

    .svg-icon {
        min-height: $base-spacing-4;
        min-width: $base-spacing-4;
    }

    .heading-wrapper {
        .svg-icon {
            margin-right: $base-spacing-1;
        }
    }
}

.s-left-header-text-box + .s-left-header-text-box,
.s-left-header-text-box + a + .s-left-header-text-box {
    .container {
        position: relative;

        &::before {
            content: '';
            height: 1px;
            background-color: $color-primary-shade-2;
            position: absolute;
            top: 0;
            left: $base-spacing-4;
            right: $base-spacing-4;
        }
    }
}

//Utility classes
.s-left-header-text-box {
    &.no-top-border {
        .container::before {
            display: none;
        }
    }
}

@media (max-width: $screen-sm-max) {
    section.s-left-header-text-box {
        .container {
            .heading-wrapper {
                margin-bottom: $base-spacing-5;
            }
        }
    }
}

@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    section.s-left-header-text-box {
        .container .chart-wrapper {
            display: grid;
            grid-template-columns: 50% 50%;
            grid-gap: $base-spacing-3;
            @include ie-grid(50% 50%);
        }
    }
}

@media (min-width: $screen-md) {
    section.s-left-header-text-box {
        .container {
            .desktop-row {
                @include row;
            }

            .heading-wrapper {
                @include col(4);

                .chart-wrapper {
                    width: 130%; //Kind of hacky fix to give the charts enough room to look nice.
                }
            }

            .content-wrapper {
                @include col(8);
            }
        }
    }
}

@media (min-width: $screen-lg) {
    section.s-left-header-text-box {
        .container .heading-wrapper .chart-wrapper {
            width: 120%; //Reset the charts now that they have tons of room.
        }
    }
}
