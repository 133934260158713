$tab-next-button: 100px;

.s-tabs {
    .tabs-heading {
        margin-bottom: $base-spacing-2;
        position: relative;
        border-bottom: 1px solid $color-shade-2;
        overflow: hidden;
    }

    .tab-wrapper {
        display: flex;
        justify-content: flex-start;
        overflow-y: auto;
        -ms-overflow-style: none;
        // scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &.colorised {
        .tabs-heading {
            border-bottom: unset;
            margin-bottom: 0;
        }

        .tab {
            width: 100%;
            min-width: 226px;
            padding: $base-spacing-3 $base-spacing-3 $base-spacing-1 $base-spacing-3;
            white-space: unset;
            height: auto;
            justify-content: flex-start;
            align-items: flex-end;

            &:last-child {
                margin-right: 0;
            }

            &.mint.active,
            &.mint:hover,
            &.mint:focus {
                background-color: $color-secondary-shade-1;
                color: $color-primary-1;
            }

            &.citrus.active,
            &.citrus:hover,
            &.citrus:focus {
                background-color: $color-accent-0;
                color: $color-primary-1;
            }

            &.blossom.active,
            &.blossom:hover,
            &.blossom:focus {
                background-color: $color-accent-4;
                color: $color-primary-1;
            }
        }

        .header-line {
            width: 100%;
        }

        .tab.mint .header-line {
            color: $color-primary-3;
        }

        .tab.citrus .header-line {
            color: $color-accent-1;
        }

        .tab.blossom .header-line {
            color: $color-accent-2;
        }

        .tabs-content {
            &.mint {
                background-color: $color-secondary-shade-1;
            }

            &.citrus {
                background-color: $color-accent-0;
            }

            &.blossom {
                background-color: $color-accent-4;
            }
        }
    }

    .next-button,
    .prev-button {
        background: linear-gradient(90deg, transparent 0%, rgba($color-white, 0.7) 30%, rgba($color-white, 1) 100%);
        display: none;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: $tab-next-button;

        &.hide {
            display: none;
        }

        &:hover {
            &::after {
                content: none;
            }
        }
    }

    .prev-button {
        background: linear-gradient(-90deg, transparent 0%, rgba($color-white, 0.7) 30%, rgba($color-white, 1) 100%);
        left: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &.bg-theme-1 {
        .next-button {
            background: linear-gradient(90deg, transparent 0%, rgba($color-primary-1, 0.7) 30%, rgba($color-primary-1, 1) 100%);
        }

        .prev-button {
            background: linear-gradient(-90deg, transparent 0%, rgba($color-primary-1, 0.7) 30%, rgba($color-primary-1, 1) 100%);
        }

        .tab {
            &.active,
            &.active:hover,
            &.active:focus {
                cursor: default;
                background-color: $color-primary-2;
                color: $color-white;
            }
        }
    }

    &.bg-theme-2 {
        .next-button {
            background: linear-gradient(90deg, transparent 0%, rgba($color-primary-shade-1, 0.7) 30%, rgba($color-primary-shade-1, 1) 100%);
        }

        .prev-button {
            background: linear-gradient(-90deg, transparent 0%, rgba($color-primary-shade-1, 0.7) 30%, rgba($color-primary-shade-1, 1) 100%);
        }
    }

    &.bg-theme-3 {
        .next-button {
            background: linear-gradient(90deg, transparent 0%, rgba($color-shade-1, 0.7) 30%, rgba($color-shade-1, 1) 100%);
        }

        .prev-button {
            background: linear-gradient(-90deg, transparent 0%, rgba($color-shade-1, 0.7) 30%, rgba($color-shade-1, 1) 100%);
        }
    }

    &.bg-theme-4 {
        .next-button {
            background: linear-gradient(90deg, transparent 0%, rgba($color-shade-2, 0.7) 30%, rgba($color-shade-2, 1) 100%);
        }

        .prev-button {
            background: linear-gradient(-90deg, transparent 0%, rgba($color-shade-2, 0.7) 30%, rgba($color-shade-2, 1) 100%);
        }
    }

    &.bg-theme-5 {
        .next-button {
            background: linear-gradient(90deg, transparent 0%, rgba($color-primary-2, 0.7) 30%, rgba($color-primary-2, 1) 100%);
        }

        .prev-button {
            background: linear-gradient(-90deg, transparent 0%, rgba($color-primary-2, 0.7) 30%, rgba($color-primary-2, 1) 100%);
        }
    }

    .next-icon,
    .prev-icon {
        background-color: $color-primary-4;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: $base-spacing-6;
        height: $base-spacing-6;

        svg {
            color: $color-white;
        }
    }

    .tab {
        @include display-heading-small;
        padding: 0 $base-spacing-3;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        border-top-right-radius: $base-spacing-2;
        border-top-left-radius: $base-spacing-2;
        background-color: $color-white;
        color: $color-primary-1;
        height: 64px;

        &.active,
        &.active:hover,
        &.active:focus {
            cursor: default;
            background-color: $color-primary-1;
            color: $color-white;
        }

        &:hover,
        &:focus {
            cursor: pointer;
            color: $color-primary-4;
        }

        &:last-child {
            margin-right: $tab-next-button;
        }
    }

    .tabs-content {
        > div {
            display: none;

            section[class*='s-'] {
                &:not(.s-three-col-content-blocks):last-of-type { //Fixes https://ngssuper.atlassian.net/browse/NB-416
                    padding-bottom: 0;
                }
            }
        }
    }

    .s-chart-block {
        .container {
            padding: 0;
        }
    }
}

@media (min-width: $screen-sm) {
    .s-tabs {
        .tabs-heading {
            justify-content: center;
        }
    }
}

@media (min-width: $screen-md) {
    .s-tabs {
        .next-button {
            display: flex;
        }
    }
}
