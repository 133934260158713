$single-checkbox-spacing:           $base-spacing-2;
$single-checkbox-height:            $base-spacing-6;
$single-checkbox-bg-color:          $color-white;

.single-checkbox-filter {
    position: relative;
    width: 100%;
    min-height: $single-checkbox-height;
    padding: (($single-checkbox-height - $checkbox-size) / 2);
    height: $single-checkbox-height;
    background-color: $single-checkbox-bg-color;
    z-index: 1;

    .single-checkbox-filter-panel {
        @include list-reset;
        padding-left: $single-checkbox-spacing;
        padding-right: $single-checkbox-spacing;
        height: 0;
        width: 100%;
        border: 1px solid $input-border-color;
        border-top: none;
        background-color: $input-bg;
        z-index: 10;
        overflow: hidden;
        overflow-y: auto;
        visibility: hidden;
        transition: all 200ms ease-in-out;
        position: static;
    }

    .single-checkbox-filter-item {
        display: flex;
        align-items: center;
        min-height: $checkbox-size;
        cursor: pointer;
        position: relative;

        span {
            position: relative;
            padding-left: $checkbox-size + $single-checkbox-spacing;
            min-height: $checkbox-size;
            line-height: $checkbox-size;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                height: $checkbox-size;
                width: $checkbox-size;
                border: 1px solid $input-border-color;
                border-radius: 2px;
            }
        }

        .svg-icon {
            position: absolute;
            top: 3px;
            color: $checkbox-icon-color;
            opacity: 0;
            z-index: 1;
            transition: opacity 0.15s ease-in-out;
            padding: 6px;
            width: $checkbox-size;
            height: $checkbox-size;
            pointer-events: none;
        }

        &.active {
            span::before {
                border-color: $input-border-color-focus;
                background-color: $checkbox-checked-bg;
            }

            .svg-icon {
                opacity: 1;
            }
        }
    }
}
