.form-member-join,
.form-dependant,
.form-custom {
    counter-reset: form-dependant-item-counter;
    @include base-mobile-form-layout;

    .dependant-item-add {
        margin: $base-spacing-6 0;
    }

    .dependant-item-close {
        align-items: center;
        display: flex;
        margin-left: auto;
    }

    .is-hidden {
        display: none;
    }
}

@media (min-width: $screen-sm) {
    .form-member-join,
    .form-dependant,
    .form-custom {
        @include base-desktop-form-layout;

        .dependant-item-add {
            margin-bottom: 0;

            &:focus {
                color: $btn-skin-4-color;
                background-color: $btn-skin-4-bg;
                border: 1px solid $btn-skin-4-border-color;
            }
        }
    }
}
