$form-layout-mobile-margin-bottom:      $base-spacing-3;
$form-layout-desktop-margin-bottom:     $base-spacing-4;

@mixin base-mobile-form-layout {
    @include stack;
    margin-bottom: $form-layout-mobile-margin-bottom;
}

form {
    .form-element,
    .form-field,
    .form-row {
        @include base-mobile-form-layout;

        &.form-element-error {
            position: relative;
        }

        input[type="submit"] {
            width: 100%;
        }

        &.is-hidden {
            display: none;
        }

        .input-wrapper {
            position: relative;
        }
    }

    // Kentico Smart form
    .editing-form-control-nested-control {
        position: relative;
    }
}

@mixin base-desktop-form-layout {
    @include col(12);
    margin-bottom: $form-layout-desktop-margin-bottom;
    display: flex;
    justify-content: flex-end;

    input[type="submit"] {
        width: auto;
        min-width: calc(100% / 3 - #{$desktop-gutter-width});
    }
}

@media (min-width: $screen-sm) {
    form,
    .EditingTableForm,
    .form-layout {
        .form-element {
            @include base-desktop-form-layout;
        }

        .form-layout-half {
            @include col(6);
        }

        .form-layout-half-cleared {
            @include col(12);
            padding-right: 50%;
        }

        .form-layout-third {
            @include col(4);
        }

        .form-layout-third-cleared {
            @include col(12);
            padding-right: 66.66%;
        }

        .form-layout-quarter {
            @include col(3);
        }

        .form-layout-quarter-cleared {
            @include col(12);
            padding-right: 75%;
        }
    }

    // Kentico Dancing Goat two columns layout
    .form-row,
    .form-wrapper {
        display: flex;

        > div {
            margin-left: $base-spacing-3;
            width: 100%;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .form-field {
        .input-wrapper {
            margin-left: $base-spacing-3;
            width: 100%;

            &:first-child {
                margin-left: 0;
            }
        }

        .editing-form-control-nested-control {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .form-member-join,
    .form-custom {
        .form-element,
        .form-field,
        .form-row {
            margin-bottom: 0;
        }
    }
}

@media (max-width: $screen-md) {
    .form-wrapper {
        display: block;
        width: 100%;

        > div {
            margin-left: 0;
        }

        .form-field {
            padding-bottom: 0;
        }
    }

    .form-member-join,
    .form-custom {
        .form-field .input-wrapper {
            margin-top: 0;
        }
    }
}
