section.s-combine-super {
    padding-top: $base-spacing-8;
    padding-bottom: $base-spacing-8;

    .features-heading {
        .title {
            margin-bottom: $base-spacing-3;

            h2 {
                @include display-medium;
                text-transform: none;

                &.accent {
                    color: $color-primary-2;
                }
            }
        }

        h3 {
            @include display-extra-small;
            color: $color-primary-1;
        }
    }

    .feature {
        border: 1px solid $color-primary-shade-1;
        padding: $base-spacing-8 $base-spacing-4;
        text-align: center;

        h3 {
            margin-bottom: $base-spacing-1;
        }

        p {
            margin-bottom: 0;
        }

        img {
            width: 160px;
            height: 160px;
            margin-bottom: $base-spacing-3;
        }

        a[class^="btn"] {
            margin-top: $base-spacing-2;
        }
    }
}

@media (max-width: $screen-xs-max) {
    section.s-combine-super {
        .links {
            display: flex;
            flex-direction: column;

            a {
                align-self: center;
            }

            a:first-of-type {
                margin-bottom: $base-spacing-3;
            }
        }
    }
}

@media (max-width: $screen-sm-max) {
    section.s-combine-super {
        .features-heading {
            margin-bottom: $base-spacing-6;
        }

        .feature {
            margin-bottom: $base-spacing-3;

            &:last-of-type {
                margin-bottom: $base-spacing-6;
            }
        }
    }
}

@media (min-width: $screen-sm) {
    section.s-combine-super {
        padding-top: $base-spacing-10;
        padding-bottom: $base-spacing-10;

        .features-heading {
            margin-bottom: $base-spacing-10;

            .title {
                margin-bottom: $base-spacing-4;
            }
        }

        .links {
            display: flex;
            justify-content: center;

            a:first-of-type {
                margin-right: $base-spacing-3;
            }
        }
    }
}

@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    section.s-combine-super {
        .tablet-row {
            @include row;

            .grid-wrapper {
                @include col(10);
                @include col-offset(1);
            }
        }
    }
}

@media (min-width: $screen-md) {
    section.s-combine-super {
        padding-top: $base-spacing-16;
        padding-bottom: $base-spacing-16;

        .features-heading {
            @include row();
            margin-bottom: $base-spacing-6;

            .title {
                @include col(5);
                @include col-offset(1);
            }

            h3 {
                @include col(4);
                @include col-offset(1);
                margin-bottom: 0;
            }
        }

        .features {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: $base-spacing-3;
            margin-bottom: $base-spacing-6;
            @include ie-grid(1fr 1fr 1fr);

            .feature {
                padding: $base-spacing-8 $base-spacing-6;
            }
        }
    }
}

@media (min-width: $screen-lg) {
    section.s-combine-super {
        .features-heading {
            .title {
                @include col(4);
            }

            h3 {
                @include col(5);
            }
        }
    }
}
