$newsletter-signup-bg: $color-black;

.s-newsletter-signup {
    color: $color-white;
    background-color: $newsletter-signup-bg;

    .container {
        padding-top: $base-spacing-3;
        padding-bottom: $base-spacing-3;
    }
}

@media (max-width: $screen-xs-max) {
    .s-newsletter-signup {
        p {
            margin-bottom: $base-spacing-3;
        }

        .btn {
            width: 100%;
            text-align: center;
        }
    }
}

@media (min-width: $screen-sm) {
    .s-newsletter-signup {
        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                margin-bottom: 0;
            }
        }
    }
}
