// Colors
// =======================================================================
/* stylelint-disable color-no-hex */
$color-primary-1:                          	#003C3C; // forest
$color-primary-2:                          	#39C06A; // apple
$color-primary-3:                          	#8CCAB1; // mint
$color-primary-4:                          	#32A95D; // apple dark

$color-secondary-7:                         #C3E8F1;

// forest variations
$color-primary-shade-1:                  	#E6ECEC; // 10%
$color-primary-shade-2:                  	#CCD8D8; // 20%
$color-primary-shade-3:                  	#B3C5C5; // 30%
$color-primary-shade-4:                  	#809D9D; // 50%
$color-primary-shade-5:                  	#4D7777; // 70%
$color-primary-shade-6:                  	#1A5050; // 90%
$color-primary-shade-7:                     #05463F;

// apple variations
$color-secondary-shade-1:                   #E6F3EB; // 10%
$color-secondary-shade-2:                   #D1EBDA; // 20%
$color-secondary-shade-3:                   #C4EDD3; // 30%
$color-secondary-shade-4:                   #98DFB4; // 50%
$color-secondary-shade-5:                   #75D397; // 70%
$color-secondary-shade-6:                   #4DC779; // 90%
$color-secondary-shade-7:                   #39C06A;

// mint variations
$color-tertiary-shade-1:                    #7CC1A6;
$color-tertiary-shade-2:                    #76BA9F;

$color-accent-0:                            #F9FBE6; // light citrus
$color-accent-1:                        	#BCD700; // citrus
$color-accent-2:                        	#FF96A3; // blossom
$color-accent-3:                        	#CA8184; // Blossom 2: Full Bloom.
$color-accent-4:                            #FFEFF1; // light blossom

$color-lifestage-start:                     $color-primary-2;
$color-lifestage-build:                     $color-accent-2;
$color-lifestage-plan:                      $color-accent-1;
$color-lifestage-retire:                    $color-primary-3;

// Neutral
$color-white:                              	#FFF;
$color-shade-1:                          	#F6EEE8; // parchment
$color-shade-2:                             #AEAFB5; // grey
$color-shade-3:                             #6D6E70; // lead
$color-shade-4:                             #D2D2D3; // for forms
$color-black:                              	#273236;
$color-light-grey:                          #F9F9F9;

$color-parchment-shade-1:                   #FAF6F3;

$color-error:                              	#DD3B52;
$color-error-1:                             #F8D8DC;
$color-warning:                            	#EA883E;
$color-warning-1:                           #FBE7D8;
$color-warning-2:                           #CF6D45;
$color-success:                            	$color-primary-2;
$color-disabled:                            #E0E0E6;

$color-facebook:                           	#3B5998;
$color-google:                             	#DD4B39;
$color-instagram:                          	#E1306C;
$color-linkedin:                           	#0077B5;
$color-pinterest:                          	#CB2027;
$color-twitter:                            	#55ACEE;
$color-youtube:                            	#CD201F;

// gradient

$gradient-primary-1: linear-gradient(to right, #003C3C 0%, #39C06A 100%);
$gradient-brand-banner: linear-gradient(157deg, #003C3C 27.18%, #28B46E 65.31%, #48BA82 64.65%, #2AB673 64.65%);
$gradient-tertiary-banner: linear-gradient(180deg, #003C3C 37.18%, #32A95D 130.36%);


/* stylelint-enable color-no-hex */

// Background Themes
@mixin bg-theme-1 {
    background-color: $color-primary-1;
    color: $color-white !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    blockquote,
    ul,
    ol,
    li,
    p {
        color: $color-white !important;
    }

    a {
        color: $color-white;

        &:hover,
        &:focus {
            color: $color-white;
            text-decoration: none;
        }

        &.btn-icon {
            svg {
                fill: $color-white;
            }
        }

        &.btn-skin-4 {
            color: $color-white;
            border-color: $color-white;

            &:hover {
                color: $color-primary-1;

                &::after {
                    background-color: $color-white;
                }
            }
        }

        &.btn-skin-ghost {
            &::after {
                background: $color-white;
            }
        }
    }
}

.bg-theme-1 {
    @include bg-theme-1;
}

@mixin bg-theme-2 {
    background-color: $color-primary-shade-1;
}

.bg-theme-2 {
    @include bg-theme-2;
    color: $color-black;

    .c-accordion {
        .accordion-item {
            .accordion-heading {
                .svg-icon {
                    background-color: $color-primary-shade-2;
                    color: $color-primary-1;
                }

                &:hover,
                &:focus {
                    .svg-icon {
                        background-color: $color-primary-1;
                        color: $color-primary-shade-2;
                    }
                }
            }
        }
    }
}


@mixin bg-theme-3 {
    background-color: $color-shade-1;
    color: $color-black;
}

.bg-theme-3 {
    @include bg-theme-3;
}

@mixin bg-theme-4 {
    background-color: $color-shade-2;
}

.bg-theme-4 {
    @include bg-theme-4;
}

@mixin bg-theme-5 {
    background-color: $color-primary-2;
}

.bg-theme-5 {
    @include bg-theme-5;
}

@mixin bg-light-grey {
    background: $color-light-grey;
}

.bg-light-grey {
    @include bg-light-grey;
}

// Lifestage Colours
@mixin lifestage-start {
    color: $color-lifestage-start;
}

.lifestage-start {
    @include lifestage-start;
}

@mixin lifestage-build {
    color: $color-lifestage-build;
}

.lifestage-build {
    @include lifestage-build;
}

@mixin lifestage-plan {
    color: $color-lifestage-plan;
}

.lifestage-plan {
    @include lifestage-plan;
}

@mixin lifestage-retire {
    color: $color-lifestage-retire;
}

.lifestage-retire {
    @include lifestage-retire;
}

@mixin bg-lifestage-start {
    background-color: $color-lifestage-start;
}

.bg-lifestage-start {
    @include bg-lifestage-start;
}

@mixin bg-lifestage-build {
    background-color: $color-lifestage-build;
}

.bg-lifestage-build {
    @include bg-lifestage-build;
}

@mixin bg-lifestage-plan {
    background-color: $color-lifestage-plan;
}

.bg-lifestage-plan {
    @include bg-lifestage-plan;
}

@mixin bg-lifestage-retire {
    background-color: $color-lifestage-retire;
}

.bg-lifestage-retire {
    @include bg-lifestage-retire;
}

@mixin bg-light-grey {
    background: $color-light-grey;
}

.bg-light-grey {
    @include bg-light-grey;
}

// Generic Colour Classes {
@mixin color-blossom {
    color: $color-accent-2;
}

.color-blossom {
    @include color-blossom;
}

@mixin color-white {
    color: $color-white;
}

.color-white {
    @include color-white;
}

@mixin color-white {
    color: $color-white;
}

@mixin forest {
    color: $color-primary-1;
}

.forest {
    @include forest;
}

@mixin parchment {
    color: $color-shade-1;
}

.parchment {
    @include parchment;
}

@mixin lead {
    color: $color-shade-3;
}

.lead {
    @include lead;
}
