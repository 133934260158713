.c-unit-price-table {
    text-align: center;

    table {
        th,
        td {
            text-align: left;
        }
    }

    .more {
        border: 1px solid $color-primary-2;
        color: $color-primary-1;
        margin-top: $base-spacing-7;
        @include body-normal-heavy;
        display: inline-block;
        padding: $base-spacing-1 $base-spacing-6;
        text-decoration: none;

        svg {
            color: $color-primary-2;
            margin-right: $base-spacing-1;
            vertical-align: text-top;
        }
    }
}
