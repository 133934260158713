$feature-panel-wrapper-width: 1662px;

$bg-skin-1: $color-shade-1;
$bg-skin-2: $color-secondary-shade-1;
$gradient-skin-1: linear-gradient(to bottom, $color-white 20%, $color-shade-1 100%);
$gradient-skin-2: linear-gradient(to bottom, $color-white 20%, $color-secondary-shade-1 100%);

section.s-feature-cta-panel {
    padding: 0;

    .feature-panel-container {
        position: relative;
        width: 100%;
        max-width: $feature-panel-wrapper-width;
        margin: 150px auto;
        border-radius: $base-spacing-3;
        background: linear-gradient(to bottom, $color-white 20%, $color-shade-1 100%);
        padding: $base-spacing-10 0 $base-spacing-16;
    }

    &.panel-skin-2 {
        .feature-panel-container {
            background: $gradient-skin-2;
        }
    }

    .feature-panel-image {
        position: absolute;

        img {
            width: 100%;
            height: auto;
        }
    }

    .feature-panel-space-filler {
        position: relative;

        &::before {
            content: "";
            display: block;
        }
    }

    .image-container {
        min-height: 360px;
        background-position: center center;
        background-size: cover;
    }

    .feature-panel-row {
        display: flex;
    }

    .feature-panel-col {
        width: 100%;
    }

    .panel-subtitle {
        @include display-heading-small;
        color: $color-primary-1;
        margin-bottom: $base-spacing-3;

        .svg-icon {
            display: block;
            width: 154px;
            color: $color-accent-2;

            &.style-1 {
                width: 80px;
            }

            &.style-2 {
                width: 95px;
            }

            &.style-3 {
                width: 110px;
            }

            &.style-4 {
                width: 125px;
            }

            &.style-5 {
                width: 140px;
            }

            &.style-6 {
                width: 155px;
            }

            &.style-7 {
                width: 170px;
            }

            &.style-8 {
                width: 185px;
            }

            &.style-9 {
                width: 200px;
            }

            &.style-10 {
                width: 215px;
            }

            &.style-11 {
                width: 230px;
            }

            &.style-12 {
                width: 245px;
            }

            &.style-13 {
                width: 260px;
            }

            &.style-14 {
                width: 275px;
            }
        }
    }

    .panel-title {
        @include display-medium;
        text-transform: none;
    }

    .panel-description {
        margin-top: $base-spacing-4;
        font-size: 18px;
        line-height: 1.5;
    }

    .panel-cta-box {
        margin-top: $base-spacing-4;
        display: flex;
        flex-wrap: wrap;
        gap: $base-spacing-3;
    }
}

@media (max-width: $screen-sm-max) {
    section.s-feature-cta-panel {
        .feature-panel-container {
            overflow: hidden;
        }
    }
}

//mobile only 
@media (max-width: $screen-xs-max) {
    section.s-feature-cta-panel {
        .feature-panel-image {
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 163%;
        }

        .feature-panel-space-filler {
            &::before {
                padding-top: 140%;
            }
        }
    }
}

//tablet only
@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    section.s-feature-cta-panel {
        .feature-panel-image {
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 137%;
        }

        .feature-panel-space-filler {
            &::before {
                padding-top: 120%;
            }
        }
    }
}

// desktop and larger
@media (min-width: $screen-md) {
    section.s-feature-cta-panel {
        .feature-panel-container {
            background: $bg-skin-1;
            padding: $base-spacing-10 0;
        }

        &.panel-skin-2 {
            .feature-panel-container {
                background: $bg-skin-2;
            }
        }

        .feature-panel-image {
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            max-width: 842px;
        }

        .feature-panel-col {
            width: 50%;
            margin-left: auto;
        }

        &.reverse {
            .feature-panel-image {
                right: 0;
                left: auto;
            }

            .feature-panel-col {
                margin-left: 0;
                margin-right: auto;
            }
        }
    }
}

@media (min-width: $screen-md) and (max-width: $screen-lg-max) {
    section.s-feature-cta-panel {
        .feature-panel-image {
            width: 50%;
        }
    }
}
