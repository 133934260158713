.s-text-media {
    .media-wrapper,
    .text-wrapper {
        width: 100%;
    }

    .media-wrapper {
        margin-bottom: $base-spacing-3;

        // Image mode
        .ratio-wrapper {
            @include placeholder-ratio-wrapper('3x2');

            img {
                @include placeholder-image-item;
            }
        }
    }

    .text-wrapper {
        @include vertical-rhythm;
    }
}

@media (min-width: $screen-sm) {
    .s-text-media {
        .media-wrapper {
            margin-bottom: 0;
            @include col(6);
        }

        .text-wrapper {
            @include col(6);
        }
    }
}

@media (min-width: $screen-md) {
    .s-text-media {
        .media-wrapper {
            @include col(6);
        }

        .text-wrapper {
            @include col(5);
        }
    }

    .s-text-media .row:not(.grid-reverse) .text-wrapper {
        @include col-offset(1);
    }

    .s-text-media .row.grid-reverse .text-wrapper {
        @include col-offset(1, true);
    }
}
