.c-pagination-complex {
    ul {
        flex-wrap: wrap;
    }

    .first,
    .previous,
    .next,
    .last {
        a,
        button {
            padding: 0 $base-spacing-2;
        }
    }
}
