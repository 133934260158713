.s-form {
    .container {
        .form-step-count,
        form {
            @include stack;
        }
    }
}

@media (min-width: $screen-sm) {
    .s-form {
        .container {
            .form-step-count,
            form {
                @include col(8);
            }
        }
    }
}
