// Unit Price Chart
//----------------------------------------
$unit-price-box-shadow:     0 0 6px 0 rgba(0, 0, 0, 0.1);
// $unit-price-latest-padding:                 $base-spacing-2 $base-spacing-2 $base-spacing-1 $base-spacing-2;

$unit-price-current-date-color:             $color-black;
$unit-price-current-value-color:            $color-primary-1;

$unit-price-more-padding:                   $base-spacing-1 0 $base-spacing-1 $base-spacing-2;

.c-unit-price-old {
    box-shadow: $unit-price-box-shadow;
    width: 100%;
    padding: $base-spacing-3 $base-spacing-2;

    .latest {
        .current-date {
            color: $unit-price-current-date-color;
            @include body-small;
            display: block;
            margin-bottom: $base-spacing-1;
        }

        .current-value {
            display: block;
            @include display-large;
            color: $unit-price-current-value-color;
            margin-bottom: $base-spacing-3;
        }
    }

    .table-wrapper {
        margin-bottom: 0;
    }

    .more {
        margin-top: $base-spacing-4;
        @include body-normal-heavy;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        svg {
            margin-left: $base-spacing-1;
            flex-shrink: 0;
        }
    }
}
