.c-article-header {
    padding-bottom: $base-spacing-8;

    span {
        @include tag;
        border: 1px solid $color-shade-3;
        border-radius: 20px;
        color: $color-black;
        display: inline-block;
        padding: 4px 20px;
    }

    h1 {
        margin-top: $base-spacing-3;
    }

    p,
    small {
        display: block;
        margin-top: $base-spacing-3;
        @include body-caption;
    }

    small {
        color: $color-black;

        span {
            border: 0 none;
            background: $color-primary-shade-2;
            display: inline-block;
            height: 20px;
            margin: 0 $base-spacing-1;
            padding: 0;
            vertical-align: middle;
            width: 1px;
        }
    }

    p {
        @include display-extra-small;
        color: $color-primary-1;
    }

    .article-header-wrapper p {
        margin-top: $base-spacing-3 !important;
    }
}

@media (max-width: $screen-sm) {
    .c-article-header {
        h1 {
            @include display-medium;
        }
    }
}

@media (min-width: $screen-sm) {
    .c-article-header {
        position: relative;

        h1 {
            @include display-medium;
            text-transform: none;

            .s-content-block .content & {
                margin-bottom: $base-spacing-2;
            }
        }
    }
}
