// Back to top anchor
//----------------------------------------
$back-to-top-width:                  64px;
$back-to-top-icon-color:             $color-white;
$back-to-top-bg:                     $color-primary-1;
$back-to-top-hover-bg:               $color-shade-3;

.c-back-to-top {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 20px;
    bottom: 96px;
    width: $back-to-top-width;
    height: $back-to-top-width;
    border-radius: 50%;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    background: $back-to-top-bg;
    transition: all 200ms ease-in;

    .svg-icon {
        color: $back-to-top-icon-color;
    }

    &.is-visible {
        opacity: 1;
        visibility: visible;
        z-index: 100;

        &:hover,
        &:focus {
            background-color: $back-to-top-hover-bg;
        }
    }
}

@media (min-width: $screen-md-max) {
    .c-back-to-top {
        right: 90px;
        bottom: 18px;
    }
}
