// GLOBAL SECTION STYLES
//----------------------------------------
$base-section-padding:              $base-spacing-4;
$base-section-desktop-padding:      $base-spacing-8;

@mixin base-section-padding {
    padding-top: $base-section-padding;
    padding-bottom: $base-section-padding;

    @media (min-width: $screen-sm) {
        padding-top: $base-section-desktop-padding;
        padding-bottom: $base-section-desktop-padding;
    }
    // @media (min-width: $screen-md) {
    //     padding-top: $base-spacing-12;
    //     padding-bottom: $base-spacing-12;
    // }
    // @media (min-width: $screen-lg) {
    //     padding-top: $base-spacing-16;
    //     padding-bottom: $base-spacing-16;
    // }
}

section[class*='s-'] {
    @include base-section-padding;

    &.no-top-padding {
        padding-top: 0;
    }

    &.no-bottom-padding {
        padding-bottom: 0;
    }

    &.margin-top {
        margin-top: $base-section-padding;
    }

    &.has-margins {
        margin-top: $base-section-padding;
        margin-bottom: $base-section-padding;
    }

    &.margin-bottom {
        margin-bottom: $base-section-padding;
    }
}

@media (min-width: $screen-sm) {
    section[class*='s-'] {
        &.margin-top {
            margin-top: $base-section-desktop-padding;
        }

        &.has-margins {
            margin-top: $base-section-desktop-padding;
            margin-bottom: $base-section-desktop-padding;
        }

        &.margin-bottom {
            margin-bottom: $base-section-desktop-padding;
        }
    }
}

@media (min-width: $screen-md) {
    section[class*='s-'] {
        &.margin-top {
            margin-top: $base-spacing-12;
        }

        &.has-margins {
            margin-top: $base-spacing-12;
            margin-bottom: $base-spacing-12;
        }

        &.margin-bottom {
            margin-bottom: $base-spacing-12;
        }
    }
}

@media (min-width: $screen-lg) {
    section[class*='s-'] {
        &.margin-top {
            margin-top: $base-spacing-16;
        }

        &.has-margins {
            margin-top: $base-spacing-16;
            margin-bottom: $base-spacing-16;
        }

        &.margin-bottom {
            margin-bottom: $base-spacing-16;
        }
    }
}
