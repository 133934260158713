$content-tile-paragraph-margin-top:     $base-spacing-2;
$content-tile-content-margin-top:       $base-spacing-2;

$content-tile-bg-min-height:            $base-spacing-7 * 2; //112px
$content-tile-bg-content-padding:       $base-spacing-2;
$content-tile-bg-1-bg-color:            $color-white;
$content-tile-text-color:               $color-black;

$content-tile-horizontal-image-width:   282px;

.c-base-tile {
    &:hover,
    &:focus {
        text-decoration: none;
    }

    .tile-image {
        @include placeholder-ratio-wrapper('3x2');

        img {
            @include placeholder-image-item;
        }
    }

    .tile-image-wrapper,
    .tile-content {
        background-color: $content-tile-bg-1-bg-color;
    }

    .tile-content {
        margin-top: 0;
        min-height: $content-tile-bg-min-height;
        padding: $content-tile-bg-content-padding;

        h4 {
            margin-bottom: 0;
        }

        p {
            color: $content-tile-text-color;
        }

        .subtext {
            @include body-small;
            margin-bottom: 0;
        }

        p:not(.subtext) {
            margin-top: $content-tile-paragraph-margin-top;
            margin-bottom: 0;
        }
    }
}
