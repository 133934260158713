.basic-panel__row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: $base-spacing-3;
}

@media (min-width: $screen-sm) {
    .basic-panel__row {
        grid-template-columns: repeat(2, 1fr);

        .basic-panel__col {
            height: 100%;

            > * {
                height: 100%;
            }
        }
    }
}
