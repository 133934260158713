.card-banner {
    position: relative;
    border-radius: $base-spacing-3;
    padding: $base-spacing-3;
    color: $color-white;
    background: $color-primary-1;
    display: block;
    text-decoration: none;

    &.clear-bg {
        border: 1px solid $color-white;
        background-color: transparent;

        &:hover .btn-skin-ghost,
        .btn-skin-ghost:not(:disabled):not(.btn-disabled):hover {
            color: $color-white;

            &::after {
                background: $color-white;
            }
        }
    }

    &:hover {
        color: $color-white;

        .btn-skin-ghost {
            color: $color-primary-2;
        }
    }

    .card-banner__inner {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: $base-spacing-2;
    }

    .card-banner__thumbnail {
        align-self: flex-start;
        max-width: $base-spacing-16;
        width: 100%;

        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }

    .card-banner__description {
        @include body-description;
        color: $color-white;
        width: 100%;
    }

    .card-banner__title {
        @include display-heading-x-large;
        font-weight: 900;
        color: $color-white;
    }

    .card-banner__cta-box {
        margin-top: $base-spacing-2;
    }

    .card-banner__cta {
        display: flex;
        align-items: center;
        gap: $base-spacing-1;

        .cta--icon {
            width: $base-spacing-3;
        }
    }
}

@media (min-width: $screen-md) {
    .card-banner {
        padding: $base-spacing-4 $base-spacing-6;

        .card-banner__description {
            width: auto;
            flex: 1;
        }

        .card-banner__inner {
            gap: $base-spacing-3;
        }
    }
}
