.c-social-shares {
    display: flex;
    justify-content: flex-start;
    background-color: $color-secondary-shade-1;
    padding: $base-spacing-3;
    width: 100%;

    p {
        @include body-small-heavy;
        color: $color-primary-1;
        margin: 0 $base-spacing-2 0 0;
    }

    .social-link,
    .share-link {
        height: $base-spacing-4;
        width: $base-spacing-4;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        margin-right: $base-spacing-2;
        border-radius: 50%;
        background-color: $color-primary-1;
        position: relative;

        > .svg-icon {
            width: 100%;
            height: 100%;
            fill: $color-primary-3;
            color: $color-primary-3;
        }

        &:hover,
        &:focus {
            background-color: $color-tertiary-shade-2;

            > .svg-icon {
                fill: $color-primary-3;
                color: $color-primary-3;
            }
        }

        .share-email {
            width: $base-spacing-2;
        }

        .tooltip-tag {
            color: $color-white;
            position: absolute;
            right: 48px;
            white-space: nowrap;
            padding: 8px;
            background-color: $color-primary-1;
            border-radius: 3px;
            margin-bottom: 0;

            &::before {
                left: 100%;
                top: 50%;
                border: solid transparent;
                content: "";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba($color-primary-1, 0);
                border-left-color: $color-primary-1;
                border-width: $base-spacing-1;
                margin-top: -$base-spacing-1;
            }
        }
    }

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        height: $base-spacing-4;
        width: $base-spacing-4;
        border: 0 none;
        padding: 0;
    }

    .social-content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: transparent;
    }

    &.sticky {
        position: fixed;
        bottom: 0;
    }
}

@media (max-width: $screen-md-max) {
    .c-social-shares {
        .social-content {
            margin: 0 auto;
            width: auto;
        }
    }
}

@media (min-width: $screen-md) {
    .c-social-shares {
        max-width: 88px;
        border-radius: 44px;
        margin: 0;

        .social-link,
        .share-link {
            margin: 12px auto;
        }

        .social-content {
            display: block;
            padding-left: 0;
            padding-right: 0;
        }

        p {
            margin: 0;
        }
    }
}
