$feature-image-width:   $base-spacing-12;

section.s-three-col-features {
    .container {
        .features-heading {
            margin-bottom: $base-spacing-6;
            text-align: center;

            h2 {
                @include display-heading-xx-large;
                margin-bottom: 0;
            }

            p {
                margin-top: $base-spacing-4;
                margin-bottom: 0;
            }
        }

        .content-wrapper {
            @include body-normal;
        }

        .feature {
            border: 1px solid $color-primary-shade-1;
            padding: $base-spacing-4;
            text-align: center;

            img,
            h3 {
                margin-bottom: $base-spacing-3;
            }

            p {
                // margin-bottom: $base-spacing-3;
            }

            .icon {
                width: $feature-image-width;
                height: $feature-image-width;
            }

            a {
                margin-top: $base-spacing-3;
            }

            h3 {
                color: $color-primary-1;
            }
        }
    }

    &[class*='bg-theme-'] {
        .container {
            .feature {
                border: none;
                padding-bottom: 0;
            }
        }
    }

    &[class*='bg-theme-1'] {
        .btn-icon svg {
            fill: $color-white;
        }
    }
}

.s-landing-intro + .s-three-col-features[class*='bg-theme-'] {
    border-top: 1px solid $color-white;
}

@media (max-width: $screen-sm-max) {
    section.s-three-col-features {
        .container .feature {
            margin-bottom: $base-spacing-3;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    section.s-three-col-features {
        .container {
            .features-heading,
            .features {
                @include col(10);
                @include col-offset(1);
            }

            .feature-content {
                display: grid;
                grid-template-columns: $feature-image-width 1fr;
                grid-gap: $base-spacing-6;
                text-align: left;
                @include ie-grid($feature-image-width 1fr, $max-row: 2);

                img {
                    grid-column: 1 / 2;
                    grid-row: 1 / -1;
                    margin-bottom: 0;
                }

                h3,
                p {
                    grid-column: 2 / -1;
                }
            }
        }
    }
}

@media (min-width: $screen-sm) {
    section.s-three-col-features {
        .container {
            .tablet-row {
                @include row;
            }

            .feature {
                text-align: left;
                padding: $base-spacing-6;
            }
        }

        &.layout-two-col {
            .feature {
                .feature-content {
                    display: grid;
                    grid-template-columns: $feature-image-width 1fr;
                    grid-gap: $base-spacing-6;
                    text-align: left;
                    @include ie-grid($feature-image-width 1fr, $max-row: 2);

                    img {
                        grid-column: 1 / 2;
                        grid-row: 1 / -1;
                        margin-bottom: 0;
                    }

                    h3,
                    p {
                        grid-column: 2 / -1;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-md) {
    section.s-three-col-features {
        .container {
            .tablet-row {
                justify-content: center;
            }

            .features-heading {
                @include col(6);
            }

            .features {
                @include col(12);
            }

            .feature-heading {
                margin-bottom: $base-spacing-9;
            }

            .features {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                @include ie-grid(1fr 1fr 1fr, $max-row: 3);
                grid-gap: $base-spacing-3;
            }
        }


        &[class*='bg-theme-'] {
            .container {
                .feature {
                    padding-bottom: 0;
                }
            }
        }

        &.layout-two-col {
            .features {
                grid-template-columns: 1fr 1fr;
                @include ie-grid(1fr 1fr, $max-row: 2);
            }
        }
    }
}
