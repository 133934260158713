.c-market-commentary {
    // Tabs
    .market-tabs {
        margin: 0 auto $base-spacing-4;

        button {
            margin: 0 auto $base-spacing-2;
        }
    }

    table td {
        cursor: pointer;
    }

    .back-button {
        align-self: flex-start;
        margin-bottom: $base-spacing-2;
        display: flex;
        align-items: flex-start;

        .back-text,
        .back-icon svg {
            color: $color-primary-2;
        }

        .back-icon {
            display: inline-block;
            height: $back-icon-size;
            margin-right: $base-spacing-1;

            svg {
                height: $back-icon-size;
                width: $back-icon-size;
            }
        }

        &:hover,
        &:focus {
            .back-text,
            .back-icon svg {
                color: $color-black;
            }
        }
    }

    .actionButtons {
        display: flex;
        width: 100%;
    }

    .svg-icon {
        display: inline-block;
        vertical-align: middle;
    }

    h2 {
        margin-left: 120px;
    }
}

@media (min-width: $screen-xs) {
    .c-market-commentary {
        .market-tabs {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            button {
                margin-left: 0;
                margin-right: $base-spacing-2;
            }
        }
    }
}
