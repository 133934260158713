$brand-cta-container-width: 1662px;

.s-brand-cta-panel {
    position: relative;

    .brand-panel-container {
        position: relative;
        margin: 0 auto;
        width: 100%;
        max-width: $brand-cta-container-width;
        background: $color-primary-4;
        border-radius: $base-spacing-3;
        padding: $base-spacing-10 0 $base-spacing-16;
    }

    .brand-image {
        position: absolute;

        img {
            width: 100%;
            height: auto;
        }
    }

    .brand-panel-image-spacer {
        position: relative;

        &::before {
            content: "";
            display: block;
        }
    }

    .brand-panel-row {
        display: flex;
    }

    .brand-panel-col {
        width: 100%;
    }

    .brand-panel-title {
        @include display-large;
        text-transform: none;
        color: $color-white;
    }

    .brand-panel-cta {
        margin-top: $base-spacing-6;
        display: inline-block;
        position: relative;
        padding-right: $base-spacing-7;

        .icon-spark {
            position: absolute;
            right: 0;
            bottom: $base-spacing-1;
            width: $base-spacing-8;
            height: $base-spacing-8;
            color: $color-white;
            transform: rotate(61.84deg);
        }
    }
}

// tablet and smaller
@media (max-width: $screen-sm-max) {
    .s-brand-cta-panel {
        .brand-panel-container {
            overflow: hidden;
        }
    }
}

// mobile only 
@media (max-width: $screen-xs-max) {
    .s-brand-cta-panel {
        .brand-panel-image-spacer {
            &::before {
                padding-top: 128%;
            }
        }

        .brand-image {
            width: 164%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

// tablet only
@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    .s-brand-cta-panel {
        .brand-panel-image-spacer {
            &::before {
                padding-top: 115%;
            }
        }

        .brand-image {
            width: 137%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

// desktop and larger
@media (min-width: $screen-md) {
    .s-brand-cta-panel {
        margin: $base-spacing-14 0;

        .brand-panel-container {
            padding: $base-spacing-10 0;
        }

        .brand-panel-col {
            width: 57%;
            margin-left: auto;
        }

        .brand-image {
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            max-width: 695px;
        }
    }
}

// desktop small only
@media (min-width: $screen-md) and (max-width: $screen-md-max) {
    .s-brand-cta-panel {
        .brand-image {
            width: 41%;
        }
    }
}

