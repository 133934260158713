// Layout specific overrides - filters top (default style)
.layout-filters-top {
    .active-filters-container,
    .filters-container,
    .listing-toolbar .toolbar-container,
    .listing-items .listing-items-container {
        @include container;
    }
}

@media (min-width: $screen-sm) {
    .layout-filters-top {
        .filters {
            @include row;
            flex-wrap: nowrap;

            .filter-item {
                @include col(3);
            }

            .active-filters {
                justify-content: center;
            }
        }
    }
}
