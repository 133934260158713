.c-content-area {
    background-color: $color-white;
    height: 100%;
    padding: $base-spacing-5;

    .content-text {
        margin-top: $base-spacing-3;
    }

    p {
        margin: 0;
    }

    .btn-text {
        color: $color-primary-1;
        font-weight: bold;
        margin-top: $base-spacing-2;
        text-decoration: none;
    }
}

@media (min-width: $screen-md) {
    .c-content-area {
        padding: $base-spacing-8;
    }
}
