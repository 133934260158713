.form-nav .row {
    a,
    span {
        text-align: center;
        margin-right: $base-spacing-3;
        @include btn-small;
        min-width: $base-spacing-10 * 2;
    }

    .btn-skin-1 {
        cursor: default;

        &:hover,
        &:focus {
            background: $color-primary-1;
        }
    }

    > * :last-of-type {
        margin-right: 0;
    }
}

.form-nav.example-nav {
    .row {
        margin-bottom: $base-spacing-2;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

// Dancing Goat Form
.ktc-default-section,
.form-row {
    + input[type="submit"] {
        @include btn-skin-1;
        display: block;
        margin-top: $base-spacing-4;
        width: 100%;
    }
}

.form-field {
    position: relative;

    .ktc-form-section-title {
        @include display-heading-medium;
        margin-top: 6px;

        &.styled {
            border-top: 1px solid $color-primary-shade-2;
            display: block;
            margin-top: $base-spacing-5;
            padding-top: $base-spacing-8;
            width: 100%;
        }
    }
}
