$bg-mint-light: rgba(140, 202, 177, 0.15);
$bg-citrus-light: rgba(188, 215, 0, 0.15);
$bg-blossom-light: $color-accent-4;

.c-animated-tile {
    text-align: center;
    padding: $base-spacing-4;
    border: 1px solid $color-white;
    border-radius: $base-spacing-3;
    background-color: $color-white;
    min-height: 340px;

    .colorised .mint & {
        border-color: $color-primary-3;
    }

    .colorised .citrus & {
        border-color: $color-accent-1;
    }

    .colorised .blossom & {
        border-color: $color-accent-2;
    }

    &.indicator .feature-content {
        margin-top: $base-spacing-3;
    }

    .feature-content-wrapper {
        position: relative;
        z-index: 5;
    }

    .feature-heading {
        margin-top: $base-spacing-3;
        width: 100%;
        text-align: center;
        font-size: 41px;
        font-family: $base-heading-font;
        font-weight: 900;
        line-height: 48px;
    }

    &.indicator .feature-heading {
        margin-top: 0;
    }

    .feature-subtitle {
        margin-top: $base-spacing-1;
        width: 100%;
        text-align: center;
        font-size: 23px;
        font-family: $base-heading-font;
        font-weight: 900;
        line-height: 32px;
    }

    .feature-info {
        margin-top: $base-spacing-1;
        width: 100%;
        text-align: center;
        font-size: 18px;
        font-family: $Avenir-Light;
        font-weight: 350;
        line-height: 28px;
    }

    .feature-graphic {
        position: relative;
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 124px;
    }

    .feature-icon {
        height: 110px;
        width: 110px;
    }

    .feature-circle {
        height: 124px;
        width: 124px;
        border-radius: 50%;
        position: absolute;
        clip-path: polygon(50% 50%, 100% 50%, 50% 0%); /* Start with 0 degrees */
        transform-origin: 50% 50%; /* Center for rotation */
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-90deg);
        opacity: 0.5;


        .colorised .mint & {
            background-color: $color-primary-3;
        }

        .colorised .citrus & {
            background-color: $color-accent-1;
        }

        .colorised .blossom & {
            background-color: $color-accent-2;
        }
    }

    .feature-bar-grid {
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        gap: 4px;
        max-width: 300px;

        .colorised .mint & {
            background-color: $bg-mint-light;
        }

        .colorised .citrus & {
            background-color: $bg-citrus-light;
        }

        .colorised .blossom & {
            background-color: $bg-blossom-light;
        }
    }

    .feature-bar-cell {
        position: relative;
        height: auto;
        width: 100%;
        z-index: 4;
    }

    .feature-bar {
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        height: 100%;
        width: 0%;

        .colorised .mint & {
            background-color: $color-primary-3;
        }

        .colorised .citrus & {
            background-color: $color-accent-1;
        }

        .colorised .blossom & {
            background-color: $color-accent-2;
        }
    }

    .feature-bar-tick {
        position: absolute;
        z-index: 2;
        top: -46px;
        right: -19px;
        height: 40px;
        width: 38px;
        opacity: 0;
        transition: 0.6s;
        transform-style: preserve-3d;
    }

    .feature-bar.done .feature-bar-tick {
        opacity: 1;
        transform: rotateY(360deg);
    }

    .feature-indicator-wrapper {
        position: relative;

        .colorised .mint & {
            background-color: $bg-mint-light;
        }

        .colorised .citrus & {
            background-color: $bg-citrus-light;
        }

        .colorised .blossom & {
            background-color: $bg-blossom-light;
        }
    }

    .feature-indicator {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .feature-indicator-grid {
        position: relative;
        z-index: 3;
        width: 100%;
        max-width: 267px;
        height: auto;
        display: block;
    }

    .feature-indicator-icon {
        position: absolute;
        z-index: 4;
        width: 56px;
        height: 56px;
        left: 50%;
        bottom: 8px;
        transform: translateX(-50%);
    }

    .feature-indicator {
        height: 267px;
        width: 267px;
        border-radius: 50%;
        position: absolute;
        clip-path: polygon(50% 50%, 100% 50%, 50% 0%); /* Start with 0 degrees */
        transform-origin: 50% 50%; /* Center for rotation */
        left: 50%;
        transform: translateX(-50%) rotate(-182deg);

        .colorised .mint & {
            background-color: $color-primary-3;
        }

        .colorised .citrus & {
            background-color: $color-accent-1;
        }

        .colorised .blossom & {
            background-color: $color-accent-2;
        }
    }

    .feature-image-wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.donut {
        display: flex;
        gap: $base-spacing-4;
        flex-direction: column;

        .feature-graphic {
            height: 300px;

            .feature-circle {
                height: 270px;
                width: 270px;
                opacity: 1;
            }

            .feature-image {
                position: absolute;
                z-index: -1;
            }

            .feature-inner-content {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 225px;
                height: 225px;
                border-radius: 50%;
                background-color: $color-white;
                z-index: 1;

                .feature-heading {
                    margin-top: 0;
                }
            }
        }
    }
}

@media (min-width: $screen-md) {
    .c-animated-tile {
        padding: $base-spacing-8;
        min-height: 484px;

        &.indicator .feature-content {
            margin-top: $base-spacing-6;
        }

        .feature-heading {
            margin-top: $base-spacing-6;
            font-size: 64px;
            line-height: 68px;
        }

        .feature-subtitle {
            margin-top: $base-spacing-2;
            font-size: 26px;
            line-height: 32px;
        }

        .feature-info {
            margin-top: $base-spacing-2;
        }

        .feature-graphic {
            height: 172px;
        }

        .feature-icon {
            height: 124px;
            width: 124px;
        }

        .feature-circle {
            height: 172px;
            width: 172px;
        }

        .feature-bar-grid {
            max-width: 515px;
        }

        .feature-bar-tick {
            top: -64px;
            right: -28px;
            height: 58px;
            width: 56px;
        }

        .feature-indicator-grid {
            max-width: 334px;
        }

        .feature-indicator {
            width: 334px;
            height: 334px;
        }

        .feature-indicator-icon {
            width: 78px;
            height: 78px;
            bottom: 8%;
            transform: translateX(-50%);
        }

        &.donut {
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

@media (min-width: $screen-lg) {
    .c-animated-tile {
        .feature-indicator-grid {
            max-width: 465px;
        }

        .feature-indicator {
            width: 465px;
            height: 465px;
        }

        .feature-indicator-icon {
            bottom: 20%;
        }
    }
}
