$icon-size: $base-spacing-10 + $base-spacing-2;

.c-feature-tile {
    text-align: left;
    margin: 0 auto $base-spacing-6;

    &.with-border {
        padding: $base-spacing-5;
        border: 1px solid $color-shade-3;
        border-radius: $base-spacing-2;
    }

    &.has-button {
        position: relative;
    }

    section[class*="bg-theme"] & {
        border: none;
    }

    .feature-heading {
        @include display-heading-x-large;
        margin-bottom: $base-spacing-2;

        > a {
            text-decoration: none;
        }
    }

    .feature-image {
        margin-bottom: $base-spacing-5;
        border-radius: $base-spacing-2;
        display: block;
        width: 100%;
    }

    .feature-icon {
        height: $icon-size;
        margin-bottom: $base-spacing-3;
        width: $icon-size;
    }

    .feature-category-holder {
        width: 100%;
        margin-bottom: $base-spacing-5;
    }

    .feature-category {
        @include body-small;
        border: 1px solid $color-primary-1;
        border-radius: 18px;
        color: $color-primary-1;
        height: 36px;
        display: inline-block;
        padding: $base-spacing-1 $base-spacing-2;
    }

    .feature-content {
        @include body-normal;
        color: $color-black;
    }

    .bg-theme-1 & .feature-content {
        color: $color-white;
    }

    .feature-date {
        @include body-small;
        color: $color-shade-3;
        margin-top: $base-spacing-2;
    }

    a[class^="btn"] {
        margin-top: $base-spacing-4;
        @include body-normal;
    }
}

@media (min-width: $screen-sm) {
    .c-feature-tile {
        margin-bottom: $base-spacing-3;
        margin-left: unset;
        text-align: left;

        &.with-border {
            padding: $base-spacing-6;
        }

        &.has-button {
            padding-bottom: 88px;
            position: relative;

            a[class^="btn"] {
                margin-top: 0;
                position: absolute;
                bottom: 0;
            }
        }

        &.with-border.has-button {
            padding-bottom: 136px;

            a[class^="btn"] {
                bottom: $base-spacing-6;
            }
        }

        &.has-button .feature-content .content-wrapper .feature-content {
            display: block;/* or inline-block */
            text-overflow: ellipsis;
            word-wrap: break-word;
            overflow: hidden;
            max-height: 7rem;
        }

        .feature-image {
            margin-bottom: $base-spacing-4;
        }

        .feature-category-holder {
            margin-bottom: $base-spacing-4;
        }
    }
}
