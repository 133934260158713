$resources-padding-m: $base-spacing-2;
$resources-padding-dt: $base-spacing-3;
$resources-bg: $color-shade-2;

$resource-group-margin-m: $base-spacing-3;
$resource-group-margin-dt: $base-spacing-7;
$resource-group-title-margin: $base-spacing-2;

$resource-item-margin: $base-spacing-1;
$resource-item-icon-width: $base-spacing-3;
$resource-item-filesize-margin: 0 $base-spacing-1;

.listing-resources {
    padding: $resources-padding-m;
    background-color: $resources-bg;

    .resource-group {
        margin-bottom: $resource-group-margin-m;

        &:last-of-type {
            margin-bottom: 0;
        }

        h4 {
            margin-bottom: $resource-group-title-margin;
        }

        ul {
            @include list-reset;

            li {
                margin-bottom: $resource-item-margin;
            }
        }

        .resource-item {
            a {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                // text
                span {
                    order: 1;
                    flex: 0 0 85%;
                    max-width: 85%;
                }

                // text with filesize sibling
                small + span {
                    flex-basis: 55%;
                    max-width: 55%;
                }

                // filesize
                small {
                    order: 2;
                    margin: $resource-item-filesize-margin;
                    flex: 0 0 auto;
                }

                .svg-icon {
                    order: 3;
                    flex: 0 0 $resource-item-icon-width;
                }
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .listing-resources {
        padding: $resources-padding-dt;

        .resource-group {
            margin-bottom: $resource-group-margin-dt;
        }
    }
}
