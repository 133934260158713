$mobile-menu-trigger-width:                                $base-spacing-4;
$mobile-menu-trigger-hover-bg:                             $color-shade-1;

$hamburger-layer-width:                                    32px;
$hamburger-layer-height:                                   4px;
$hamburger-layer-spacing:                                  6px;
$hamburger-layer-color:                                    $color-primary-2;
$hamburger-layer-background:                               $color-white;
$hamburger-layer-border-radius:                            0;
$hamburger-layer-timing-bezier-1:                          cubic-bezier(0.55, 0.055, 0.675, 0.19);
$hamburger-layer-timing-bezier-2:                          cubic-bezier(0.215, 0.61, 0.355, 1);

$mobile-menu-trigger-hover-transition-duration:            0.15s;
$mobile-menu-trigger-hover-transition-timing-function:     linear;


.c-mobile-nav-trigger {
    position: relative;
    width: $mobile-menu-trigger-width;
    height: $mobile-menu-trigger-width;
    background-color: $hamburger-layer-background;
    cursor: pointer;
    transition-property: background;
    transition-duration: $mobile-menu-trigger-hover-transition-duration;
    transition-timing-function: $mobile-menu-trigger-hover-transition-timing-function;
    // Normalize (<button>)
    padding: 0;
    font: inherit;
    color: inherit;
    text-transform: none;
    border: none;
    margin: 0;
    overflow: visible;

    .flex-wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .hamburger-box {
        width: $hamburger-layer-width;
        height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
        display: inline-block;
        position: relative;
    }

    .hamburger-inner {
        display: block;
        top: 50%;
        margin-top: $hamburger-layer-height / -2;
        transition-duration: 0.075s;
        transition-timing-function: $hamburger-layer-timing-bezier-1;

        &,
        &::before,
        &::after {
            width: $hamburger-layer-width;
            height: $hamburger-layer-height;
            background-color: $hamburger-layer-color;
            border-radius: $hamburger-layer-border-radius;
            position: absolute;
        }

        &::before,
        &::after {
            content: "";
            display: block;
        }

        &::before {
            top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
            transition: top 0.075s 0.12s ease, opacity 0.075s ease;
        }

        &::after {
            bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
            transition: bottom 0.075s 0.12s ease, transform 0.075s $hamburger-layer-timing-bezier-1;
        }
    }

    &.is-active {
        .hamburger-inner {
            transform: rotate(45deg);
            transition-delay: 0.12s;
            transition-timing-function: $hamburger-layer-timing-bezier-2;

            &::before {
                top: 0;
                opacity: 0;
                transition: top 0.075s ease, opacity 0.075s 0.12s ease;
            }

            &::after {
                bottom: 0;
                transform: rotate(-90deg);
                transition: bottom 0.075s ease, transform 0.075s 0.12s $hamburger-layer-timing-bezier-2;
            }
        }
    }
}
