$image-mobile-height:       464px;
$image-desktop-height:      920px;

.s-two-col-half-image {
    .content-container {
        @include body-normal;
        @include vertical-rhythm;

        h2 {
            @include display-heading-xx-large;
        }

        h3 {
            color: $color-primary-1;
        }

        *:last-child {
            margin-bottom: 0;
        }

        strong,
        em,
        u,
        span {
            display: contents;
        }

        a[class^="cta-"] {
            display: block;
        }
    }

    .image-container {
        display: block;
        min-height: $image-mobile-height;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

@media (max-width: $screen-sm-max) {
    .s-two-col-half-image {
        .desktop-row {
            display: flex;
            flex-direction: column-reverse;

            .image-container {
                margin-bottom: $base-spacing-6;
            }
        }
    }
}

@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    .s-two-col-half-image {
        .desktop-row {
            .image-container {
                margin-bottom: $base-spacing-8;
            }

            .content-container {
                padding: 0 $base-spacing-7;
            }
        }
    }
}

@media (min-width: $screen-md) {
    .s-two-col-half-image {
        .desktop-row {
            @include row;

            .content-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                @include col(4);
            }

            .image-container {
                @include col(6);
                min-height: $image-desktop-height;
            }

            &:not(.reverse) {
                .content-container,
                .image-container {
                    @include col-offset(1);
                }
            }

            &.reverse {
                flex-direction: row-reverse;

                .content-container,
                .image-container {
                    @include col-offset(1, true);
                }
            }
        }
    }
}
