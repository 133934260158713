$address-padding: $base-spacing-1 $base-spacing-2;

@import "../../node_modules/spinthatshit/src/loaders";

.validation-experian-qas {
    position: relative;

    .address-picklist-container {
        border: 1px solid $color-shade-4;
        border-top: none;

        .address-picklist {
            background-color: $color-white;
            max-height: 330px;
            margin: 0;
            padding: 0;
            overflow: auto;
            overflow-x: hidden;
            text-align: left;
        }

        .address-picklist div {
            color: $color-shade-3;
            padding: $address-padding;
            display: block;
            cursor: pointer;

            &:hover,
            &:focus {
                background-color: $color-shade-1;
            }
        }

        .use-address-entered {
            @include btn-skin-4;
            @include btn-small;
            margin: $address-padding;
        }
    }

    .submmit-address,
    .search-again-link {
        @include btn-skin-4;
        @include btn-small;
        margin: $base-spacing-2 0;
    }

    .loader.loader-inline {
        position: absolute;
        top: $base-spacing-1;
        right: $base-spacing-1;

        .spinner {
            @include loader02;
            width: $base-spacing-5;
            height: $base-spacing-5;
            border-width: 6px;
            border-color: rgba($color-primary-1, 0.25);
            border-top-color: $color-primary-1;
        }
    }
}
