$mobile-search-trigger-bg:                $color-white;
$mobile-search-icon-width:                32px;
$mobile-search-trigger-icon-color:        $color-shade-3;

.c-mobile-menu-search-trigger {
    width: $mobile-menu-trigger-width;
    height: $mobile-menu-trigger-width;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.15s opacity ease-in-out;
    padding: 0;

    svg {
        height: $mobile-search-icon-width;
        width: $mobile-search-icon-width;
        color: $mobile-search-trigger-icon-color;
    }
}
