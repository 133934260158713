
section.s-contact-us {
    .svg-icon {
        margin-right: $base-spacing-1;
    }
}

// section.s-download-application {
//     .c-download-item {
//         border-top: 1px solid $color-primary-shade-2;
//         border-bottom: 1px solid $color-primary-shade-2;
//         margin-bottom: $base-spacing-5;
//     }

//     .container.bg-theme-2 {
//         .c-download-item .item-container .item-icon {
//             background-color: $color-white;
//         }
//     }
// }
