.form-member-join,
.form-dependant-item,
.form-custom {
    .dependant-item-number::before {
        counter-increment: form-dependant-item-counter;
        content: counter(form-dependant-item-counter);
    }

    .dependant-item-title {
        span {
            @include display-heading-large;
            background: none;
        }
    }

    .dependant-item-header {
        @include base-mobile-form-layout;

        .dependent-item-header-wrapper {
            display: flex;
            justify-content: space-between;
        }
    }
}

@media (min-width: $screen-sm) {
    .form-member-join,
    .form-dependant-item,
    .form-custom {
        .dependant-item-header {
            @include base-desktop-form-layout;
        }
    }
}
