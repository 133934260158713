$listing-items-section-padding:     $base-spacing-3 0;
$listing-item-margin:               $base-spacing-3;

.listing-items {
    padding: $listing-items-section-padding;

    .items-resources-wrapper {
        @include row;
        margin-bottom: $listing-item-margin;

        .listing-items,
        .listing-resources {
            width: 100%;

            .items-wrapper .listing-no-results button {
                display: inline;
                text-decoration: underline;
            }
        }

        .items-wrapper .pagination-wrapper {
            margin-bottom: $base-spacing-5;
        }
    }
}

@media (min-width: $screen-sm) {
    .items-resources-wrapper:not(.has-resources) {
        .items-wrapper {
            @include col(12);
        }
    }

    .items-resources-wrapper.has-resources {
        .items-wrapper {
            @include col(9);
        }

        .listing-resources {
            @include col(3);
        }
    }
}
