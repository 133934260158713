// Filtered Listing
//----------------------------------------
$listing-icon-color:                $color-primary-1;

// Import all the styles used by the filtered listing

// Filters
@import "filters/fl-filters";
@import "filters/keyword-filter/keyword-filter";
@import "filters/multi-checkbox-filter/multi-checkbox-filter";
@import "filters/single-checkbox-filter/single-checkbox-filter";
@import "filters/tag-filter/tag-filter";
@import "active-filters/fl-active-filters";

// Toolbar
@import "toolbar/fl-toolbar";

// Items/ Results
@import "items/fl-listing-items";
@import "resources/fl-resources";

// Layouts w/ Top & Side overrides
@import "layouts/fl-layout";
@import "layouts/layout-filters-top";
@import "layouts/layout-filters-side";
