$outdated-color:        $color-white;
$outdated-bg-color:     $color-error;

#outdated {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    z-index: 1500;
    background-color: $outdated-bg-color;
    color: $outdated-color;
    padding: $base-spacing-4 0;

    h1 {
        color: $outdated-color;
        margin: $base-spacing-4 0 $base-spacing-3;
    }


    a {
        @extend %base-button-styles;
        background-color: $outdated-color;
        color: $outdated-bg-color;

        &:hover {
            background-color: $outdated-color;
            color: $outdated-bg-color;
        }
    }

    #last {
        position: absolute;
        top: $base-spacing-2;
        right: $base-spacing-2;
        width: $base-spacing-6;
        height: $base-spacing-6;
        cursor: pointer;
        margin-bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .svg-icon {
            width: 100%;
            height: 100%;
        }

        &[dir='rtl'] {
            right: auto !important;
            left: $base-spacing-2 !important;
        }
    }
}

@media (min-width: $screen-md) {
    #outdated {
        h1 {
            margin: 0 0 $base-spacing-3;
        }

        #last {
            top: $base-spacing-4;
        }
    }
}
