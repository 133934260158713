$unit-price-box-shadow:     0 0 6px 0 rgba(0, 0, 0, 0.1);

.c-risk-chart {
    box-shadow: $unit-price-box-shadow;
    width: 100%;
    border: 1px solid $color-disabled;
    padding: $base-spacing-3 $base-spacing-2;

    .recharts-responsive-container {
        display: flex;
        align-items: center;
    }
}
