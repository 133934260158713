.s-cta-grid {
    background-color: $color-primary-shade-1;

    .grid-heading {
        h2 {
            @include display-medium;
            text-transform: none;
        }

        h3 {
            @include display-small;
            margin-top: $base-spacing-1;
            margin-bottom: $base-spacing-6;
        }
    }

    .cta-tile {
        display: flex;
        min-height: 310px;
        text-decoration: none;
        margin-bottom: $base-spacing-2;

        &:last-of-type {
            margin-bottom: 0;
        }

        .tile-content {
            padding: $base-spacing-8 $base-spacing-6;
            background-color: $color-white;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            transition: 0.3s all ease-in-out;
            width: 100%;
            min-height: 310px;

            .learn-more {
                display: flex;
                align-items: center;

                p {
                    @include body-normal-heavy;
                    color: $color-primary-1;
                    margin-bottom: 0;
                }

                svg {
                    margin-left: $base-spacing-1;
                    color: $color-primary-2;
                    transition: margin-left ease-in-out 0.15s;
                }
            }
        }

        .tile-image {
            background-position: center center;
            background-size: cover;
            display: none;
        }

        &:hover,
        &:focus {
            .tile-content {
                background-color: $color-primary-2;

                .learn-more svg {
                    margin-left: $base-spacing-2;
                    color: $color-primary-1;
                }
            }
        }
    }

    .subtitle {
        margin-top: $base-spacing-1;

        img {
            display: none;

            &.mobile-subtitle {
                display: block;
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .s-cta-grid {
        .container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-rows: 310px;
            grid-gap: $base-spacing-3;
            @include ie-grid(1fr 1fr 1fr 1fr);

            .grid-heading {
                grid-column: span 2;
                -ms-grid-column-span: 3;
                margin-bottom: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;

                h3 {
                    margin-bottom: 0;
                }
            }

            .cta-tile {
                margin-bottom: 0;
                @include ie-grid(1fr);
            }

            .cta-tile:first-of-type {
                grid-row: 2;
                grid-column: span 2;
                -ms-grid-row: 2;
                -ms-grid-column: 1;
                -ms-grid-column-span: 3;
            }

            .cta-tile:nth-of-type(2) {
                grid-row: 1 / 3;
                grid-column: 3 / -1;
                -ms-grid-row: 1;
                -ms-grid-row-span: 2;
                -ms-grid-column: 5;
                -ms-grid-column-span: 3;
            }

            .cta-tile:last-of-type {
                grid-row: 3;
                grid-column: span 4;
                -ms-grid-row: 3;
                -ms-grid-column: 1;
                -ms-grid-column-span: 7;
            }
        }

        .subtitle {
            img {
                display: block;

                &.mobile-subtitle {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: $screen-sm-max) {
    .s-cta-grid {
        .container {
            .cta-tile {
                &:first-of-type {
                    .tile-content {
                        background-color: $color-lifestage-build;
                    }
                }

                &:nth-of-type(2) {
                    .tile-content {
                        background-color: $color-lifestage-retire;
                    }
                }

                &:last-of-type {
                    .tile-content {
                        background-color: $color-lifestage-plan;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-md) {
    .s-cta-grid {
        .container {
            .grid-heading {
                padding: 0 $base-spacing-8;
            }

            .cta-tile .tile-image {
                display: block;
            }

            .cta-tile:first-of-type {
                display: grid;
                grid-gap: $base-spacing-3;
                grid-template-columns: 1fr 1fr;
                @include ie-grid(1fr 1fr);
            }

            .cta-tile:nth-of-type(2) {
                position: relative;
                @include ie-grid(1fr);

                .tile-content {
                    width: 50%;
                    height: calc(310px + #{$base-spacing-3});
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    z-index: 1;
                    border-top: $base-spacing-3 solid $color-primary-shade-1;
                    border-right: $base-spacing-3 solid $color-primary-shade-1;
                }

                .tile-image {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    top: 0;
                }
            }

            .cta-tile:last-of-type {
                display: grid;
                grid-gap: $base-spacing-3;
                grid-template-columns: 3fr 1fr;
                @include ie-grid(3fr 1fr);

                .tile-content {
                    grid-column: 2;
                    -ms-grid-column: 3;
                }

                .tile-image {
                    grid-column: 1;
                    -ms-grid-column: 1;
                    grid-row: 1;
                    -ms-grid-row: 1;
                }
            }
        }
    }
}

@media (min-width: $screen-md) and (max-width: $screen-md-max) {
    .s-cta-grid {
        .container .cta-tile .tile-content {
            padding: $base-spacing-4 $base-spacing-4;
        }
    }
}
