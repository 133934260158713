.s-tile-container,
.fl-tile-container {
    a[class$='-tile'] {
        width: 100%;
        margin-bottom: $base-spacing-3;
        display: block;
    }
}

@media (min-width: $screen-sm) {
    .s-tile-container,
    .fl-tile-container {
        a[class*='-tile'] {
            margin-bottom: 0;
        }

        &.tiles-wrap .row a[class*='-tile'] {
            margin-bottom: $base-spacing-3;
        }

        &.col-three {
            a[class*='-tile'] {
                @include col(4);
            }
        }

        &.col-four {
            a[class*='-tile'] {
                @include col(3);
            }
        }
    }
}
