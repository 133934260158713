.card-cta {
    position: relative;
    border-radius: $base-spacing-3;
    padding: 20px $base-spacing-3;
    color: $color-white;
    border: 1px solid $color-white;
    background-color: transparent;
    display: block;
    text-decoration: none;
    word-break: break-word;
    height: 100%;

    &:hover {
        color: $color-white;
        background-color: $color-primary-shade-6;
    }

    .card-cta__inner {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: $base-spacing-3;
    }

    .card-cta__thumbnail {
        max-width: $base-spacing-16;
        width: 100%;

        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }

    .card-cta__title {
        font-weight: 900;
        @include display-heading-medium;
        color: $color-white;
    }
}

@media (min-width: $screen-sm) {
    .card-cta {
        padding: $base-spacing-4 $base-spacing-3;
    }

    .card-cta__inner {
        justify-content: center;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .card-cta__title {
        text-align: center;
    }
}
