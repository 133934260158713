$keyword-icon-color:                $color-primary-1;
$keyword-padding:                   $base-spacing-6;

.keyword-filter {
    position: relative;
    flex-grow: 1;
    width: 100%;
    max-width: none !important;

    .svg-icon {
        position: absolute;
        top: 12px;
        left: 8px;
        color: $keyword-icon-color;
    }

    input[type="text"] {
        max-width: 100%;
        margin-bottom: 0;
        padding-left: $keyword-padding;
    }
}
