section.s-banner-article {
    background-color: $color-shade-1;
    padding-bottom: 0;
    padding-top: 0;

    .banner-content {
        margin-top: 0;

        img {
            display: none;
            width: 100%;
            object-fit: cover;
            border-top-left-radius: $base-spacing-4;
            border-top-right-radius: $base-spacing-4;
        }

        .mobile-banner {
            display: block;
        }
    }

    .c-breadcrumbs,
    .page-header__breadcrumb {
        position: absolute;
        opacity: 0;
    }
}

@media (min-width: $screen-sm) {
    section.s-banner-article {
        .c-breadcrumbs,
        .page-header__breadcrumb {
            padding: $base-spacing-3 $base-spacing-4;
            position: relative;
            opacity: 1;
        }

        .banner-content {
            img {
                display: block;
                min-height: 650px;
            }

            .mobile-banner {
                display: none;
            }
        }
    }
}

@media (min-width: $screen-md) {
    section.s-banner-article {
        .banner-content {
            padding: $base-spacing-8 0 0;
        }

        .c-breadcrumbs,
        .page-header__breadcrumb {
            padding: 0 0 $base-spacing-7;
        }
    }

    .s-desktop-header + .page-content section.s-banner-article {
        margin-top: -174px;
        padding-top: 174px;
        z-index: 1;
    }
}

@media (min-width: $screen-lg) {
    section.s-banner-article {
        border-bottom-left-radius: $base-spacing-3;
        border-bottom-right-radius: $base-spacing-3;
    }
}

@media (min-width: $screen-xlg) {
    section.s-banner-article {
        border-bottom-left-radius: $base-spacing-10;
        border-bottom-right-radius: $base-spacing-10;
    }
}
