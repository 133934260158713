// Fonts
// ======================================================
$font-path: './../assets/fonts';

// Websafe font stacks
$cursive: 'Bradley Hand', 'Comic Sans MS', 'Comic Sans', cursive;
$helvetica: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$helvetica-bold: 'HelveticaNeue-Bold', 'Helvetica Neue Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;

// Custom Webfonts
@mixin fontFace($name, $filename, $id, $weight: 'normal') {
    @font-face {
        font-family: '#{$name}';
        src: url('#{$font-path}/#{$filename}.eot');
        src:
            url('#{$font-path}/#{$filename}.eot?#iefix') format('embedded-opentype'),
            // url('#{$font-path}/#{$filename}.woff2') format('woff2'),
            url('#{$font-path}/#{$filename}.woff') format('woff'),
            url('#{$font-path}/#{$filename}.ttf')  format('truetype'),
            url('#{$font-path}/#{$filename}.svg')  format('svg');
        font-weight: $weight;
        font-style: normal;
        font-display: swap;
    }
}

// Name, filename, svgId, weight (optional)
@include fontFace('Avenir Black', 'avenir-black-hinted-webfont', 'AvenirBlack');
@include fontFace('Avenir Heavy', 'avenir-heavy-hinted-webfont', 'AvenirHeavy');
@include fontFace('Avenir Light', 'Avenir-Light', 'AvenirLight');
@include fontFace('Avenir Medium', 'Avenir-Medium', 'AvenirMedium');
@include fontFace('Avenir Roman', 'Avenir-Roman', 'AvenirRoman');
@include fontFace('NGS Noteworthy', 'NGSNoteworthy', 'NGSNoteworthy');

// Custom font stack (w/ websafe fallback)
$Avenir-Black: 'Avenir Black', $helvetica-bold;
$Avenir-Heavy: 'Avenir Heavy', $helvetica-bold;
$Avenir-Light: 'Avenir Light', $helvetica;
$Avenir-Medium: 'Avenir Medium', $helvetica;
$Avenir-Roman: 'Avenir Roman', $helvetica;

$NGSNoteworthy: 'NGS Noteworthy', $cursive;
