section.s-footer {
    background-color: $color-primary-1;
    background-image: url("../../assets/images/footer-flare.png");
    background-repeat: no-repeat;
    background-size: 442px 442px;
    background-position: bottom -80px right -180px;
    border-top-right-radius: $base-spacing-4;
    border-top-left-radius: $base-spacing-4;
    padding-top: $base-spacing-8;
    padding-bottom: $base-spacing-12;

    .logo-holder {
        width: 100%;
        margin-bottom: $base-spacing-6;
    }

    .logo-img {
        margin: 0;
        padding: 0;
    }

    .copyright {
        margin-bottom: $base-spacing-8;
    }

    .footer-header {
        @include display-heading-large;
        color: $color-white;
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: $base-spacing-3;

        &.underline {
            padding-bottom: $base-spacing-2;
        }

        > .svg-icon {
            color: $color-accent-2;
            width: 150px;
            height: 12px;
            position: absolute;
            left: 0;
            bottom: 4px;
        }
    }

    .footer-social {
        margin: $base-spacing-2 0 $base-spacing-4;
    }

    .footer-contacts {
        @include body-footer;
        color: $color-white;
    }

    .footer-phone-link {
        color: $color-white;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .footer-small-info,
    .footer-small-info p {
        @include body-extra-small;
        color: $color-primary-3;
        line-height: 20px;

        a {
            color: $color-white;
        }
    }

    .footer-small-info-link {
        @include body-extra-small;
        color: $color-primary-3;
        line-height: 20px;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .footer-menu {
        margin-bottom: $base-spacing-1;
    }

    .footer-nav-ul {
        @include list-reset;
        margin-bottom: $base-spacing-8;

        &.small-bottom-gap {
            margin-bottom: $base-spacing-5;
        }

        > li {
            margin-bottom: 12px;
        }

        > li > a {
            @include body-footer;
            color: $color-white;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .simple-footer {
        .copyright {
            margin-bottom: 0;

            a {
                display: block;

                img {
                    margin-bottom: $base-spacing-2;
                }
            }

            .awards-logo {
                margin-top: $base-spacing-2;
                margin-bottom: 0;
            }
        }
    }

    .acknowledge-holder {
        @include body-footer;
        font-weight: 350;
        color: $color-white;
        margin-top: $base-spacing-12;
    }

    .acknowledge-text {
        width: 100%;
    }

    .flag-holder {
        width: 100%;
        max-width: 212px;
        display: flex;
        justify-content: space-between;
        margin-bottom: $base-spacing-4;
    }

    .flag-img {
        width: 93px;
        height: 62px;
    }
}

@media (min-width: $screen-sm) {
    section.s-footer {
        background-size: 590px 590px;
        background-position: bottom -174px right -347px;
    }
}

@media (min-width: $screen-md) {
    section.s-footer {
        background-size: 590px 590px;
        background-position: top -295px right -80px;
        padding-top: $base-spacing-14;
        border-top-right-radius: $base-spacing-10;
        border-top-left-radius: $base-spacing-10;

        .footer-wrapper {
            display: flex;
            justify-content: space-between;
        }

        .logo-holder {
            margin-bottom: $base-spacing-8;
        }

        .copyright {
            margin-bottom: 0;
            width: 40%;
            padding-right: $base-spacing-4;
        }

        .footer-menu {
            display: flex;
            justify-content: space-between;
            width: 60%;
            position: relative;
        }

        .footer-small-info {
            max-width: 370px;
        }

        .footer-nav-ul {
            margin-bottom: 212px;

            &.small-bottom-gap {
                margin-bottom: 212px;
            }

            > li {
                min-height: 28px;
            }
        }

        .simple-footer {
            .copyright {
                display: flex;
                margin-bottom: 0;
                flex-wrap: wrap;
                justify-content: space-between;

                a {
                    display: block;

                    img {
                        margin-bottom: $base-spacing-2;
                    }
                }

                .awards-logo {
                    margin-top: 0;
                }
            }
        }

        .acknowledge-holder {
            display: flex;
            flex-direction: column;
            margin-top: 0;
            position: absolute;
            max-width: 100%;
            left: 0;
            bottom: 0;
        }
    }
}
