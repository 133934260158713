$search-bar-height-mobile: 80px;
$search-bar-height-desktop: 120px;
$search-bar-icon-size: 24px;
$search-bar-input-min-width: 100px;
$search-bar-inner-max-width: 500px;
$search-bar-transition: $base-transition-medium $base-transition-curve;
$search-bar-overlay-transition: opacity $base-transition-medium $base-transition-curve;
$search-bar-overlay-opacity: 0.7;
$search-bar-overlay-bg: rgba($color-white, 0.8);
$search-bar-bg-color: $color-primary-1;
$search-bar-input-color: $color-white;
$search-bar-label: $color-primary-3;
$search-bar-icon-color: $color-primary-1;
$search-bar-icon-color-hover: $color-primary-2;

.search-bar {
    position: relative;
    display: block;
    align-items: center;
    justify-content: flex-end;
    z-index: 101;
    overflow: hidden;
    transition: height $search-bar-transition;
    background-color: $color-parchment-shade-1;
    border-radius: $base-spacing-4;
    height: $base-spacing-5;

    &.open {
        height: $search-bar-height-mobile;

        .search-bar-content {
            display: flex;
        }
    }

    .search-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $search-bar-overlay-bg;
        opacity: 0;
        visibility: hidden;
        transition: $search-bar-overlay-transition;

        &.is-visible {
            opacity: $search-bar-overlay-opacity;
            visibility: visible;
            z-index: 1;
        }
    }

    .search-bar-content {
        display: none;
        position: relative;
        align-items: center;
        height: 100%;
        z-index: 2;
        background-color: $search-bar-bg-color;

        .search-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding-top: 0;
            padding-bottom: 0;
        }

        label {
            @include display-heading-medium;
            color: $search-bar-label;
            margin: 0;
        }
    }

    .search-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 56px;
        border-bottom: solid 1px $search-bar-label;
    }

    .search-input {
        @include body-normal-medium;
        background-color: $color-parchment-shade-1;
        color: $color-shade-3;
        border: none;
        padding: 0 $base-spacing-3;
    }

    ::placeholder {
        color: $color-shade-3;
    }

    .search-button {
        display: none;
    }

    .search-icon,
    .search-close {
        flex-shrink: 0;
        width: $search-bar-icon-size;
        height: $search-bar-icon-size;
        padding: 0;
        cursor: pointer;
        margin-right: $base-spacing-2;

        .svg-icon {
            position: static;
            width: $search-bar-icon-size;
            height: $search-bar-icon-size;
            color: $search-bar-icon-color;
        }

        &:hover,
        &:focus {
            .svg-icon {
                color: $search-bar-icon-color-hover;
            }
        }
    }

    .search-close {
        margin-left: $base-spacing-1;
        display: block;
    }
}

.search-bar {
    .search-overlay,
    .search-inner label,
    .search-close {
        display: none;
    }

    .search-bar-content {
        background-color: $color-parchment-shade-1;
        display: block;
    }

    .search-container {
        padding: 0;
    }

    .search-inner {
        border: 0 none;
    }
}

.azure-search {
    margin-bottom: $base-spacing-2;
    position: static;
    height: auto;

    .search-bar-content {
        display: block;
    }

    .search-bar-content .search-container {
        justify-content: flex-start;
    }
}

@media (min-width: $screen-sm) {
    .search-bar {
        &.open {
            height: $search-bar-height-desktop;
        }

        .search-container {
            @include container;
        }

        .search-button {
            display: inline-block;
        }

        .search-close {
            margin-left: $base-spacing-2;
        }

        .search-icon {
            margin-right: unset;
        }
    }
}

@media (min-width: $screen-md) {
    .search-bar {
        max-width: 280px;

        .search-container {
            padding: 0 $base-spacing-2;
        }

        .search-close {
            margin-left: $base-spacing-3;
        }
    }
}
