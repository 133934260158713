section.s-three-col-content-blocks {
    background-color: $color-primary-shade-1;
    padding-top: $base-spacing-10;
    padding-bottom: $base-spacing-10;

    h2 {
        text-align: center;
        margin-bottom: $base-spacing-10;
    }

    .row {
        > div {
            @include stack();
        }
    }

    .btn-wrapper {
        display: flex;
        justify-content: center;
    }

    .btn-skin-4 {
        margin-top: $base-spacing-5;
    }
}

@media (min-width: $screen-sm) {
    .s-three-col-content-blocks {
        .row {
            > div {
                @include col(6);
                margin-bottom: $base-spacing-3;
            }
        }
    }
}

@media (min-width: $screen-md) {
    .s-three-col-content-blocks {
        .row {
            > div {
                @include col(4);
            }
        }
    }
}
