$logo-height: 55px;

.server-error {
    padding-top: 0;

    .page-content {
        background-image: url("../../assets/images/error-background.jpg");
        height: 100%;
        color: $color-shade-3;
        background-repeat: no-repeat;
        background-size: cover;
        padding: $base-spacing-4;
    }

    .logo {
        height: $logo-height;
    }

    .content-wrapper {
        display: flex;
        align-items: center;
        margin-top: $base-spacing-3;
    }

    .content-cols {
        display: flex;
        margin-top: $base-spacing-3;
        flex-wrap: wrap;
    }

    h1 {
        @include display-large;
        text-transform: none;
    }

    span {
        display: block;
        margin-bottom: $base-spacing-3;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .login {
        margin-top: $base-spacing-4;
        display: flex;
        align-items: center;

        a {
            margin-top: $base-spacing-2;
            padding-left: $base-spacing-6;
            padding-right: $base-spacing-6;
        }
    }

    p {
        margin-bottom: 0;
    }
}

@media (min-width: $screen-xs) {
    .server-error {
        .page-content {
            height: 100%;
        }

        .content-cols {
            display: flex;
            flex-wrap: nowrap;
        }

        .login {
            margin-top: 0;
            position: relative;
            padding-left: 77px;

            &::before {
                background-color: $color-primary-shade-2;
                content: '';
                width: 1px;
                display: block;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                margin: 8px 0;
            }
        }

        .contact {
            position: relative;
            margin-right: 77px;
        }
    }
}

@media (min-width: $screen-xs-max) {
    .server-error {
        height: 100%;

        .content-wrapper {
            height: calc(100% - 200px);
            margin-left: 75px;
        }
    }
}

@media (min-width: $screen-md) {
    .server-error {
        .content-wrapper {
            margin-left: 175px;
        }
    }
}
