$label-padding: $base-spacing-4;
$back-icon-size: $base-spacing-2;

.c-asset-allocation {
    width: 100%;
    display: flex;
    flex-direction: column;

    tr {
        td:first-child,
        th:first-child {
            padding-left: $base-spacing-7;
            padding-right: $base-spacing-6;
            text-align: left;
        }

        &.has-drilldown {
            cursor: pointer;

            td.row-label {
                position: relative;

                .drilldown-icon {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    right: $base-spacing-1;
                    top: 0;
                    bottom: 0;
                }
            }

            &:hover,
            &:focus {
                td .drilldown-icon {
                    right: 0;

                    svg {
                        color: $color-primary-2;
                    }
                }
            }
        }

        &.asset-group {
            td {
                font-weight: 800;
            }
        }

        .row-label {
            position: relative;

            .label-color {
                position: absolute;
                left: 0;
                margin-left: $base-spacing-1;
                height: $base-spacing-3;
                width: $base-spacing-3;
            }
        }

        .value-cell {
            text-align: right;
        }
    }

    .back-button {
        align-self: flex-start;
        margin-bottom: $base-spacing-2;
        display: flex;
        align-items: flex-start;

        .back-text,
        .back-icon svg {
            color: $color-primary-2;
        }

        .back-icon {
            display: inline-block;
            height: $back-icon-size;
            margin-right: $base-spacing-1;

            svg {
                height: $back-icon-size;
                width: $back-icon-size;
            }
        }

        &:hover,
        &:focus {
            .back-text,
            .back-icon svg {
                color: $color-black;
            }
        }
    }

    .drilldown-container {
        width: 100%;

        h3 {
            margin-bottom: $base-spacing-3;
        }

        .tab-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin-bottom: $base-spacing-3;
        }

        .drilldown-tabs {
            display: flex;
            justify-content: flex-end;

            button {
                margin-left: $base-spacing-2;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    .tooltip-wrapper {
        text-align: left;

        .tooltip {
            position: relative;
            overflow: hidden;
            margin-left: 8px;
        }
    }

    .pie-wrapper {
        width: 100%;
        padding: $base-spacing-2 $base-spacing-2 0;
        margin-bottom: $base-spacing-3;
        position: relative;
        display: flex;
        align-items: center;
        z-index: 2;
        overflow: visible;

        // Commented out due to alignment issue in mobile
        // Also removed the div element that contained the background from asset-allocation-pie
        // .pie-bg {
        //     background-color: $color-black;
        //     opacity: 0.8;
        //     border-radius: 100%;
        //     position: absolute;
        //     width: 100%;
        //     padding-top: 100%;
        // }

        > div:first-child > svg + div {
            overflow: visible;
        }

        svg {
            filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.15));
        }
    }
}

@media (min-width: $screen-xs) {
    .c-asset-allocation {
        .pie-wrapper {
            padding-top: $base-spacing-4;
            padding-left: $base-spacing-4;
            padding-right: $base-spacing-4;
        }
    }
}

@media (min-width: $screen-sm) {
    .c-asset-allocation {
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        position: relative;
        z-index: 2;

        .back-button {
            align-self: center;
            margin-bottom: 0;
        }

        .drilldown-container .tab-container {
            flex-direction: row;
        }

        .pie-wrapper {
            align-items: flex-start;
            max-width: calc(100% / 3);
            margin-top: $base-spacing-1;
            margin-right: $base-spacing-5;
            margin-bottom: 0;
            padding-top: $base-spacing-2;
            padding-left: 0;
            padding-right: 0;
        }
    }
}
