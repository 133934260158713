$image-overlap:                         $base-spacing-10 * (-1);
$image-height-mobile:                   234px;
$image-height-desktop:                  320px;
$tile-background-colour:                $color-primary-shade-1;
$tile-hover-background-colour:          $color-shade-1;
$tile-hover-heading-colour:             $color-black;
$tile-hover-animation:                  0.15s ease-in-out all;
$theme-tile-background:                 $color-white;

.c-tile-article {
    background-color: $tile-background-colour;
    text-decoration: none;
    display: block;
    transition: $tile-hover-animation;

    .tile-image {
        height: $image-height-mobile;
        display: block;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .tile-content {
        padding: $base-spacing-4;
        text-align: center;
        background-color: $tile-background-colour;
        margin: $image-overlap $base-spacing-3 0;
        position: relative;
        z-index: 1;
        flex: 1;
        align-content: center;
        transition: $tile-hover-animation;

        .cat-tag {
            @include tag;
            border: 1px solid $color-shade-3;
            border-radius: 20px;
            color: $color-black;
            display: inline-block;
            padding: 4px 20px;
            margin-bottom: $base-spacing-2;
        }

        h3 {
            @include display-heading-medium();
            margin-bottom: $base-spacing-2;
            transition: $tile-hover-animation;
        }

        .date {
            @include body-caption;
            color: $color-black;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            .separator {
                display: inline-block;
                margin: 0 $base-spacing-1;
                width: 1px;
                height: 20px;
                background-color: $color-primary-shade-2;
            }
        }
    }

    &:hover,
    &:focus {
        background-color: $tile-hover-background-colour;

        .tile-content {
            background-color: $tile-hover-background-colour;

            h3 {
                color: $tile-hover-heading-colour;
            }
        }
    }
}

// Available theme
.bg-theme-3 {
    .c-tile-article,
    .tile-content {
        background-color: $theme-tile-background;

        &:hover,
        &:focus {
            background-color: $tile-background-colour;

            .tile-content {
                background-color: $tile-background-colour;
            }
        }
    }
}

@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    .c-tile-article {
        .tile-content {
            h3 {
                @include display-heading-small;
                color: $color-primary-1;
            }

            .date {
                flex-direction: column;

                .separator {
                    transform: rotate(90deg);
                }
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .c-tile-article {
        .tile-content {
            margin: $image-overlap $base-spacing-4 0;
            padding: $base-spacing-6;
            text-align: left;

            .date {
                align-items: flex-start;
                justify-content: flex-start;
            }
        }
    }
}


@media (min-width: $screen-md) {
    .c-tile-article {
        .tile-image {
            height: $image-height-desktop;
        }
    }
}
