$news-tile-height:              320px;

.c-news-tile {
    display: block;
    width: 100%;
    text-decoration: none;

    .tile-image {
        height: $news-tile-height;
        background-position: center center;
        background-size: cover;
    }

    .tile-content {
        text-decoration: none;
        padding: $base-spacing-7 $base-spacing-8;
        text-align: center;

        .btn-tag {
            border-color: $color-primary-1;
            color: $color-primary-1;
            align-self: center;

            &:hover {
                color: $color-white;
            }
        }

        .btn-tag,
        h2 {
            margin-bottom: $base-spacing-2;
        }

        img {
            margin: $base-spacing-2 0;
        }

        .date {
            @include body-caption;
            color: $color-black;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            .separator {
                display: inline-block;
                margin: 0 $base-spacing-1;
                width: 1px;
                height: 20px;
                background-color: $color-primary-1;
            }
        }
    }

    // &.getting-started {
    //     background-color: $color-lifestage-start;

    //     &:hover,
    //     &:focus {
    //         background-color: rgba($color-lifestage-start, 0.7);
    //     }
    // }

    // &.building-wealth {
    //     background-color: $color-lifestage-build;

    //     &:hover,
    //     &:focus {
    //         background-color: rgba($color-lifestage-build, 0.7);
    //     }
    // }

    // &.planning {
    //     background-color: $color-lifestage-plan;

    //     &:hover,
    //     &:focus {
    //         background-color: rgba($color-lifestage-plan, 0.7);
    //     }
    // }

    // &.retirement {
    //     background-color: $color-lifestage-retire;

    //     &:hover,
    //     &:focus {
    //         background-color: rgba($color-lifestage-retire, 0.7);
    //     }
    // }
}

@media (min-width: $screen-md) {
    .c-news-tile {
        &.horizontal {
            display: grid;
            grid-template-columns: 1fr 1fr;
            @include ie-grid(1fr 1fr);

            .tile-image {
                min-height: $news-tile-height * 2;
                height: 100%;
                order: 2;
            }

            .tile-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                order: 1;

                img {
                    align-self: center;
                }
            }
        }
    }
}
