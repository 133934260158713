// Notifications
//----------------------------------------
$notification-padding:              $base-spacing-3 0;
$notification-transition:           all 300ms cubic-bezier(0.66, 0.01, 0.51, 1);

$notification-icon-width:           $base-spacing-3;
$notification-icon-height:          $notification-icon-width;

$notification-content-color:        $color-white;
$notification-icon-color:           $color-primary-2;
$notification-background:           $color-primary-1;
$notification-link-color:           $color-primary-2;
$notification-link-dark:            $color-black;

$notification-bg-info: $color-secondary-7;
$notification-color-info: $color-black;

$notification-bg-warning-1: $color-warning-1;
$notification-color-warning-1: $color-black;

$notification-bg-warning-2: $color-warning-2;
$notification-color-warning-2: $color-white;

$notification-bg-warning-3: $color-shade-3;
$notification-color-warning-3: $color-white;

$notification-bg-error: $color-error-1;
$notification-color-error: $color-black;

section.s-notification {
    background: $notification-background;
    position: relative;
    overflow: hidden;
    padding: $notification-padding;
    transition: $notification-transition;

    .container {
        position: relative;
    }

    &.hide {
        max-height: 0;
        visibility: hidden;
        opacity: 0;
        margin: 0;
        position: absolute;
        z-index: -1;
    }

    .notification-icon {
        width: $base-spacing-6;
        height: $base-spacing-6;
    }

    .notification-col-content {
        display: flex;
        flex-direction: column;
        gap: $base-spacing-1;

        p {
            margin-bottom: unset;
            font-size: unset;
            line-height: unset;
        }
    }

    .notification-title {
        @include body-normal-heavy;
    }

    .btn-text {
        display: inline-flex;
        gap: $base-spacing-1;
        color: inherit;
        text-decoration: underline;
        align-items: center;

        .svg-icon {
            height: $base-spacing-2;
            width: $base-spacing-2;
            vertical-align: middle;
        }
    }

    &.light {
        .notification-title,
        a {
            color: $notification-link-dark;
        }

        svg {
            fill: $notification-link-dark;
        }

        p {
            color: $color-shade-3;
        }
    }

    .notification-content {
        @include display-heading-small;
        width: calc(100% - #{$base-spacing-4});
        display: flex;
        align-items: flex-start;
        gap: $base-spacing-4;
    }

    .close-icon {
        position: absolute;
        top: 0;
        right: $base-spacing-3;
        width: $notification-icon-width;
        height: $notification-icon-height;
        color: inherit;
    }

    &.hide {
        + .s-mobile-header {
            .mobile-nav {
                top: 72px;
            }
        }
    }

    + .s-mobile-header {
        .mobile-nav {
            top: 225px;
        }
    }

    &.skin-info {
        background: $notification-bg-info;
        color: $notification-color-info;
    }

    &.skin-warning-1 {
        background: $notification-bg-warning-1;
        color: $notification-color-warning-1;
    }

    &.skin-warning-2 {
        background: $notification-bg-warning-2;
        color: $notification-color-warning-2;
    }

    &.skin-warning-3 {
        background: $notification-bg-warning-3;
        color: $notification-color-warning-3;
    }

    &.skin-error {
        background: $notification-bg-error;
        color: $notification-color-error;
    }
}

@media (max-width: $screen-xs-max) {
    section.s-notification {
        .notification-content {
            flex-direction: column;
            gap: $base-spacing-1;
        }
    }
}

@media (min-width: $screen-sm-max) {
    section.s-notification {
        .container {
            display: flex;
            align-items: flex-start;
            justify-content: center;
        }

        .notification-title {
            margin-right: $base-spacing-2;
        }
    }
}

@media (max-width: $screen-md-max) {
    .has-notification:not(.notification-hidden) {
        padding-top: 0;

        .s-desktop-header {
            position: absolute;
        }
    }
}

@media (min-width: $screen-md) {
    .has-notification:not(.notification-hidden) {
        padding-top: 0;

        .s-desktop-header {
            position: relative;
        }
    }
}
