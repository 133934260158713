.form-toggle-group {
    display: none;
    @include stack;
}

@media (min-width: $screen-sm) {
    .form-toggle-group {
        @include col(12);
    }
}
