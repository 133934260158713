$tab-next-button: 100px;

.c-portfolio-holdings-table {
    .portfolio-tabs {
        margin-bottom: $base-spacing-4;
        position: relative;
        border-bottom: 1px solid $color-shade-1;
        overflow: hidden;

        .tab-wrapper {
            display: flex;
            overflow-x: none;
            overflow-y: auto;
            -ms-overflow-style: none;
            // scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .tab {
            border-bottom: 2px solid transparent;

            &.active {
                @include body-normal-heavy;
                background-color: $color-primary-shade-1;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                color: $color-primary-1;
                border-color: $color-primary-1;
            }

            &:hover {
                cursor: pointer;
                border-color: $color-primary-1;
            }

            &:last-child {
                margin-right: $tab-next-button;
            }
        }

        button:not(.next-icon, .prev-icon) {
            @include body-normal-medium;
            padding: $base-spacing-2;
            white-space: nowrap;

            &.active {
                @include body-normal-heavy;
                background-color: $color-primary-shade-1;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                color: $color-primary-2;
                position: relative;

                &::after {
                    content: "";
                    background-color: $color-primary-2;
                    display: block;
                    height: 2px;
                    position: absolute;
                    bottom: -1px;
                    left: 0;
                    width: 100%;
                }
            }

            &:hover {
                cursor: pointer;
                position: relative;

                &::after {
                    content: "";
                    background-color: $color-primary-2;
                    display: block;
                    height: 2px;
                    position: absolute;
                    bottom: -1px;
                    left: 0;
                    width: 100%;
                }
            }
        }

        .next-button,
        .prev-button {
            background: linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.7) 30%, rgba(255, 255, 255, 1) 100%);
            display: none;
            justify-content: flex-end;
            align-items: center;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: $tab-next-button;

            &:hover {
                &::after {
                    content: none;
                }
            }

            &.hide {
                display: none;
            }
        }

        .next-icon,
        .prev-icon {
            background-color: $color-primary-shade-1;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: $base-spacing-6;
            height: $base-spacing-6;
            padding: 0;

            svg {
                color: $color-primary-1;
            }
        }

        .prev-button {
            background: linear-gradient(-90deg, transparent 0%, rgba(255, 255, 255, 0.7) 30%, rgba(255, 255, 255, 1) 100%);
            left: 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }

    table {
        th:first-child {
            text-align: center;
        }

        td:not(:first-child) {
            text-align: center;
        }

        td {
            width: 200px;
            text-align: center;
        }
    }

    .portfolio-pagination {
        margin-top: $base-spacing-3;

        > span {
            display: block;
            margin-bottom: $base-spacing-2;
            text-align: center;
        }

        nav {
            margin-left: auto;

            button {
                @include body-small;
                color: $color-shade-3;
            }
        }
    }

    .total:not(:first-child) > strong {
        display: none;
    }

    ::-webkit-scrollbar {
        display: none;
    }
}

@media (min-width: $screen-sm) {
    .c-portfolio-holdings-table {
        .portfolio-pagination {
            display: flex;

            > span {
                display: flex;
                align-items: center;
                margin-bottom: 0;
                text-align: left;
            }
        }
    }
}


@media (min-width: $screen-md) {
    .c-portfolio-holdings-table {
        .portfolio-tabs {
            .next-button {
                display: flex;
            }
        }
    }
}
