$investment-options-box-shadow:  0 0 5px 0 rgba(0, 0, 0, 0.2);
$investment-icon-size: 18px;

.c-investment-summary-chart {
    display: flex;
    flex-wrap: wrap-reverse;

    .graph-wrapper {
        width: 100%;
    }

    .graph-content {
        svg {
            overflow: visible;
        }
    }

    .options-wrapper {
        padding: $base-spacing-2 $base-spacing-4;
        box-shadow: $investment-options-box-shadow;
        margin-bottom: $base-spacing-4;
        width: 100%;

        small {
            margin-top: $base-spacing-2;
        }
    }

    .option-toggle-trigger {
        display: block;
        min-height: $base-spacing-5;
        padding-left: 0;
        padding-right: $base-spacing-3;
        position: relative;
        text-align: right;

        @include bg-icon('caret-up', after, $color-black) {
            position: absolute;
            top: calc(50% - #{$base-spacing-2 / 2});
            right: 0;
            width: $base-spacing-2;
            height: $base-spacing-2;
            content: "";
        }

        &.option-hidden {
            @include bg-icon('caret-down', after, $color-black) {
                position: absolute;
                top: calc(50% - #{$base-spacing-2 / 2});
                right: 0;
                width: $base-spacing-2;
                height: $base-spacing-2;
                content: "";
            }
        }
    }

    .checkbox-wrapper {
        margin-top: $base-spacing-1;

        p {
            margin: $base-spacing-2 0;
        }

        input[type="checkbox"]:checked {
            + div {
                > label,
                > span {
                    position: relative;

                    @include bg-icon($checkbox-icon, after, $color-white) {
                        position: absolute;
                        top: 2px;
                        left: 2px;
                        width: $investment-icon-size;
                        height: $investment-icon-size;
                        content: "";
                    }
                }

                > span + label {
                    position: absolute;

                    &::after {
                        content: none;
                    }
                }
            }
        }
    }

    .input-wrapper {
        margin-bottom: $base-spacing-1;
    }

    .investment-option {
        display: flex;
        align-items: center;
        width: 100%;

        span + label {
            border: 0 none;
            padding-left: $base-spacing-4;
            position: absolute;
            left: 0;
            top: 0;
            width: auto;
        }

        span,
        label {
            min-width: $checkbox-size;
            height: $checkbox-size;
            box-sizing: border-box;
            display: inline-block;
            border: 1px solid $input-border-color;
            background-color: transparent;
            border-radius: $input-radius;
            margin-bottom: 0;
            margin-right: $base-spacing-1;
            cursor: pointer;
            transition:
                border-color $input-transition-speed $input-transition-curve,
                background-color $input-transition-speed $input-transition-curve;

            &::before {
                content: none;
            }
        }

        a {
            border-radius: $input-radius;
            color: $color-white;
            padding: $base-spacing-1 / 2 $base-spacing-4 $base-spacing-1 / 2 $base-spacing-1;
            position: relative;
            text-decoration: none;
            z-index: 1;

            @include bg-icon($investment-opt-icon, after, $color-white) {
                position: absolute;
                top: 7px;
                right: 10px;
                width: $investment-icon-size;
                height: $investment-icon-size;
                content: "";
                transition: right 0.2s;
            }

            &:hover {
                opacity: 0.7;

                &::after {
                    right: 5px;
                }
            }
        }
    }
}

// IE 11 Fix
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .c-investment-summary-chart {
        .graph-content > div {
            height: 550px !important;
        }
    }
}

@media (min-width: $screen-md) {
    .c-investment-summary-chart {
        flex-wrap: nowrap;

        .graph-wrapper {
            margin-right: $base-spacing-4;
        }

        .options-wrapper {
            min-width: 360px;
            width: 550px;

            small {
                display: none;
            }
        }

        .options-toggle button {
            display: none;
        }

        .checkbox-wrapper.hidden {
            display: block;
        }
    }
}
