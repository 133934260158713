$input-color:                       $color-black;
$input-bg:                          $color-white;
$input-height:                      $base-spacing-7; //48px
$input-padding:                     $base-spacing-2;
$input-radius:                      4px;
$input-border-color:                $color-shade-4;
$input-border-color-focus:          $color-black;

$input-disabled-opacity:            0.5;

$textarea-min-height:               150px;

$input-transition-speed:            0.2s;
$input-transition-curve:            ease-in-out;

@mixin base-input-styles {
    width: 100%;
    min-height: $input-height;
    padding: $input-padding;
    border: 1px solid $input-border-color;
    border-radius: $input-radius;
    color: $input-color;
    background-color: $input-bg;
    transition: border-color $input-transition-speed $input-transition-curve;
    font-family: $base-font-family;
    @include font-size($base-type);

    &:focus {
        outline: none;
        border-color: $input-border-color-focus;
        box-shadow: none;
    }
}

input:not([type="submit"]) {
    @include base-input-styles;

    &:disabled,
    &[readonly],
    &[readonly="readonly"],
    &[readonly="readonly"]:focus {
        border-color: $color-disabled;
        opacity: $input-disabled-opacity;
    }
}

textarea {
    @include base-input-styles;
    height: auto;
    min-height: $textarea-min-height;
    resize: vertical;
    display: block;

    &:disabled {
        opacity: $input-disabled-opacity;
    }
}

@media (max-width: $screen-md) {
    .form-member-join,
    .form-custom {
        input:not([type="submit"]) {
            margin-bottom: $base-spacing-1;
        }
    }
}
