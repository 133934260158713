section.s-brand-banner {
    position: relative;
    color: $color-white;
    margin: 0 auto;
    width: 100%;
    max-width: 1920px;
    border-radius: 0 0 $base-spacing-4 $base-spacing-4;
    overflow: hidden;
    padding: 125px 0 108px;

    &::before {
        content: '';
        display: block;
        background-position: 0 0;
        background-image: $gradient-brand-banner;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-height: 6000px;
    }

    .container {
        color: $color-white;
        position: relative;
        z-index: 20;
    }

    .brand-banner__illustration {
        position: absolute;
        z-index: 10;

        img {
            width: 100%;
            max-width: 1202px;
        }
    }

    .brand-banner__row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .col--content {
            grid-column: span 2;
        }
    }

    .banner-content {
        .banner-content__title {
            color: $color-white;
            text-transform: none;
        }

        .banner-content__content-box {
            @include body-intro;
            margin-top: $base-spacing-3;
        }

        .banner-content__cta-box {
            margin-top: $base-spacing-6;
            margin-bottom: $base-spacing-4;
            display: flex;
            flex-wrap: wrap;
        }

        .banner-content__cta-col {
            width: auto;
            margin-top: $base-spacing-3;
            margin-right: $base-spacing-3;
        }
    }

    .brand-banner__flare {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translate(600px, 290px);
        z-index: 10;
        width: 1120px;
        height: 1120px;
        color: $color-secondary-shade-4;
        display: none;

        .svg-icon {
            width: 100%;
            height: 100%;
        }
    }

    .brand-content__cta-cards + .brand-panel {
        &.brand-panel__wrapper {
            margin-top: $base-spacing-8;
        }
    }

    .brand-panel {
        &.brand-panel__wrapper {
            margin-top: $base-spacing-8;
            position: relative;
            z-index: 20;
        }

        .brand-panel__content-box {
            max-width: 800px;
        }

        .brand-panel__title {
            font-weight: 900;

            .display-medium {
                color: $color-white;
                text-transform: none;
            }

            .heading-underline {
                color: $color-accent-2;
                max-width: 200px;
                margin-top: $base-spacing-2;

                .svg-icon {
                    width: 100%;
                }
            }
        }

        .brand-panel__text-content {
            @include body-description;
            margin-top: $base-spacing-4;
        }

        .brand-panel__panel-group {
            margin-top: $base-spacing-8;
        }
    }

    .brand-content__cta-cards {
        margin-top: $base-spacing-12;
    }
}

// mobile only
@media (max-width: $screen-xs-max) {
    section.s-brand-banner {
        .brand-banner__image-filler {
            position: relative;

            &::before {
                content: "";
                display: block;
                padding-top: 88.88%;
            }
        }

        .brand-banner__illustration {
            position: absolute;
            top: 0;
            width: 155%;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }
}

// tablet only
@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    section.s-brand-banner {
        .brand-banner__image-filler {
            position: relative;

            &::before {
                content: "";
                display: block;
                padding-top: 78.125%;
            }
        }

        .brand-banner__illustration {
            position: absolute;
            top: 0;
            width: 155%;
            left: 50%;
            transform: translate(-50%, -5%);
        }
    }
}

// from tablet and larger
@media (min-width: $screen-sm) {
    section.s-brand-banner {
        border-radius: 0 0 80px 80px;
        padding-bottom: 174px;

        &::before {
            min-height: 3340px;
        }

        &.no-sub-content {
            padding-bottom: 142px;
        }

        .banner-content {
            .banner-content__content-box {
                margin-top: $base-spacing-6;
            }

            .banner-content__cta-box {
                margin-top: $base-spacing-4;
            }

            .banner-content__cta-col {
                margin-top: $base-spacing-4;
            }

            .banner-cta {
                padding: 14px $base-spacing-4;
                font-size: 22px;
                line-height: 32px;
                min-height: 60px;
            }
        }

        .brand-panel {
            &.brand-panel__wrapper {
                margin-top: $base-spacing-12;
            }

            .brand-panel__panel-group {
                margin-top: $base-spacing-12;
            }
        }

        .brand-content__cta-cards + .brand-panel {
            &.brand-panel__wrapper {
                margin-top: $base-spacing-10;
            }
        }

        .brand-content__cta-cards {
            margin-top: $base-spacing-16;
        }
    }
}

// from desktop and larger
@media (min-width: $screen-md) {
    .s-desktop-header + .page-content section.s-brand-banner {
        margin-top: -174px;
        z-index: 1;
    }

    section.s-brand-banner {
        padding: 316px 0 196px;

        &.no-sub-content {
            padding-bottom: 142px;
        }

        .brand-banner__row {
            .col--content {
                grid-column: span 1;
            }
        }

        .brand-banner__illustration {
            position: absolute;
            top: 60px;
            right: 0;
            display: flex;
            width: 100%;
            justify-content: flex-end;

            > img {
                width: 80%;
            }
        }

        .brand-banner__flare {
            display: block;
        }

        .banner-content {
            .banner-content__cta-col {
                width: auto;

                &:first-child {
                    margin-right: $base-spacing-3;
                }
            }
        }

        .brand-panel {
            &.brand-panel__wrapper {
                margin-top: 198px;
            }
        }

        .brand-content__cta-cards + .brand-panel {
            &.brand-panel__wrapper {
                margin-top: $base-spacing-12;
            }
        }

        .brand-content__cta-cards {
            margin-top: 180px;
        }
    }
}

// from desktop and larger
@media (min-width: $screen-lg) {
    section.s-brand-banner {
        .brand-banner__illustration {
            > img {
                width: 100%;
            }
        }
    }
}
