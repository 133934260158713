$bg-tile-content-spacing:     $base-spacing-3;
$bg-tile-gradient:            linear-gradient(-180deg, rgba(34, 34, 34, 0) 20%, rgba(34, 34, 34, 0.5) 80%);
$bg-tile-text-color:          $color-black;

.c-bg-tile {
    position: relative;
    display: block;
    background-size: cover;
    background-position: 50% 50%;

    &:hover {
        text-decoration: none;
    }

    .tile-content {
        position: absolute;
        bottom: $bg-tile-content-spacing;
        left: $bg-tile-content-spacing;

        p.subtext {
            @include body-small;
            margin-bottom: 0;
            color: $bg-tile-text-color;
        }
    }

    // make it square
    &::before {
        display: block;
        padding-top: 100%;
        content: "";
    }

    // gradient overlay
    &::after {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        content: '';
        background-image: $bg-tile-gradient;
        z-index: 1;
    }
}
