.c-investment-performance-summary {
    .investment-toggle {
        align-self: flex-end;
        margin-left: auto;
    }

    .investment-wrapper {
        margin-top: $base-spacing-5;
        position: relative;
    }

    .investment-title {
        margin-bottom: $base-spacing-3;
    }
}

@media (min-width: $screen-sm) {
    .c-investment-performance-summary {
        .investment-header,
        .investment-toggle {
            display: flex;
        }

        .investment-toggle {
            .radio-item {
                margin-left: $base-spacing-2;
            }
        }
    }
}
