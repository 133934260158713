$step-nav-icon-size: $base-spacing-4;
$step-nav-button-margin: $base-spacing-3;

.form-step-count {
    display: none;
}

@media (min-width: $screen-sm) {
    .form-step-count {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row !important;
        background-color: transparent;
        max-width: 100%;
        margin-bottom: $base-spacing-8;

        .step-left,
        .step-right {
            flex-shrink: 0;
        }

        .step-left {
            padding: 0 $step-nav-button-margin 0 0;
        }

        .step-right {
            padding: 0 0 0 $step-nav-button-margin;
        }

        :not(.btn-disabled) {
            &.step-left,
            &.step-right {
                &:hover,
                &:focus {
                    .step-nav-icon {
                        color: $color-primary-2;
                    }
                }
            }
        }

        .step-nav-icon {
            pointer-events: none;
            width: $step-nav-icon-size;
            height: $step-nav-icon-size;
        }

        .steps-list {
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            overflow-x: hidden;
            // scroll-behavior: smooth;
            align-items: stretch;
            padding: 0;
            margin: 0;

            li {
                margin: 0 $base-spacing-1 0 0;
            }

            li::before {
                display: none;
            }

            a {
                text-decoration: none;
                height: 100%;
            }
        }

        .step-item {
            flex: 0 0 auto;
            background-color: $color-white;
            opacity: 0.4;
            width: calc(24 * #{$base-spacing-1});
            padding: $base-spacing-1;
            border: 1px solid $color-shade-2;
            border-radius: 4px;

            .step-name,
            .step-indicator,
            .step-title {
                margin: 0;
            }

            .step-name {
                grid-row: 1/2;
                grid-column: 1/2;
                -ms-grid-row: 1;
                -ms-grid-row-span: 1;
                -ms-grid-column: 1;
                -ms-grid-column-span: 1;
                text-transform: unset;
            }

            .step-indicator {
                grid-row: 1/2;
                grid-column: 2/3;
                -ms-grid-row: 1;
                -ms-grid-row-span: 1;
                -ms-grid-column: 2;
                -ms-grid-column-span: 1;
                align-self: center;
                justify-content: center;
                display: flex;
                align-items: center;
                height: $base-spacing-2;
                width: $base-spacing-2;
                border-radius: 50%;
                background-color: $color-primary-2;

                svg {
                    height: 12px;
                    width: 12px;
                    color: $color-white;
                }
            }

            .step-title {
                grid-row: 2/3;
                grid-column: 1/3;
                -ms-grid-row: 2;
                -ms-grid-row-span: 1;
                -ms-grid-column: 1;
                -ms-grid-column-span: 2;
            }
        }

        .step-item,
        .step-completed a {
            display: grid;
            grid-template-rows: auto 1fr;
            grid-template-columns: 1fr auto;
            -ms-grid-rows: auto 1fr;
            -ms-grid-columns: 1fr auto;
        }

        .step-completed,
        .step-current {
            opacity: 1;
        }

        .step-current {
            background-color: $color-primary-2;
            border-color: $color-primary-2;

            .step-name,
            .step-title {
                color: $color-white;
            }
        }
    }
}
