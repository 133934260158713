$investment-group:          $color-primary-3;
$investment-group-padding:  6px;
$investment-icon-size:      $base-spacing-2;
$investment-opt-icon:       'arrow-right';
$investment-opt-padding:    12px $base-spacing-2 12px $base-spacing-2;
$investment-cell-height:    50px;

.c-investment-summary-table {
    width: 100%;

    table {
        border-collapse: separate;

        a {
            text-decoration: none;
        }

        th,
        td {
            text-align: left;

            &:first-child {
                background: none;
            }
        }

        th {
            line-height: 1.2;
        }

        tr {
            border-bottom: 1px solid $color-white;

            &:first-child,
            &:first-child:hover {
                background-color: transparent;
            }

            td {
                min-width: auto;
                width: 200px;
                vertical-align: top;
            }

            .investment-group {
                background-color: $investment-group;
                border-bottom: 1px solid $color-white;
                border-right: 2px solid $color-white;
                color: $color-white;
                padding: $investment-group-padding;
                height: 150px;
                min-width: 35px;
                width: 40px;

                // IE fix
                div {
                    height: 100%;
                    position: relative;
                }

                span {
                    display: block;
                    left: 0;
                    position: absolute;
                    text-align: center;
                    top: 50%;
                    transform-origin: top left;
                    transform: rotate(-90deg) translate(-50%);
                    width: 140px;
                    white-space: nowrap;
                }
            }

            .investment-options {
                padding: 0;
                white-space: nowrap;

                a {
                    background-color: $color-primary-shade-1;
                    border-radius: 3px;
                    color: $color-primary-1;
                    display: block;
                    padding: $investment-opt-padding;
                    position: relative;
                    text-decoration: underline;

                    &:hover {
                        opacity: 0.9;

                        &::after {
                            right: $base-spacing-1;
                        }
                    }
                }
            }

            .investment-col {
                border-bottom: 1px solid $table-heading-bg;
                height: $investment-cell-height;
                padding: $base-spacing-1 12px;
                text-align: right;
            }
        }
    }
}

@media (max-width: $screen-xs-max) {
    .c-investment-summary-table {
        &::after {
            content: "";
            height: 100%;
            position: absolute;
            width: 50px;
            background: linear-gradient(90deg, transparent 0%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0.1) 100%);
            top: 0;
            right: 0;
        }

        .table-wrapper::after {
            content: none;
        }

        table {
            tr {
                .investment-options {
                    max-width: 150px;
                    white-space: break-spaces;
                }
            }
        }
    }
}

