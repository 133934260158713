.form-step-header {
    @include base-mobile-form-layout;

    .step-name {
        @include display-heading-large;
        margin-bottom: $base-spacing-2;
    }

    .step-title {
        @include display-heading-xx-large;
        margin: 0;
    }
}

@media (min-width: $screen-sm) {
    .form-step-header {
        @include base-desktop-form-layout;
        margin-bottom: $base-spacing-6;

        .step-total {
            display: none;
        }
    }
}
