.mobile-nav ul#c-secondary-links {
    a {
        display: flex;
        align-items: center;
        width: 100%;
        padding: $base-spacing-2 $base-spacing-3;

        &:hover,
        &:focus {
            background-color: $primary-link-background;
        }
    }
}
