$filters-section-padding:           $base-spacing-3 0 $base-spacing-2;
$filters-item-margin:               $base-spacing-2;
$filters-section-bg-color:          $color-white;

.filters-section {
    padding: $filters-section-padding;
    background-color: $filters-section-bg-color;
}

.filters {
    .filter-item {
        margin-bottom: $filters-item-margin;
    }
}
