.form-text {
    @include vertical-rhythm;

    &:not(.bg-theme-3) {
        *:last-child {
            margin-bottom: 0;
        }
    }

    &[class*='bg-theme-'] {
        padding: $base-spacing-3 $base-spacing-2 0;
    }

    @include base-mobile-form-layout;
}

@media (min-width: $screen-sm) {
    .form-text {
        @include base-desktop-form-layout;
    }
}

[class*='bg-theme-'] {
    .form-text {
        margin-top: 0 !important;
        padding: 0 $base-spacing-2 $base-spacing-3;
    }
}
