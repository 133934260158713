// HEADING STYLES
//----------------------------------------
$base-heading-font:         $Avenir-Black;
$base-heading-font-2:       $Avenir-Roman;
$base-heading-font-3:       $Avenir-Heavy;
$base-heading-font-4:       $NGSNoteworthy;

$base-heading-colour-1:     $color-primary-1;
$base-heading-colour-2:     $color-primary-2;
$base-heading-colour-3:     $color-black;
$base-heading-colour-4:     $color-shade-3;

$display-x-large: (
    null: (41px, 48px),
    sm: (80px, 96px)
);

// Display Large
$display-large: (
    null: (41px, 48px),
    sm: (64px, 72px)
);

// Display Medium
$display-medium: (
    null: (32px, 40px),
    sm: (44px, 48px)
);

$display-small: (
    null: (29px, 40px),
    sm: (37px, 48px)
);

$display-xx-small: (
    null: (28px, 32px)
);

$display-extra-small: (
    null: (23px, 32px),
    sm: (25px, 40px)
);

// Heading XXLarge
$display-heading-xx-large-type: (
    null: (32px, 32px),
    sm: (37px, 40px)
);

// Heading XLarge
$display-heading-x-large-type: (
    null: (26px, 32px),
    sm: (31px, 40px)
);

// Heading Large
$display-heading-large-type: (
    null: (23px, 32px),
    sm: (26px, 32px)
);

// Heading Medium
$display-heading-medium-type: (
    null: (20px, 24px),
    sm: (22px, 32px)
);

// Heading Small
$display-heading-small-type: (
    null: (18px, 24px)
);

// Heading XSmall
$display-heading-extra-small-type: (
    null: (15px, 24px)
);

@mixin display-x-large {
    @include font-size($display-x-large);
    font-family: $base-heading-font;
    color: $base-heading-colour-1;
    letter-spacing: -1px;
    text-transform: uppercase;
}

.display-x-large {
    @include display-x-large;
}

@mixin display-large {
    @include font-size($display-large);
    font-family: $base-heading-font;
    color: $base-heading-colour-1;
    letter-spacing: -1px;
    text-transform: uppercase;
}

.display-large {
    @include display-large;
}

@mixin display-banner-large {
    @include font-size($display-large);
    font-family: $base-heading-font;
    color: $base-heading-colour-1;
    letter-spacing: -1px;
}

.display-banner-large {
    @include display-banner-large;
}

@mixin display-medium {
    @include font-size($display-medium);
    font-family: $base-heading-font;
    color: $base-heading-colour-1;
    letter-spacing: -1px;
    text-transform: uppercase;
}

.display-medium {
    @include display-medium;
}

@mixin display-small {
    @include font-size($display-small);
    font-family: $base-heading-font-4;
    font-weight: normal;
    color: $base-heading-colour-4;
    letter-spacing: -1px;
}

.display-small {
    @include display-small;
}

@mixin display-xx-small {
    @include font-size($display-xx-small);
    font-family: $base-heading-font-3;
    font-weight: normal;
}

.display-small {
    @include display-small;
}

@mixin display-extra-small {
    @include font-size($display-extra-small);
    font-family: $base-heading-font-2;
    font-weight: normal;
    color: $base-heading-colour-2;
}

.display-extra-small {
    @include display-extra-small;
}

@mixin display-heading-xx-large {
    @include font-size($display-heading-xx-large-type);
    font-family: $base-heading-font;
    color: $base-heading-colour-1;
}

.display-heading-xx-large,
h1 {
    @include display-heading-xx-large;
}

@mixin display-heading-x-large {
    @include font-size($display-heading-x-large-type);
    font-family: $base-heading-font;
    color: $base-heading-colour-1;
}

.display-heading-x-large,
h2 {
    @include display-heading-x-large;
}

@mixin display-heading-large {
    @include font-size($display-heading-large-type);
    font-family: $base-heading-font;
    color: $base-heading-colour-1;
}

.display-heading-large,
h3 {
    @include display-heading-large;
}

@mixin display-heading-medium {
    @include font-size($display-heading-medium-type);
    font-family: $base-heading-font;
    color: $base-heading-colour-1;
}

.display-heading-medium,
h4 {
    @include display-heading-medium;
}

@mixin display-heading-small {
    @include font-size($display-heading-small-type);
    font-family: $base-heading-font-3;
    color: $base-heading-colour-3;
}

.display-heading-small,
h5 {
    @include display-heading-small;
}

@mixin display-heading-extra-small {
    @include font-size($display-heading-extra-small-type);
    font-family: $base-heading-font-3;
    color: $base-heading-colour-3;
}

.display-heading-extra-small,
h6 {
    @include display-heading-extra-small;
}

@mixin sub-nav-item-heading {
    @include display-heading-small;
}
