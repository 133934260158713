$tooltip-info-icon-size: $base-spacing-2;
$tooltip-info-arrow-half-size: $base-spacing-1;
$tooltip-info-margin: $base-spacing-1;
$tooltip-info-babble-max-width: 220px;

$tooltip-info-base-bg: $color-white;
$tooltip-info-color: $color-white;
$tooltip-info-color-bg: $color-black;
$tooltip-info-icon-color-bg: $color-black;

.tooltip-info {
    position: relative;
    overflow: hidden;
    margin-left: $tooltip-info-margin;

    &::after {
        content: "";
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        z-index: 2;
    }

    .svg-icon {
        width: $tooltip-info-icon-size;
        height: $tooltip-info-icon-size;
        bottom: -2px;
        color: $tooltip-info-icon-color-bg;
        position: relative;
        z-index: 1;
    }

    &.color-primary {
        .svg-icon {
            color: $color-primary-1;
        }
    }
}

// Tooltip bubble styles
.tooltip-info-bubble {
    color: $tooltip-info-color;
    position: absolute;
    z-index: 2000;
    padding: $base-spacing-2;
    max-width: $tooltip-info-babble-max-width;
    background: $tooltip-info-base-bg;
    border-radius: $base-spacing-1 / 2;

    p {
        @include body-small;
        color: $tooltip-info-color;
        margin-bottom: 0;
    }

    .arrow::before,
    .arrow::after,
    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.8;
        z-index: 1;
    }

    &::before {
        background: $tooltip-info-color-bg;
        border-radius: $base-spacing-1 / 2;
    }

    .arrow {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 0;
        height: 0;
        z-index: 1;
        background: $tooltip-info-base-bg;
    }

    .arrow::before {
        opacity: 1;
    }

    .arrow::before,
    .arrow::after {
        width: 0;
        height: 0;
        border: solid $tooltip-info-arrow-half-size transparent;
    }

    &.top .arrow {
        top: auto;
        left: 50%;
    }

    &.bottom .arrow {
        top: $tooltip-info-arrow-half-size * -2;
        left: 50%;
    }

    &.left .arrow {
        top: 50%;
        left: auto;
    }

    &.right .arrow {
        top: 50%;
        left: $tooltip-info-arrow-half-size * -2;
    }

    &.top .arrow::before {
        margin-left: $tooltip-info-arrow-half-size * -1;
        border-top-color: $tooltip-info-base-bg;
    }

    &.bottom .arrow::before {
        margin-left: $tooltip-info-arrow-half-size * -1;
        border-bottom-color: $tooltip-info-base-bg;
    }

    &.left .arrow::before {
        margin-top: $tooltip-info-arrow-half-size * -1;
        border-left-color: $tooltip-info-base-bg;
    }

    &.right .arrow::before {
        margin-top: $tooltip-info-arrow-half-size * -1;
        border-right-color: $tooltip-info-base-bg;
    }

    &.top .arrow::after {
        margin-left: $tooltip-info-arrow-half-size * -1;
        border-top-color: $tooltip-info-color-bg;
    }

    &.bottom .arrow::after {
        margin-left: $tooltip-info-arrow-half-size * -1;
        border-bottom-color: $tooltip-info-color-bg;
    }

    &.left .arrow::after {
        margin-top: $tooltip-info-arrow-half-size * -1;
        border-left-color: $tooltip-info-color-bg;
    }

    &.right .arrow::after {
        margin-top: $tooltip-info-arrow-half-size * -1;
        border-right-color: $tooltip-info-color-bg;
    }

    div {
        position: relative;
        z-index: 2;
    }
}
