$step-height-mobile:     312px;
$step-circle-height:    $base-spacing-7;

section.s-three-col-steps {
    .container {
        h2 {
            text-align: center;
            margin-bottom: $base-spacing-3;
        }

        .subtext {
            margin: 0 auto $base-spacing-3;
            text-align: center;
        }

        .desktop-row {
            .step-content {
                background-color: $color-white;
                display: flex;
                flex-direction: column;
                min-height: $step-height-mobile;
                border: 1px solid $color-shade-2;
                margin-bottom: $base-spacing-3;
                padding: $base-spacing-5;
                @include body-normal;

                strong,
                em,
                u,
                a,
                span {
                    display: contents;
                }

                .circle {
                    @include display-heading-medium();
                    border-radius: 50%;
                    background-color: $color-primary-shade-1;
                    width: $step-circle-height;
                    height: $step-circle-height;
                    line-height: $step-circle-height;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: $base-spacing-4;
                }

                h3 {
                    @include display-heading-medium();
                    margin-bottom: $base-spacing-3;
                }
            }
        }

        .btn-container {
            text-align: center;
        }
    }
}

@media (min-width: $screen-md) {
    section.s-three-col-steps {
        .container {
            h2 {
                margin-bottom: $base-spacing-4;
            }

            .subtext {
                @include col(7);
                margin-right: auto;
                margin-bottom: $base-spacing-6;
            }

            .desktop-row {
                @include row;

                > div {
                    @include col(4);

                    &.step-content {
                        // padding: $base-spacing-8;
                        margin-bottom: $base-spacing-8;
                    }
                }
            }
        }
    }
}
