.s-two-col-section {
    form {
        padding-bottom: $base-spacing-6;
        border-bottom: 1px solid $color-primary-shade-2;
    }

    h2 {
        margin-bottom: $base-spacing-3;
    }

    .c-accordion {
        margin-top: $base-spacing-2;
    }
}

@media (min-width: $screen-sm) {
    .s-two-col-section {
        .desktop-row {
            display: flex;
        }

        .two-col30 {
            @include col(4);
        }

        .two-col70 {
            @include col(8);
        }
    }
}
