$article-pagination-bg:                    $color-shade-1;
$article-pagination-height:                96px;
$article-pagination-height-desktop:        160px;

$article-pagination-icon-offset:           8px;

$article-pagination-nav-item-hover-bg:     $color-black;
$article-pagination-text-colour:           $color-black;
$article-pagination-text-hover-colour:     $color-white;
$article-pagination-icon-margin:           $base-spacing-3;

.s-article-pagination {
    padding: 0;
    background-color: $article-pagination-bg;

    .nav-container {
        display: flex;
        align-items: center;
        height: $article-pagination-height;
    }

    .nav-item {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        transition: 0s;

        &::before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            content: '';
            opacity: 0;
            z-index: 1;
            background-color: $article-pagination-nav-item-hover-bg;
            transition: opacity $base-transition-fast $base-transition-curve;
        }

        &.prev::before {
            left: -1000px;
            right: 0;
        }

        &.next::before {
            left: 0;
            right: -1000px;
        }

        h4,
        p,
        .svg-icon {
            margin: 0;
            position: relative;
            z-index: 2;
        }

        h4 {
            color: inherit;
        }

        p {
            color: $article-pagination-text-colour;
        }

        &:hover,
        &:focus {
            text-decoration: none;

            &::before {
                opacity: 1;
            }

            h4,
            p,
            .svg-icon {
                color: $article-pagination-text-hover-colour;
            }
        }
    }

    .nav-item.prev {
        display: none;

        .svg-icon {
            margin-right: $article-pagination-icon-margin;
        }
    }

    .nav-item.next {
        text-align: right;

        .svg-icon {
            margin-left: $article-pagination-icon-margin;
        }
    }
}

@media (max-width: $screen-xs-max) {
    .s-article-pagination {
        .nav-item {
            justify-content: flex-end;

            &.next {
                &::before {
                    left: -100px;
                }
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .s-article-pagination {
        .nav-container {
            height: $article-pagination-height-desktop;
        }

        .nav-item {
            width: 50%;
            justify-content: space-between;

            .svg-icon {
                transition: transform $base-transition-medium ease;
            }
        }

        .nav-item.prev {
            display: flex;
            justify-content: flex-start;

            &:hover,
            &:focus {
                .svg-icon {
                    transform: translateX(-#{$article-pagination-icon-offset});
                }
            }
        }

        .nav-item.next {
            justify-content: flex-end;

            &:hover,
            &:focus {
                .svg-icon {
                    transform: translateX($article-pagination-icon-offset);
                }
            }
        }
    }
}
