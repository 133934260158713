.has-error {
    input,
    textarea,
    select,
    input[type="checkbox"]:focus + label.rich-checkbox-radio,
    input[type="radio"]:focus + label.rich-checkbox-radio,
    input[type="checkbox"]:focus + label::before,
    input[type="radio"]:focus + label::before,
    input[type="checkbox"] + label.rich-checkbox-radio,
    input[type="radio"] + label.rich-checkbox-radio,
    input[type="checkbox"] + label::before,
    input[type="radio"] + label::before,
    input[type="hidden"] + label,
    input[type="hidden"] + label::before {
        border-color: $color-error !important;
        box-shadow: 0 5px 11px rgba($color-error, 0.2);
    }

    .input-wrapper {
        position: relative;

        .error-msg {
            display: block;
        }
    }
}

.error-msg {
    position: absolute;
    top: 100%;
    @include body-small;
    color: $color-error;
    margin-top: $base-spacing-1;
    display: none;
    left: 0;
}

span.error-msg {
    display: block;
    position: relative;
}

.datepicker-wrapper.has-error .input-wrapper {
    @include bg-icon('calendar', after, $color-error);
}

.file-uploader-wrapper.has-error .btn {
    background-color: $color-error;
}

// Form Element error styling
.EditingFormValueCell .Error {
    input,
    textarea,
    select,
    input + label::before {
        border-color: $color-error;
    }

    input:focus,
    textarea:focus,
    select:focus,
    input:focus + label::before {
        border-color: $input-border-color-focus;
    }

    // Calendar Icon styling
    .date-time-picker .control-group-inline button {
        @include bg-icon('calendar', after, $color-error);
    }

    .uploader-upload label {
        background-color: $color-error;
    }

    .uploader-upload label:focus {
        &.btn-skin-1 {
            background-color: $btn-skin-1-hover-bg;
        }

        &.btn-skin-2 {
            background-color: $btn-skin-2-hover-bg;
        }
    }
}

// Select Icon styling
.select-wrapper.has-error .input-wrapper,
.select-wrapper .EditingFormValueCell > div.Error {
    @include bg-icon($select-icon, after, $color-error) {
        position: absolute;
        top: $base-spacing-2;
        right: $base-spacing-2;
        width: $select-icon-width;
        height: $select-icon-width;
        content: "";
        text-align: center;
    }
}

// Form Element error message
.EditingFormErrorLabel {
    @include body-small;
    color: $color-error;
}

// Firefox overrides
:not(output):-moz-ui-invalid {
    box-shadow: none;
}
