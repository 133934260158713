$pagination-height:            $base-spacing-6;
$pagination-bg:                $color-primary-1;
$pagination-active-color:      $color-primary-2;

nav[class*='c-pagination-'] {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &.hide,
    &.hidden,
    .hide {
        display: none !important;
    }

    .pre-text {
        margin: 0 $base-spacing-2 0 0;
    }

    ul {
        @include list-reset;
        display: flex;
        justify-content: flex-start;
        height: 100%;

        li {
            display: block;
            min-width: $base-spacing-5;
            min-height: $base-spacing-5;

            > a,
            > button,
            > span {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
            }

            > a,
            > button {
                &:hover,
                &:focus {
                    border: none;
                }
            }

            &.selected > a,
            &.selected > button {
                cursor: default;
                border: solid 1px $pagination-active-color;
            }
        }
    }
}

@media (max-width: $screen-xs-max) {
    nav[class*='c-pagination-'] {
        .pre-text {
            order: 1000;
            width: 100%;
            margin-top: $base-spacing-2;
        }
    }
}
