.file-uploader-wrapper {
    .uploader-upload {
        display: inline-block;

        label.btn {
            margin-right: $base-spacing-1;
            cursor: pointer;
            width: auto;
            margin-bottom: 0;
        }

        .btn.btn-disabled {
            cursor: default;
        }

        // hide the actual input.
        input {
            display: none;
        }

        span:last-of-type {
            @include body-small;
        }
    }
}
