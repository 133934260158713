section.s-news-grid {
    padding: 0;
}

@media (min-width: $screen-sm) {
    .s-news-grid {
        .grid-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            -ms-grid-columns: 1fr 1fr;
        }
    }
}

@media (min-width: $screen-md) {
    .s-news-grid {
        .grid-wrapper {
            grid-template-columns: 1fr 1fr 1fr;
            -ms-grid-columns: 1fr 1fr 1fr;

            a:first-of-type {
                grid-column: 1 / 2;
                -ms-grid-row: 1;
                -ms-grid-row-span: 1;
                -ms-grid-column: 1;
                -ms-grid-column-span: 1;
            }

            a:nth-of-type(2) {
                grid-column: 2 / -1;
                -ms-grid-row: 1;
                -ms-grid-row-span: 1;
                -ms-grid-column: 2;
                -ms-grid-column-span: 2;
            }

            a:nth-of-type(3) {
                grid-row: 2;
                grid-column: 1 / span 2;
                -ms-grid-row: 2;
                -ms-grid-row-span: 1;
                -ms-grid-column: 1;
                -ms-grid-column-span: 2;
            }

            a:nth-of-type(4) {
                grid-row: 2;
                grid-column: 3 / -1;
                -ms-grid-row: 2;
                -ms-grid-row-span: 1;
                -ms-grid-column: 3;
                -ms-grid-column-span: 1;
            }
        }
    }
}
