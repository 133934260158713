$map-pin-point-size: $base-spacing-3;
$map-wrapper-lg: 720px;
$map-wrapper-sm: 500px;
$map-wrapper-xs: 665px;

section.s-map {
    padding-top: 0;
    padding-bottom: 0;

    .map-wrapper {
        height: $map-wrapper-xs;
        position: relative;
        overflow: hidden;

        &::before,
        &::after {
            background: linear-gradient($color-shade-2 1%, transparent);
            content: '';
            height: $base-spacing-2;
            position: absolute;
            top: -10px;
            width: 100%;
            z-index: 1;
        }

        &::after {
            background: linear-gradient(0deg, $color-shade-2 1%, transparent);
            bottom: -10px;
            top: auto;
        }
    }

    .map-pin-container {
        position: relative;
    }

    .location-map-pin {
        padding: 0;
        text-decoration: none;
        background: 0 0;
        border: none;
        cursor: pointer;
        position: absolute;
        transform: translate(-50%, -100%);
        top: 0;
        width: $map-pin-point-size;
        z-index: 1;

        svg {
            color: $color-primary-1;
        }
    }

    .info-close-button {
        .svg-icon {
            color: $color-black;
        }
    }

    .map-content {
        padding: $base-spacing-4 0;
    }

    .map-tabs {
        .tabs-heading {
            margin-bottom: 0;

            .tab {
                text-align: center;
                width: 100%;

                &:last-child {
                    margin: 0;
                }
            }
        }

        .tabs-content {
            padding-top: $base-spacing-2;

            .tab-content-item {
                border-bottom: 1px solid $color-shade-1;
                display: block;
                padding-bottom: $base-spacing-3;
                padding-top: $base-spacing-3;
            }

            .tab-image {
                margin-bottom: $base-spacing-4;
                height: 54px;
                min-width: 54px;
                width: 54px;
            }

            img {
                width: 100%;
            }

            > div {
                display: block;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                margin-bottom: $base-spacing-3;
            }
        }
    }
}

// Google Maps Info Window override 
.gm-style-iw {
    background-color: $color-shade-1;
    max-width: 300px !important;
    width: 300px !important;

    .info-window {
        img {
            max-width: $base-spacing-6;
        }
    }

    .info-title {
        margin-top: $base-spacing-3;
    }

    .info-content {
        margin-top: $base-spacing-5;

        p {
            margin-bottom: 0;
            @include body-normal-light;
        }
    }
}

.gm-style {
    .gm-style-iw {
        button {
            height: $base-spacing-5 !important;
            opacity: 1;
            width: $base-spacing-5 !important;
            right: 12px !important;
            top: 12px !important;

            span {
                background-color: $color-primary-1 !important;
                height: $base-spacing-5 !important;
                margin: 0 !important;
                width: $base-spacing-5 !important;
            }
        }
    }

    .gm-style-iw-d {
        max-height: none !important;
    }

    .gm-style-iw-c {
        background-color: $color-primary-shade-1;
        border-radius: 0;
        border-top-left-radius: 50px;
        padding-top: $base-spacing-4;
        padding-left: $base-spacing-4;
        max-height: none !important;
    }

    .gm-style-iw-tc::after {
        background-color: $color-primary-shade-1;
    }

    .gm-style-iw-d {
        overflow: unset !important;
        padding-right: $base-spacing-4;
        padding-bottom: $base-spacing-4;
    }
}

@media (min-width: $screen-xs-max) {
    section.s-map {
        .map-content {
            padding: $base-spacing-8 0;
        }

        .map-wrapper {
            height: $map-wrapper-sm;
        }

        .map-tabs {
            .tabs-content {
                padding-top: $base-spacing-4;

                .tab-content-item {
                    display: flex;
                    padding: $base-spacing-4;
                }

                .tab-image {
                    margin-bottom: 0;
                    margin-right: $base-spacing-6;
                    height: 76px;
                    width: 76px;
                    min-width: 76px;
                }
            }
        }
    }

    .gm-style-iw {
        max-width: 400px !important;
        width: 400px !important;
    }
}

@media (min-width: $screen-sm-max) {
    section.s-map {
        .map-content {
            padding: $base-spacing-10 0;
        }

        .map-wrapper {
            height: $map-wrapper-lg;
        }

        .map-tabs {
            .tabs-content {
                padding-top: $base-spacing-6;

                .tab-content-item {
                    display: flex;
                    padding: $base-spacing-6 $base-spacing-5;
                }

                .tab-image {
                    margin-right: $base-spacing-10;
                    height: 100px;
                    width: 100px;
                    min-width: 100px;
                }
            }
        }
    }
}
