.s-one-col-center {
    .container {
        form {
            @include stack;

            .form-text {
                margin-bottom: 0;
                margin-right: 0;
                margin-top: $base-spacing-4;
                width: 100%;
            }
        }
    }

    &.form-member-join,
    &.form-custom {
        margin-bottom: 0;
        position: relative;
        width: 100%;
    }
}

@media (min-width: $screen-sm) {
    .s-one-col-center {
        .container {
            form {
                @include col(10);
                margin: 0 auto;
            }
        }
    }
}

@media (min-width: $screen-sm-max) {
    .s-one-col-center {
        .container {
            form {
                @include col(8);
                margin: 0 auto;
            }
        }
    }
}

