section.s-landing-intro {
    @include bg-theme-3;
    padding-top: $base-spacing-7;
    padding-bottom: $base-spacing-7;

    .intro-content {
        @include vertical-rhythm;

        h5 {
            @include display-extra-small;
            color: $color-black;
        }
    }

    a[class*='btn-'] {
        color: $color-primary-1;
        border-color: $color-primary-1;
        border-width: 2px;
        border-radius: 4px;
    }
}

@media (min-width: $screen-sm) {
    section.s-landing-intro {
        padding-top: $base-spacing-9;
        padding-bottom: $base-spacing-9;

        .intro-content {
            @include col(10);
            @include col-offset(1);
        }
    }
}

@media (min-width: $screen-md) {
    section.s-landing-intro {
        .intro-content {
            @include col(8);
            @include col-offset(2);
        }
    }
}
