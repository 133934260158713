// Values based on https://www.gridlover.net/try
@mixin vertical-rhythm {
    h1 {
        margin-bottom: $base-spacing-6;
    }

    h2 {
        margin-bottom: $base-spacing-2;
    }

    h3 {
        margin-bottom: $base-spacing-2;
    }

    h4 {
        margin-bottom: $base-spacing-2;
    }

    h5 {
        margin-bottom: $base-spacing-2;
    }

    p,
    ul,
    ol,
    pre,
    table,
    blockquote {
        margin-top: 0;
        margin-bottom: $base-spacing-2;
    }

    ul ul,
    ol ol,
    ul ol,
    ol ul {
        margin-top: $list-item-margin;
        margin-bottom: 0;
    }

    > *[class*='btn-skin-'] {
        margin-bottom: $base-spacing-2;
    }
}

.vertical-rhythm {
    @include vertical-rhythm;
}
