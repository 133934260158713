// Spacing Units
// =======================================================================
$base-spacing-unit:     8px;
$base-spacing-1:        1 * $base-spacing-unit; //8px
$base-spacing-2:        2 * $base-spacing-unit; //16px
$base-spacing-3:        3 * $base-spacing-unit; //24px
$base-spacing-4:        4 * $base-spacing-unit; //32px
$base-spacing-5:        5 * $base-spacing-unit; //40px
$base-spacing-6:        6 * $base-spacing-unit; //48px
$base-spacing-7:        7 * $base-spacing-unit; //56px
$base-spacing-8:        8 * $base-spacing-unit; //64px
$base-spacing-9:        9 * $base-spacing-unit; //72px
$base-spacing-10:       10 * $base-spacing-unit; //80px
$base-spacing-11:       11 * $base-spacing-unit; //88px
$base-spacing-12:       12 * $base-spacing-unit; //96px
$base-spacing-13:       13 * $base-spacing-unit; //104px
$base-spacing-14:       14 * $base-spacing-unit; //112px
$base-spacing-15:       15 * $base-spacing-unit; //120px
$base-spacing-16:       16 * $base-spacing-unit; //128px
