// Layout specific overrides - filters side
.layout-filters-side {
    @include container();

    .layout-filters-side-row {
        .listing-active-filters {
            padding-left: $base-spacing-2;
            padding-right: $base-spacing-2;

            &.btn-tags {
                .active-filters-label,
                .active-filter-item {
                    margin-right: 0;
                    margin-bottom: $base-spacing-2;
                }
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .layout-filters-side {
        .layout-filters-side-row {
            @include row();

            .filters-wrapper {
                @include col(3);
            }

            .listing-wrapper {
                @include col(9);
            }
        }
    }
}

@media (min-width: $screen-md) {
    .layout-filters-side {
        .layout-filters-side-row {
            .filters-wrapper {
                @include col(2);
            }

            .listing-wrapper {
                @include col(10);
            }
        }
    }
}
