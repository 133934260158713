.basic-cards__row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: $base-spacing-3;
}

@media (min-width: $screen-sm) {
    .basic-cards__row {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: $screen-lg) {
    .basic-cards__row {
        grid-template-columns: repeat(6, 1fr);
    }
}
