$label-margin-bottom:              $base-spacing-1;

label,
.FieldLabel span { //Kentico uses both tags for labels inconsistently
    margin-bottom: $label-margin-bottom;
    display: block;
    color: $color-shade-3;
}

label sup {
    color: $color-error;
}

.explanation-text {
    @include body-small;
    color: $color-shade-3;
    margin-top: $base-spacing-1;
}

.form-field label {
    margin-top: $base-spacing-4;

    &.error-msg {
        margin-top: $base-spacing-1;
    }
}

.form-member-join,
.form-custom {
    .control-label {
        display: flex;
        position: relative;

        span {
            @include body-small;
            align-self: center;
            background: $color-primary-shade-1;
            border-radius: 50%;
            color: $color-primary-2;
            display: inline-block;
            font-weight: 600;
            margin-left: auto;
            padding: $base-spacing-1 / 2 $base-spacing-1;
            text-align: center;
            width: $base-spacing-3;
        }
    }

    .filled {
        color: $color-primary-2;

        p {
            color: $color-shade-3;
        }
    }

    .explanation-text {
        &.show {
            right: -$base-spacing-1;
        }
    }

    .form-field {
        width: 100%;

        &.has-error {
            + .form-field .form-text {
                margin-top: $base-spacing-1;
            }
        }
    }
}

.form-field {
    p {
        label {
            @include body-small;
            margin: 0;
            padding-right: $base-spacing-1 / 2;
            position: relative;
            cursor: pointer;
            display: inline-block;
            vertical-align: top;
        }

        .explanation-text {
            color: $color-white;
            min-width: 250px;
            right: -$base-spacing-2;
            bottom: $base-spacing-3;

            &.show {
                right: -$base-spacing-2;

                &.right {
                    left: -$base-spacing-2;
                    right: 0;

                    &::after {
                        left: 12px;
                        right: 0;
                    }
                }
            }
        }
    }
}

.explanation-text {
    &.tooltip {
        background: $color-black;
        border: 1px solid $color-black;
        border-radius: 5px;
        color: $color-white;
        max-width: 250px;
        line-height: 1.5;
        padding: $base-spacing-1 $base-spacing-2;
        position: absolute;
        right: -8px;
        bottom: $base-spacing-12;
        transition: all 50ms ease-in;
        opacity: 0;
        visibility: hidden;
        z-index: 2;

        &.show {
            opacity: 1;
            visibility: visible;

            &::after {
                content: '';
                position: absolute;
                bottom: -7px;
                right: 12px;
                width: 0;
                height: 0;
                border-top: $base-spacing-1 solid $color-black;
                border-right: $base-spacing-1 solid transparent;
                border-left: $base-spacing-1 solid transparent;
            }
        }
    }
}

