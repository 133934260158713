$modal-content-max-width:     1144px;
$modal-header-color:          $color-white;
$modal-header-background:     $color-black;
$modal-content-background:    $color-white;
$modal-close-color:           $color-primary-4;
$modal-close-hover-color:     $color-primary-2;
$modal-btn-control-spacing:   20px;

// Vendor css for the magnific modal lib
@import "vendor/magnific-popup";

.mfp-fade-modal {
    // overlay animation on the bg
    &.mfp-bg {
        opacity: 0;
        transition: opacity $base-transition-slow $base-transition-curve;

        &.mfp-ready {
            opacity: 0.75;
        }

        &.mfp-removing {
            opacity: 0;
        }
    }

    // overlay animation on the wrapper
    &.mfp-wrap {
        // Fixes for the iphone scrolling issue
        -webkit-overflow-scrolling: touch;

        .mfp-content {
            opacity: 0;
            transition: opacity $base-transition-slow $base-transition-curve;
        }

        &.mfp-ready .mfp-content {
            opacity: 1;
        }

        &.mfp-removing .mfp-content {
            opacity: 0;
        }
    }

    // Popup content style
    &.mfp-wrap .mfp-content {
        margin: $base-spacing-4 auto;

        .c-modal {
            max-width: $modal-content-max-width;
            max-height: calc(100vh - 64px);
            overflow: auto;

            .modal-wrapper {
                width: 100%;
                position: relative;
                display: flex;
                flex-wrap: wrap;
                border-radius: 28px;
                overflow: hidden;
            }

            .modal-header {
                padding: 0;
                height: $base-spacing-6;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                background-color: $color-white;
            }

            .modal-title {
                @include body-large;
                font-family: $Avenir-Black;
                margin-bottom: $base-spacing-2;
                color: $color-primary-1;
            }

            .modal-content {
                background: $modal-content-background;
                padding: $base-spacing-2 $base-spacing-3 $base-spacing-8;
                width: 100%;
                max-height: calc(100vh - 120px);
                overflow: auto;
            }

            .mfp-close {
                display: flex;
                flex-direction: row;
                align-items: center;
                color: $modal-close-color;
                cursor: pointer;
                font-size: 18px;
                opacity: 1;
                top: $base-spacing-2;
                right: $base-spacing-2;

                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }

                .svg-icon {
                    order: 2;
                    color: $modal-close-color;
                    opacity: 1;
                    position: relative;
                    padding: 0;
                    min-width: $base-spacing-3;
                    width: $base-spacing-4;
                    height: $base-spacing-4;
                    display: flex;
                    overflow: hidden;
                    cursor: pointer;
                }


                &:hover,
                &:focus {
                    color: $modal-close-hover-color;

                    .svg-icon {
                        color: $modal-close-hover-color;
                    }
                }
            }

            &.fullscreen {
                img {
                    height: 100%;
                }
            }
        }
    }

    .modal-buttons {
        display: flex;
        justify-content: space-between;
        padding-top: $modal-btn-control-spacing;
        margin-top: $modal-btn-control-spacing;
        border-top: 1px solid $modal-header-background;
    }
}

// tablet and larger
@media (min-width: $screen-sm) {
    .mfp-fade-modal {
        // Popup content style
        &.mfp-wrap .mfp-content {
            .c-modal {
                .mfp-close {
                    top: $base-spacing-2;
                    right: $base-spacing-2;

                    .svg-icon {
                        width: $base-spacing-6;
                        height: $base-spacing-6;
                    }
                }

                .modal-content {
                    padding: $base-spacing-4 $base-spacing-8 $base-spacing-10;
                }
            }

            .c-modal.fullscreen {
                height: calc(100vh - 64px);

                .modal-content {
                    height: calc(100vh - 120px);

                    img {
                        height: unset;
                    }
                }
            }
        }
    }
}
