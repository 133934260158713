$slider-arrow-height:               $base-spacing-6; //48px
$slider-arrow-width:                $base-spacing-6; //48px
$slider-arrow-color:                $color-white;

$slider-button-bg:                  $color-black;
$slider-button-padding:             $base-spacing-3;

.slick-arrow {
    @include vertical-center;
    padding: 0;
    width: $slider-arrow-width;
    height: $slider-arrow-height;
    z-index: 1;
    border: none;
    border-radius: 50%;
    color: $slider-arrow-color;
    background-color: $slider-button-bg;
    opacity: $input-disabled-opacity;
    transition: opacity 200ms ease-in;
    cursor: pointer;

    &:active,
    &:focus {
        outline: none;
    }

    &:hover,
    &:focus {
        opacity: 1;
    }

    .svg-icon {
        @include center-all;
    }

    span {
        @include visually-hidden;
    }
}

.slick-prev {
    left: 0;
}

.slick-next {
    right: 0;
}
