$mobile-header-height:         75px;
$mobile-header-height-xs:      auto;
$mobile-header-z-index:        3000;
$mobile-header-background:     $color-white;
$mobile-logo-width:            80px;
$mobile-nav-background:        $color-parchment-shade-1;
$body-mobile-padding-top:      $mobile-header-height;
$mobile-nav-box-shadow:        0 1px 7px 0 rgba($color-black, 0.09);
$mobile-header-border-color:   $color-shade-1;

@media (max-width: $screen-sm-max) {
    body {
        padding-top: $body-mobile-padding-top;

        &.mobile-menu-is-active {
            overflow: hidden;
        }
    }

    .s-mobile-header {
        .fixed-header-bar {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: $mobile-header-z-index;
            height: $mobile-header-height;
            background-color: $mobile-header-background;
            box-shadow: $mobile-nav-box-shadow;
            padding: 0 $base-spacing-4;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom-left-radius: $base-spacing-2;
            border-bottom-right-radius: $base-spacing-2;
        }

        .logo {
            margin: 0;
            width: $mobile-logo-width;

            img {
                display: block;
            }
        }

        .mobile-bar-left {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 90px;
        }

        .mobile-nav {
            display: flex;
            overflow: visible;
            flex-direction: column;
            position: fixed;
            padding-top: $base-spacing-3;
            z-index: 2000;
            top: $mobile-header-height;
            left: 100%;
            bottom: 0;
            right: 0;
            width: 100%;
            margin: 0;
            background: $mobile-nav-background;
            transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1), visibility 0s linear 0s, -webkit-transform 0.3s ease;

            &.is-active {
                transform: translate3d(-100%, 0, 0);
            }

            &.has-active-submenu {
                .c-mobile-search,
                #c-secondary-links {
                    @include visually-hidden;
                }
            }

            .entry {
                display: flex;
                padding: 0 $base-spacing-3;
                justify-content: center;

                > a {
                    margin: 0 10px;
                    min-height: 48px;
                }
            }
        }

        .member-links {
            height: 100%;

            ul {
                @include list-reset;
                display: flex;
                justify-content: center;
                height: 100%;
            }

            li {
                margin-bottom: 0;
                height: 100%;
            }

            a {
                @include body-small;
                display: flex;
                align-items: center;
                color: $color-shade-2;
                text-decoration: none;
                border-bottom: 4px solid $color-white;
                padding: $base-spacing-2;
                height: 100%;

                &.active,
                &:hover {
                    color: $color-black;
                    border-color: $color-primary-1;
                }
            }
        }

        .mobile-header-simple {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            .utility-links {
                margin-right: $base-spacing-3;
            }
        }

        .mobile-search-container {
            position: fixed;
            display: flex;
            flex-direction: row;
            align-items: center;
            top: -40px;
            width: 100%;
            height: 88px;
            z-index: 2500;
            background-color: $color-primary-1;
            padding: 50px 0 0;
            transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1), visibility 0s linear 0s, -webkit-transform 0.3s ease;

            &.is-active {
                transform: translate3d(0, 99px, 0);
            }

            .search-bar {
                flex-grow: 1;
            }

            .search-icon {
                display: none;
            }

            .mobile-search-close {
                position: absolute;
                right: 38px;
                top: 8px;
                z-index: 2000;
                border-radius: 50%;
                width: 24px;
                height: 24px;
                background-color: $color-shade-3;

                svg {
                    color: $color-white;
                }
            }
        }

        .mobile-search-holder {
            position: relative;
            max-width: 368px;
            padding: 0 $base-spacing-3 30px;
            width: 100%;
            margin: 0 auto;
        }
    }
}

@media (max-width: $screen-xs-max) {
    .s-mobile-header {
        .mobile-header-simple {
            display: block;
            text-align: center;

            .utility-links a {
                margin-top: $base-spacing-2;
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .s-mobile-header {
        .mobile-search-container {
            height: 96px;
        }
    }
}

@media (min-width: $screen-md) {
    .s-mobile-header {
        display: none;
    }
}
