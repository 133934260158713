.s-azure-search-result {
    overflow: hidden;

    &.s-search-results-kentico {
        .search-result-item {
            display: block;
        }
    }

    .search-wrapper {
        .search-bar,
        .search-bar-content {
            background: none;
        }

        .search-bar {
            margin-bottom: $base-spacing-6;
        }

        .search-container {
            padding: 0;
            flex-wrap: nowrap;
        }

        .search-inner {
            border: none;
        }

        .search-inner label {
            @include visually-hidden;
        }

        .search-inner input[type="text"] {
            @include base-input-styles;
            min-height: 50px;
            height: 50px;
        }

        .form-group-submit {
            margin-left: $base-spacing-2;
            position: relative;

            @include bg-icon('search', after, $color-primary-2) {
                position: absolute;
                left: $base-spacing-2;
                top: 12px;
                width: 24px;
                height: 24px;
                content: "";
            }

            input[type="submit"] {
                padding-left: $base-spacing-6;
            }
        }
    }
}

@media (max-width: $screen-xs-max) {
    .s-azure-search-result {
        .search-wrapper {
            .search-icon {
                display: none;
            }

            .search-bar {
                margin-bottom: $base-spacing-4;
            }
        }
    }
}
