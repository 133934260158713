// Engagement Alert
//----------------------------------------
$engagement-alert-padding:                $base-spacing-3;
$engagement-alert-color:                  $color-white;
$engagement-alert-bg:                     $color-black;
$engagement-alert-title-margin-right:     $base-spacing-7;

.s-engagement-alert {
    background-color: $engagement-alert-bg;

    .container {
        padding-top: $engagement-alert-padding;
        padding-bottom: $engagement-alert-padding;
    }

    p {
        color: $engagement-alert-color;
    }

    .btn {
        width: 100%;
        text-align: center;
    }
}

@media (min-width: $screen-sm) {
    .s-engagement-alert {
        .container {
            display: flex;
            align-items: flex-start;
        }

        p {
            margin-bottom: 0;
            margin-right: $engagement-alert-title-margin-right;
        }

        .btn {
            width: auto;
        }
    }
}
