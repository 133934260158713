.c-annual-returns {
    width: 100%;

    .annual-returns-legend {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 0 $base-spacing-1;
    }

    .annual-returns-legend-item {
        display: flex;
        align-items: center;
        margin-right: $base-spacing-2;

        span {
            margin-left: $base-spacing-1;
        }
    }
}

// IE 11 Fix
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .c-annual-returns {
        .annual-returns-legend + div {
            height: 320px !important;
        }
    }
}
