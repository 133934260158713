.s-superbalance {
    .row {
        width: 100%;
    }

    .hide {
        display: none;
        opacity: 0;
    }

    .left-panel {
        background-color: $color-primary-1;
        color: $color-white;
        display: flex;
        align-items: center;

        h2 {
            color: $color-primary-2;
        }

        a[class^='btn'] {
            border: 1px solid $color-white;
            margin-top: $base-spacing-2;

            &:hover {
                border-color: transparent;
            }
        }
    }

    .right-panel {
        background-color: $color-primary-shade-1;
        display: flex;
        align-items: center;
        position: relative;
    }

    .column {
        min-height: 500px;
        padding: $base-spacing-3;
        width: 100%;
    }

    .horizontal-panel {
        display: flex;
        flex-wrap: wrap;
        margin-top: $base-spacing-3;
        justify-content: center;

        .btn-pill {
            margin: $base-spacing-1 $base-spacing-2;
        }
    }

    .inner-panel {
        text-align: center;
        width: 100%;

        strong {
            color: $color-primary-1;
            text-transform: lowercase;
        }
    }

    .gender-items {
        display: flex;
        justify-content: center;

        .gender {
            border-radius: 50%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: $base-spacing-3;
            height: $base-spacing-10;
            width: $base-spacing-10;
            transition: all 0.3s;

            img {
                width: 100%;
            }

            &:hover {
                background-color: $color-primary-1;

                .svg-icon {
                    color: $color-primary-2;
                }
            }
        }

        .svg-icon {
            color: $color-primary-1;
            height: $base-spacing-6;
            width: $base-spacing-6;
        }
    }

    .balance-value {
        @include display-large;
        height: $base-spacing-8;
        margin-top: $base-spacing-3;
        opacity: 0;
    }

    .balance-result-panel {
        opacity: 0;
    }

    .disclaimer {
        display: block;
        position: absolute;
        bottom: $base-spacing-2;
        left: $base-spacing-2;
        text-align: left;
        width: calc(100% - #{$base-spacing-2 * 2});

        small,
        a[class^='btn'] {
            opacity: 0;
        }

        a[class^='btn'] {
            margin-top: $base-spacing-2;
        }
    }
}

@media (min-width: $screen-xs) {
    .s-superbalance {
        .column {
            padding: $base-spacing-5;
        }

        .disclaimer {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            a[class^='btn'] {
                margin-top: 0;
            }
        }
    }
}

@media (min-width: $screen-md) {
    .s-superbalance {
        .column {
            padding: $base-spacing-7;
            width: 50%;
        }
    }
}
