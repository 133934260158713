section.s-page-header {
    width: 100%;
    max-width: 1920px;
    min-height: 808px;
    // min-height: 624px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    padding: 125px 0 108px;
    color: $color-white;
    border-radius: 0 0 $base-spacing-4 $base-spacing-4;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }

    .page-header__image-wrapper {
        position: absolute;
        top: 0;
        z-index: 10;
        width: 100%;
        max-width: 1202px;

        img {
            width: 100%;
            height: auto;
        }
    }

    .page-header__image-spacer {
        position: relative;

        &::before {
            content: "";
            display: block;
        }
    }

    .container {
        color: $color-white;
        position: relative;
        z-index: 20;
    }

    .page-header__row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .page-header__col {
        grid-column: span 2;
    }

    .page-header__tag-holder {
        margin-bottom: $base-spacing-3;
    }

    .page-header__tag {
        @include body-small;
        background-color: $color-white;
        height: 36px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        color: $color-black;
        border: 1px solid $color-shade-3;
        border-radius: 18px;
        width: auto;
    }

    .page-header__title {
        text-transform: none;

        span {
            display: block;
        }
    }

    .page-header__date-info,
    .page-header__description {
        @include body-intro;
        margin-top: $base-spacing-3;
    }

    .page-header__date-info-breaker {
        display: inline-block;
        width: $base-spacing-1;
        height: $base-spacing-1;
        border-radius: 50%;
        margin: 0 $base-spacing-1 4px 10px;
    }

    .page-header__cta-box {
        margin-top: $base-spacing-6;
    }

    .page-header__breadcrumb {
        margin-bottom: $base-spacing-6;
    }

    /**
    * variant: Skin primary
    **/

    &.skin-primary {
        &::before {
            width: 1920px;
            height: 3226px;
            background: $gradient-brand-banner;
        }

        .page-header__image-wrapper {
            width: 100%;
            max-width: 1202px;
        }

        .page-header__title {
            color: $color-white;
        }

        .page-header__date-info,
        .page-header__description {
            color: $color-white;
        }

        .page-header__breadcrumb {
            color: $color-white;

            .CMSBreadCrumbsLink,
            .CMSBreadCrumbsCurrentItem {
                color: $color-white;
            }
        }
    }

    /**
    * variant: Skin secondary
    **/

    &.skin-secondary {
        &::before {
            width: 100%;
            height: 100%;
            background: $color-shade-1;
        }

        .page-header__image-wrapper {
            max-width: 100%;
            top: 0;
            left: 0;

            img {
                width: 100%;
            }
        }

        .page-header__breadcrumb {
            color: $color-primary-1;

            .CMSBreadCrumbsLink,
            .CMSBreadCrumbsCurrentItem {
                color: $color-primary-1;
            }
        }

        .page-header__title {
            color: $color-primary-1;
        }

        .page-header__date-info,
        .page-header__description {
            color: $color-primary-1;
        }

        .page-header__date-info-breaker {
            background-color: $color-primary-1;
        }
    }

    /**
    * base: skin-tertiary
    **/

    &.skin-tertiary-1,
    &.skin-tertiary-2,
    &.skin-tertiary-3,
    &.skin-tertiary-4 {
        min-height: 400px;
        padding-bottom: $base-spacing-10;

        &::before {
            width: 100%;
            height: 100%;
            background: $gradient-tertiary-banner;
        }

        .page-header__image-wrapper {
            display: none;
        }

        .page-header__image-spacer {
            display: none;
        }

        .page-header__content {
            margin-top: $base-spacing-3;
        }

        .page-header__title {
            color: $color-white;
        }

        .page-header__date-info,
        .page-header__description {
            color: $color-white;
        }

        .page-header__date-info-breaker {
            background-color: $color-white;
        }

        .page-header__breadcrumb {
            color: $color-white;
            margin-bottom: $base-spacing-6;

            .CMSBreadCrumbsLink,
            .CMSBreadCrumbsCurrentItem {
                color: $color-white;
            }
        }
    }

    .tertiary-1-flare,
    .tertiary-2-flare-a,
    .tertiary-2-flare-b,
    .tertiary-2-flare-c,
    .tertiary-3-flare-a,
    .tertiary-3-flare-b,
    .tertiary-3-flare-c,
    .tertiary-4-flare-a,
    .tertiary-4-flare-b {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        color: $color-primary-2;
        opacity: 0.3;
    }

    &.skin-tertiary-1 {
        .tertiary-1-flare {
            display: block;
        }
    }

    &.skin-tertiary-2 {
        .tertiary-2-flare-a,
        .tertiary-2-flare-b,
        .tertiary-2-flare-c {
            display: block;
        }
    }

    &.skin-tertiary-3 {
        .tertiary-3-flare-a,
        .tertiary-3-flare-b,
        .tertiary-3-flare-c {
            display: block;
        }
    }

    &.skin-tertiary-4 {
        .tertiary-4-flare-a,
        .tertiary-4-flare-b {
            display: block;
        }
    }
}

// mobile only
@media (max-width: $screen-xs-max) {
    section.s-page-header {
        .page-header__image-spacer {
            &::before {
                padding-top: 110%;
            }
        }

        .page-header__breadcrumb {
            display: none;
        }

        &.skin-primary {
            .page-header__image-wrapper {
                width: 185%;
                left: 50%;
                transform: translate(-50%, -18%);
            }

            &.has-mobile-img .page-header__image-wrapper {
                width: 100%;
                transform: translate(-50%, 0%);
            }
        }

        &.skin-secondary {
            .page-header__image-wrapper {
                width: 290%;
                max-width: none;
                left: auto;
                right: 0;
                transform: translate(15%, -8%);

                img {
                    width: 100%;
                }
            }

            &.has-mobile-img .page-header__image-wrapper {
                width: 100%;
                transform: translate(0%, 0%);
            }
        }

        &.skin-tertiary-1 {
            .tertiary-1-flare {
                transform: translate(30%, -15%);

                .svg-icon {
                    width: 280px;
                    height: 280px;
                }
            }
        }

        &.skin-tertiary-2 {
            .tertiary-2-flare-a {
                transform: translate(40px, -40px);

                .svg-icon {
                    width: 213px;
                    height: 213px;
                }
            }

            .tertiary-2-flare-b {
                transform: translate(-73px, 56px);

                .svg-icon {
                    width: 200px;
                    height: 200px;
                }
            }

            .tertiary-2-flare-c {
                transform: translate(-315px, 30px);

                .svg-icon {
                    width: 94px;
                    height: 94px;
                }
            }
        }

        &.skin-tertiary-3 {
            .tertiary-3-flare-a {
                transform: translate(-31px, 41px);

                .svg-icon {
                    width: 89px;
                    height: 89px;
                }
            }

            .tertiary-3-flare-b {
                transform: translate(-211px, 26px);

                .svg-icon {
                    width: 183px;
                    height: 183px;
                }
            }

            .tertiary-3-flare-c {
                transform: translate(-104px, 121px);

                .svg-icon {
                    width: 200px;
                    height: 200px;
                }
            }
        }

        &.skin-tertiary-4 {
            .tertiary-4-flare-a {
                transform: translate(-38px, 15px);

                .svg-icon {
                    width: 173px;
                    height: 173px;
                }
            }

            .tertiary-4-flare-b {
                transform: translate(-120px, 97px);

                .svg-icon {
                    width: 275px;
                    height: 275px;
                }
            }
        }
    }
}

// tablet only
@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    section.s-page-header {
        .page-header__image-spacer {
            &::before {
                padding-top: 80%;
            }
        }

        &.skin-primary {
            .page-header__image-wrapper {
                width: 132%;
                left: 50%;
                transform: translate(-50%, -17%);
            }
        }

        &.skin-secondary {
            .page-header__image-wrapper {
                width: 212%;
                max-width: none;
                left: auto;
                right: 0;
                transform: translate(15%, -7%);

                img {
                    width: 100%;
                }
            }
        }

        &.skin-tertiary-1 {
            .tertiary-1-flare {
                transform: translate(30%, -30%);

                .svg-icon {
                    width: 525px;
                    height: 525px;
                }
            }
        }

        &.skin-tertiary-2 {
            .tertiary-2-flare-a {
                transform: translate(40px, -40px);

                .svg-icon {
                    width: 400px;
                    height: 400px;
                }
            }

            .tertiary-2-flare-b {
                transform: translate(-173px, 156px);

                .svg-icon {
                    width: 372px;
                    height: 372px;
                }
            }

            .tertiary-2-flare-c {
                transform: translate(-615px, 30px);

                .svg-icon {
                    width: 177px;
                    height: 177px;
                }
            }
        }

        &.skin-tertiary-3 {
            .tertiary-3-flare-a {
                transform: translate(-61px, 81px);

                .svg-icon {
                    width: 177px;
                    height: 177px;
                }
            }

            .tertiary-3-flare-b {
                transform: translate(-411px, 51px);

                .svg-icon {
                    width: 366px;
                    height: 366px;
                }
            }

            .tertiary-3-flare-c {
                transform: translate(-204px, 221px);

                .svg-icon {
                    width: 400px;
                    height: 400px;
                }
            }
        }

        &.skin-tertiary-4 {
            .tertiary-4-flare-a {
                transform: translate(-68px, 31px);

                .svg-icon {
                    width: 233px;
                    height: 233px;
                }
            }

            .tertiary-4-flare-b {
                transform: translate(-160px, 127px);

                .svg-icon {
                    width: 545px;
                    height: 545px;
                }
            }
        }
    }
}

// tablet and larger
@media (min-width: $screen-sm) {
    section.s-page-header {
        padding-bottom: 174px;
        border-radius: 0 0 $base-spacing-10 $base-spacing-10;

        .page-header__cta-box {
            margin-top: $base-spacing-8;
        }

        .page-header__date-info,
        .page-header__description {
            margin-top: $base-spacing-4;
        }

        &.skin-tertiary-1,
        &.skin-tertiary-2,
        &.skin-tertiary-3,
        &.skin-tertiary-4 {
            min-height: 570px;
            padding-bottom: $base-spacing-15;
        }
    }
}

// desktop and larger
@media (min-width: $screen-md) {
    .s-desktop-header + .page-content section.s-page-header {
        margin-top: -174px;
        z-index: 1;
    }

    section.s-page-header {
        padding: 230px 0 $base-spacing-15;

        .page-header__col {
            grid-column: span 1;
        }

        .page-header__cta {
            @include btn-large;
        }

        .page-header__image-wrapper {
            right: 0;
            transform: translateY(-10%);
            display: flex;
            justify-content: flex-end;

            > picture {
                width: 80%;
            }
        }

        &.skin-secondary .page-header__image-wrapper {
            transform: translate(-25%, 0);
            width: 150%;
            max-width: unset;
        }

        .page-header__content {
            max-width: 540px;
        }

        &.skin-tertiary-1,
        &.skin-tertiary-2,
        &.skin-tertiary-3,
        &.skin-tertiary-4 {
            min-height: 644px;
        }

        &.skin-tertiary-1 {
            .tertiary-1-flare {
                transform: translate(8%, -5%);

                .svg-icon {
                    width: 674px;
                    height: 674px;
                }
            }
        }

        &.skin-tertiary-2 {
            .tertiary-2-flare-a {
                transform: translate(-52px, 56px);

                .svg-icon {
                    width: 513px;
                    height: 513px;
                }
            }

            .tertiary-2-flare-b {
                transform: translate(-293px, 276px);

                .svg-icon {
                    width: 470px;
                    height: 470px;
                }
            }

            .tertiary-2-flare-c {
                transform: translate(-915px, 76px);

                .svg-icon {
                    width: 227px;
                    height: 227px;
                }
            }
        }

        &.skin-tertiary-3 {
            .tertiary-3-flare-a {
                transform: translate(-81px, 111px);

                .svg-icon {
                    width: 227px;
                    height: 227px;
                }
            }

            .tertiary-3-flare-b {
                transform: translate(-511px, 71px);

                .svg-icon {
                    width: 470px;
                    height: 470px;
                }
            }

            .tertiary-3-flare-c {
                transform: translate(-254px, 271px);

                .svg-icon {
                    width: 513px;
                    height: 513px;
                }
            }
        }

        &.skin-tertiary-4 {
            .tertiary-4-flare-a {
                transform: translate(-88px, 41px);

                .svg-icon {
                    width: 299px;
                    height: 299px;
                }
            }

            .tertiary-4-flare-b {
                transform: translate(-210px, 167px);

                .svg-icon {
                    width: 700px;
                    height: 700px;
                }
            }
        }
    }
}

// desktop and larger
@media (min-width: $screen-lg) {
    section.s-page-header {
        min-height: 808px;

        .page-header__image-wrapper {
            > picture {
                width: 100%;
            }
        }

        &.skin-secondary .page-header__image-wrapper {
            max-width: 100%;
            width: 100%;
            transform: unset;
        }

        .page-header__content {
            max-width: 600px;
        }
    }
}

// desktop and larger
@media (min-width: $screen-xlg) {
    section.s-page-header {
        .page-header__content {
            max-width: 100%;
        }
    }
}
