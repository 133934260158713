$active-filters-padding-tags:       $base-spacing-3 0;
$active-filters-padding-text:       $base-spacing-3 0;
$active-filter-margin:              $base-spacing-2;
$active-filter-margin-sm:           $base-spacing-1;

$active-filter-item-padding:        $base-spacing-2 $base-spacing-1;
$active-filter-item-radius:         $base-spacing-1;
$active-filter-item-icon-width:     $base-spacing-3;
$active-filter-item-icon-height:    $base-spacing-3;

$active-filters-section-bg-color:   $color-shade-1;
$active-filters-bg:                 $color-shade-2;
$active-filters-reset-color:         $color-primary-1;
$remove-filter-icon-color:          $listing-icon-color;

.listing-active-filters {
    background-color: $active-filters-section-bg-color;

    .active-filters {
        display: flex;
        flex-wrap: wrap;

        > div,
        > span {
            display: flex;
            align-items: center;
            flex: 0 0 auto;
        }

        .active-filters-label {
            @include body-small;
        }

        .active-filter-item {
            span {
                @include body-small;
            }

            // The cross
            .svg-icon {
                flex: 0 0 $active-filter-item-icon-width;
                width: $active-filter-item-icon-width;
                height: $active-filter-item-icon-height;
                max-height: none;
                max-width: none;
                margin-left: $active-filter-margin-sm;
                color: $remove-filter-icon-color;
            }
        }

        .active-filters-reset {
            @include body-small;
            color: $active-filters-reset-color;
            cursor: pointer;
        }
    }

    // modifiers
    &.btn-tags {
        padding: $active-filters-padding-tags;

        .active-filters {
            > div {
                margin-bottom: $active-filter-margin;
            }

            .active-filter-item {
                flex-direction: row-reverse;
                margin-right: $active-filter-margin;
                padding: $active-filter-margin-sm;
                border-radius: $active-filter-item-radius;
                background-color: $active-filters-bg;
                cursor: pointer;
            }
        }
    }

    &.text-list {
        padding: $active-filters-padding-text;

        .active-filters {
            .active-filter-item {
                position: relative;
                margin-right: $active-filter-margin / 1.5;
                font-weight: bold;

                &::after {
                    position: absolute;
                    right: -4px;
                    content: ',';
                }

                .svg-icon {
                    display: none;
                }

                &:last-of-type {
                    margin-right: $active-filter-margin;

                    &::after {
                        content: '';
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs-max) {
    .listing-active-filters {
        .active-filters {
            .active-filters-label,
            .active-filters-reset {
                flex: 0 0 100%;
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .listing-active-filters {
        &.btn-tags {
            .active-filters {
                justify-content: center;

                > div,
                > span {
                    margin-bottom: 0;
                }
            }
        }

        &.btn-tags {
            .active-filters-label {
                margin-right: $active-filter-margin;
            }
        }

        &.text-list {
            .active-filters-label {
                margin-right: $active-filter-margin-sm;
            }
        }
    }
}
