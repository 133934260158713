// Base Font Families / Font Size / Line Heights
$base-font-family:        $Avenir-Roman; // taken from font-stacks.scss
$base-font-color:         $color-black;
$baseline:                18px; // used for rem type units
$base-font-size:          18px;
$base-line-height:        24px;

// $type-breakpoints var used in responsive mapped-font-size mixin
// add more breakpoints here if you need to for responsive typography.
$type-breakpoints: (
    sm: $screen-sm
);

$base-type: (
    null: ($base-font-size, $base-line-height)
);

html {
    height: 100%;
    font-size: $base-font-size;
}

body {
    font-family: $base-font-family;
    @include font-size($base-type);
    background-color: $color-white;
    color: $base-font-color;
}
