section.s-your-fund {
    position: relative;
    background-color: $color-white;
    padding-top: 0;
    padding-bottom: 0;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 60%;
        background-color: $color-primary-1;
        z-index: 1;
    }

    .container {
        position: relative;
        z-index: 2;
        background-color: $color-primary-2;
        padding-top: $base-spacing-8;
        padding-bottom: $base-spacing-8;
    }

    .your-fund-header,
    .your-fund-links {
        @include stack;
    }

    .your-fund-header {
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
            @include display-medium;
            text-transform: none;

            span {
                display: block;
            }
        }

        img {
            max-width: 470px;
            width: 100%;
        }
    }

    .your-fund-links {
        background-color: $color-white;

        ul {
            @include list-reset();

            li {
                padding: 0 $base-spacing-3;
            }

            a {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: $base-spacing-3 0;
                border-bottom: 1px solid $color-primary-shade-1;
                @include body-normal-heavy;
                color: $color-primary-1;
                text-decoration: none;

                svg {
                    color: $color-primary-2;
                    margin-right: $base-spacing-2;
                }

                &:hover,
                &:focus {
                    color: $color-primary-2;
                }
            }
        }
    }
}

@media (max-width: $screen-xs-max) {
    section.s-your-fund {
        .your-fund-header {
            margin-bottom: $base-spacing-7;

            h2 {
                margin-bottom: $base-spacing-8;
            }

            img {
                width: 100%;
            }
        }

        .your-fund-links {
            margin-bottom: 0;
        }
    }
}

@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    section.s-your-fund {
        .container {
            padding-top: $base-spacing-10;
            padding-bottom: $base-spacing-10;
        }

        .your-fund-header,
        .your-fund-links {
            @include col(10);
            @include col-offset(1);
        }

        .your-fund-header {
            margin-bottom: $base-spacing-10;

            h2 {
                margin-bottom: $base-spacing-14;
            }
        }
    }
}

@media (min-width: $screen-md) {
    section.s-your-fund {
        .container {
            padding-top: $base-spacing-16;
            padding-bottom: 0;
        }

        .row {
            align-items: center;
        }

        .your-fund-header {
            @include col(6);
            @include col-offset(1);

            h2 {
                margin-bottom: $base-spacing-14;
            }
        }

        .your-fund-links {
            @include col(3);
            @include col-offset(1);
        }
    }
}
