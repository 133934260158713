.form-total-allocation {
    @include base-mobile-form-layout;
    position: relative;

    .display-allocation {
        @include bg-theme-5;
        color: $color-white;
        font-weight: bold;
        justify-content: space-between;
        display: flex;
        padding: $base-spacing-2 $base-spacing-3;
        width: 100%;
    }

    .value {
        margin-left: $base-spacing-2;
    }

    .error-msg {
        display: none;
    }

    &.has-error {
        .display-allocation {
            border: 1px solid $color-error;
            background-color: $color-white;
            color: $color-error;
        }

        .error-msg {
            display: block;
        }
    }
}

@media (min-width: $screen-sm) {
    .form-total-allocation {
        @include base-desktop-form-layout;
    }
}
