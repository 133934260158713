// Intrinsic Placeholder Ratios
// =======================================================================
// http://daverupert.com/2015/12/intrinsic-placeholders-with-picture
$image-placeholder-bg:        $color-shade-3;

// aspect ratio calculator function based on
// https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/
@function aspect-ratio($width, $height) {
    @return ($height / $width) * 100%;
}

@mixin placeholder-ratio-wrapper($ratio: '3x2') {
    position: relative;
    height: 0;
    width: 100%;
    background: $image-placeholder-bg;

    @if ($ratio == '4x3') {
        padding-bottom: aspect-ratio(4, 3);
    }

    @else if ($ratio == '16x9') {
        padding-bottom: aspect-ratio(16, 9);
    }

    @else if ($ratio == '21x9') {
        padding-bottom: aspect-ratio(21, 9);
    }

    @else if ($ratio == '1x1') {
        padding-bottom: 100%;
    }

    @else {
        padding-bottom: aspect-ratio(3, 2);
    }
}

@mixin placeholder-image-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
