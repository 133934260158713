$biography-tile-paragraph-margin-top:           $base-spacing-2;
$biography-tile-content-margin-top:             $base-spacing-2;

$biography-tile-bg-min-height:                  $base-spacing-14; //112px
$biography-tile-bg-content-xs-margin:           (-$base-spacing-10) $base-spacing-3 0;
$biography-tile-bg-content-margin:              (-$base-spacing-10) $base-spacing-4 0;
$biography-tile-bg-content-padding:             $base-spacing-6;
$biography-tile-bg-content-xs-padding:          $base-spacing-4;
$biography-tile-bg-1-bg-color:                  $color-primary-shade-1;
$biography-tile-bg-2-bg-color:                  $color-shade-1;
$biography-tile-text-color:                     $color-black;
$biography-tile-title-margin:                   0 0 $base-spacing-2 0;
$biography-tile-photo-min-height:               500px;
$biography-tile-photo-min-height-md:            500px;
$biography-tile-link:                           $color-shade-1;
$biography-tile-bg-colour-hover:                $color-shade-1;
$biography-tile-heading-colour-hover:           $color-black;
$biography-tile-read-bio-color:                 $color-primary-1;
$biography-tile-read-bio-color-hover:           $color-primary-2;
$biography-tile-read-bio-svg-color:             $color-primary-1;
$biography-tile-read-bio-svg-transition:        all 200ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
$biography-tile-read-bio-svg-transform:         rotateZ(180deg);
$biography-tile-highlight-margin:               0 (-$base-spacing-3) 0 (-$base-spacing-3);
$biography-tile-header-bg-color:                $color-white;
$biography-tile-header-height:                  $base-spacing-2;
$biography-tile-arrow-down-color:               $color-white;
$biography-tile-arrow-down-margin:              0 0 0 $base-spacing-3;
$biography-tile-close-bio-color:                $color-primary-1;
$biography-tile-close-bio-color-hover:          $color-primary-2;
$biography-tile-close-bio-top:                  (-$base-spacing-4);
$biography-tile-close-bio-svg-color:            $color-primary-2;
$biography-tile-highlight-body-padding:         $base-spacing-6 $base-spacing-2 $base-spacing-4 $base-spacing-2;

.c-biography-tile {
    .biography-tile-photo {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 0;
        width: 100%;
        padding-bottom: 100%;
        border-radius: 50%;
    }

    p {
        color: $color-black;
    }

    .biography-tile-container {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        margin-top: $base-spacing-3;
        padding: 0 $base-spacing-4;
        min-height: $biography-tile-bg-min-height;
        text-align: left;
        z-index: 1;

        a {
            text-decoration: none;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

        .title {
            margin: $biography-tile-title-margin;
        }

        .btn-tag {
            align-self: center;
            margin-bottom: $base-spacing-2;
        }

        .description {
            color: $biography-tile-text-color;
        }

        .read-bio {
            @include body-normal-heavy;
            color: $biography-tile-read-bio-color;
            margin-top: $base-spacing-1;
            text-decoration: none;

            svg {
                color: $biography-tile-read-bio-svg-color;
                transition: $biography-tile-read-bio-svg-transition;
                vertical-align: top;
            }

            &.is-visible svg {
                transform: $biography-tile-read-bio-svg-transform;
            }


            &:hover,
            &:focus {
                color: $biography-tile-read-bio-color-hover;
                text-decoration: none;

                svg {
                    color: $biography-tile-read-bio-color-hover;
                    text-decoration: none;
                }
            }
        }
    }

    .biography-tile-highlight {
        display: none;
        margin: $biography-tile-highlight-margin;

        .header,
        .footer {
            height: $biography-tile-header-height;
            width: 100%;
        }

        .arrow-down {
            border-top: 35px solid $biography-tile-arrow-down-color;
            border-left: 40px solid transparent;
            border-right: 40px solid transparent;
            height: 0;
            margin: $biography-tile-arrow-down-margin;
            width: 0;
        }

        .close-bio {
            color: $biography-tile-close-bio-color;
            font-weight: 800;
            position: absolute;
            right: 0;
            text-decoration: none;
            top: $biography-tile-close-bio-top;

            svg {
                color: $biography-tile-close-bio-svg-color;
                vertical-align: bottom;
            }

            &:hover,
            &:focus {
                color: $biography-tile-close-bio-color-hover;
            }
        }

        .body {
            padding: $biography-tile-highlight-body-padding;

            .container {
                position: relative;
            }

            .text {
                display: flex;
                flex-direction: column;
            }
        }

        .highlight-text {
            @include display-extra-small;
            color: $color-primary-1;
            margin-bottom: $base-spacing-2;
        }

        &.processed {
            position: relative;
            z-index: 2;
        }
    }

    &:hover,
    &:focus {
        .biography-tile-container {
            .title {
                color: $biography-tile-heading-colour-hover;
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .c-biography-tile {
        .biography-tile-photo {
            background-position: top center;
        }

        .biography-tile-highlight {
            .body {
                padding: $base-spacing-5 $base-spacing-2 $base-spacing-4 $base-spacing-2;

                .close-bio {
                    right: $base-spacing-3;
                    top: -$base-spacing-2;
                }

                .text {
                    flex-direction: row;
                    padding: $base-spacing-5;

                    .highlight-text {
                        margin-right: $base-spacing-4;
                    }

                    .normal-text {
                        margin-left: $base-spacing-4;
                    }
                }
            }
        }

        .biography-tile-container {
            display: block;
            text-align: left;

            .title {
                text-align: left;
            }
        }
    }
}

@media (min-width: $screen-lg) {
    .c-biography-tile {
        .biography-tile-highlight {
            .body {
                .close-bio {
                    right: $base-spacing-6;
                }
            }
        }
    }
}

// Hack for IE11
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .c-biography-tile {
        .biography-tile-container {
            .title {
                //flex: 1 0 auto;
                flex-grow: 1;
                flex-shrink: 0;
                flex-basis: auto;
            }
        }
    }
}
