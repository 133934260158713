$cta-image-mobile-height:               200px;
$cta-image-tablet-height:               448px;
$cta-image-desktop-height:              508px;
$cta-imge-white-border-height:          $base-spacing-12;
$cta-imge-white-border-tablet-height:   224px;
$cta-side-padding-mobile:               $base-spacing-3;
$cta-side-padding-tablet:               $base-spacing-6;
$cta-desktop-margin:                    $base-section-desktop-padding;

.s-cta-panel-with-image {
    .image-wrapper {
        .image-container {
            height: $cta-image-mobile-height;
            background-position: center center;
            background-size: cover;
        }
    }

    .content-container {
        @include body-normal;

        h2 {
            @include display-heading-xx-large;
            margin-bottom: $base-spacing-3;
        }

        p {
            margin-bottom: 0;
            color: $color-black;
        }

        strong,
        em,
        u,
        a,
        span {
            display: contents;
        }

        a[class^="cta-"] {
            display: flex;
        }

        .cta-container {
            margin-top: $base-spacing-3;
        }

        .cta-secondary {
            @include body-normal-heavy;
            color: $color-primary-1;
            display: flex;
            align-items: center;
            text-decoration: none;

            svg {
                margin-left: $base-spacing-1;
                color: $color-primary-2;
                transition: 0.3s ease-in-out all;
            }

            &:hover,
            &:focus {
                color: $color-primary-2;

                svg {
                    margin-left: $base-spacing-2;
                }
            }
        }
    }
}

@media (max-width: $screen-sm-max) {
    .s-cta-panel-with-image {
        .desktop-row {
            display: flex;
            flex-direction: column-reverse;

            .image-wrapper {
                background-color: $color-shade-1;
                padding: 0 $cta-side-padding-mobile;
                position: relative;

                .image-container {
                    z-index: 1;
                    position: relative;
                }

                &::before {
                    content: '';
                    height: $cta-imge-white-border-height;
                    width: 100%;
                    background-color: $color-white;
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                }
            }

            .content-container {
                background-color: $color-shade-1;
                padding: $base-spacing-6 $cta-side-padding-mobile;

                .cta-primary,
                .cta-secondary {
                    align-self: flex-start;
                }

                .cta-secondary {
                    margin-top: $base-spacing-4;
                }
            }
        }
    }
}

@media (min-width: $screen-xs) {
    .s-cta-panel-with-image {
        .container .desktop-row {
            .content-container {
                .cta-container {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .cta-secondary {
                        margin-top: 0;
                        align-self: center;
                    }

                    .cta-primary + .cta-secondary {
                        margin-left: $base-spacing-3;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .s-cta-panel-with-image {
        .desktop-row {
            .image-wrapper {
                padding: 0 $cta-side-padding-tablet;

                &::before {
                    height: $cta-imge-white-border-tablet-height;
                }

                .image-container {
                    height: $cta-image-tablet-height;
                }
            }

            .content-container {
                padding: $base-spacing-7 $cta-side-padding-tablet;
            }
        }
    }
}

@media (min-width: $screen-md) {
    section.s-cta-panel-with-image {
        padding: 0;
        margin: $cta-desktop-margin 0;

        &.no-margin-top {
            margin-top: 0;
        }

        .container {
            background-color: $color-shade-1;
            position: relative;
            padding-top: $base-section-desktop-padding;
            padding-bottom: $base-section-desktop-padding;

            &::before {
                content: '';
                width: 25%;
                background-color: $color-white;
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
            }

            &::after {
                content: '';
                width: $base-spacing-3;
                background-color: $color-white;
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
            }

            .desktop-row {
                @include row;

                .content-container,
                .image-wrapper {
                    position: relative;
                    z-index: 1;
                    display: flex;
                    justify-content: center;
                    padding: 0;
                }

                .image-wrapper {
                    @include col(6);

                    .image-container {
                        height: $cta-image-desktop-height;
                    }
                }

                .content-container {
                    @include col(4);
                }
            }
        }

        &:not(.reverse) {
            .container {
                &::before {
                    right: 0;
                }

                &::after {
                    left: 0;
                }

                .image-wrapper,
                .content-container {
                    @include col-offset(1);
                }
            }
        }

        &.reverse {
            .container {
                &::before {
                    left: 0;
                }

                &::after {
                    right: 0;
                }

                .desktop-row {
                    flex-direction: row-reverse;

                    .content-container,
                    .image-wrapper {
                        @include col-offset(1, true);
                    }
                }
            }
        }
    }
}
