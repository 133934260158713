// Buttons
//----------------------------------------
$btn-small-padding:                         $base-spacing-1 + 4px $base-spacing-3;
$btn-padding:                               $base-spacing-1 $base-spacing-4;
$btn-large-padding:                         $base-spacing-2 $base-spacing-5;
$btn-border-radius:                         100px;
$btn-bg-hover-opacity:                      0.7;
$btn-disabled-opacity:                      0.4;

$btn-skin-1-color:                          $color-primary-1; //$color-white;
$btn-skin-1-bg:                             $color-secondary-shade-7; //$color-primary-2;
$btn-skin-1-hover-color:                    $color-black;
$btn-skin-1-hover-bg:                       $color-accent-2;

$btn-skin-2-color:                          $color-primary-1;
$btn-skin-2-bg:                             $color-white;
$btn-skin-2-hover-color:                    $color-white;
$btn-skin-2-hover-bg:                       $color-accent-2;

$btn-skin-3-color:                          $color-primary-shade-5;
$btn-skin-3-bg:                             $color-primary-shade-1;
$btn-skin-3-hover-color:                    $color-primary-1;
$btn-skin-3-hover-bg:                       $color-primary-shade-3;

$btn-skin-4-color:                          $color-primary-1;
$btn-skin-4-bg:                             transparent;
$btn-skin-4-border-color:                   $color-primary-1;
$btn-skin-4-hover-color:                    $color-white;
$btn-skin-4-hover-bg:                       $color-primary-1;
$btn-skin-4-hover-border-color:             $btn-skin-3-hover-bg;

$btn-pill-padding:                          $base-spacing-1 $base-spacing-3;
$btn-pill-bg:                               transparent;
$btn-pill-color:                            $color-primary-1;
$btn-pill-border-color:                     $color-primary-1;
$btn-pill-border-radius:                    28px;
$btn-pill-hover-color:                      $color-white;
$btn-pill-hover-border-color:               $color-primary-1;
$btn-pill-active-color:                     $color-white;
$btn-pill-active-border-color:              $color-primary-1;
$btn-pill-active-bg:                        $color-primary-1;

$btn-mobile-nav-tab-padding:                0 0 $base-spacing-2 0;
$btn-mobile-nav-tab-bg:                     transparent;
$btn-mobile-nav-tab-border-color:           transparent;
$btn-mobile-nav-tab-hover-border-color:     $color-primary-1;

$btn-tag-color:                             $color-primary-1;
$btn-tag-padding:                           $base-spacing-1 / 2 $base-spacing-1;
$btn-tag-hover-color:                       $color-white;
$btn-tag-hover-bg:                          $color-primary-1;

$btn-icon-size:                             $base-spacing-3;
$btn-icon-color:                            $color-primary-1;

$btn-ghost-color:                           $color-primary-1;

// base button abstract class. All the btn-skin-x classes needs to extend this abstract class
%base-button-styles {
    @include body-normal;
    display: inline-flex;
    padding: $btn-padding;
    line-height: 1;
    border-radius: $btn-border-radius;
    border-color: transparent;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    text-decoration: none !important;
    font-family: $Avenir-Heavy;
    font-weight: 900;
    min-height: $base-spacing-7;
    align-items: center;
    vertical-align: middle;
    gap: $base-spacing-1;

    &:disabled {
        cursor: default;

        &:hover {
            cursor: default;
            text-decoration: none;
        }
    }
}

/*
 * Button Themes
 */
@mixin button-theme ($colour, $bg, $hover-bg, $hover-color) {
    @extend %base-button-styles;
    color: $colour !important; // Need the important to override link styles.
    background-color: $bg;

    &:not(:disabled):not(.btn-disabled) {
        &:hover {
            background-color: $hover-bg;
            color: $hover-color !important;
        }
    }

    &.btn-icon {
        .svg-icon {
            fill: $colour;
        }

        &:hover {
            .svg-icon {
                fill: $hover-color;
            }
        }
    }
}

@mixin btn-skin-1 {
    @include button-theme ($btn-skin-1-color, $btn-skin-1-bg, $btn-skin-1-hover-bg, $btn-skin-1-hover-color);

    &:focus,
    &:active {
        background: $color-accent-2;
        color: $color-white !important;
    }

    &:disabled,
    &.btn-disabled {
        background: $color-disabled;
        color: $color-shade-2 !important;
    }
}

.btn-skin-1 {
    @include btn-skin-1;
}

@mixin btn-skin-2 {
    @include button-theme ($btn-skin-2-color, $btn-skin-2-bg, $btn-skin-2-hover-bg, $btn-skin-2-hover-color);
    border: 1px $color-primary-1 solid;

    &:active,
    &:focus {
        background: $btn-skin-2-hover-bg;
        color: $color-black !important;
    }

    &:disabled,
    &.btn-disabled {
        background: $color-disabled;
        color: $color-shade-2 !important;
        border-color: $color-disabled;
    }
}

.btn-skin-2 {
    @include btn-skin-2;
}

@mixin btn-skin-3 {
    @include button-theme ($btn-skin-3-color, $btn-skin-3-bg, $btn-skin-3-hover-bg, $btn-skin-3-hover-color);
}

.btn-skin-3 {
    @include btn-skin-3;
}

@mixin btn-skin-4 {
    @extend %base-button-styles;
    color: $btn-skin-4-color;
    background-color: transparent;
    border: 1px solid $btn-skin-4-border-color;
    display: inline-flex;
    overflow: hidden;
    position: relative;
    z-index: 1;
    align-items: center;

    &::after {
        width: 100%;
        height: 100%;
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateY(100%);
        z-index: -1;
    }

    &:not(.btn-disabled) {
        &:hover {
            color: $color-white;

            &::after {
                background-color: $color-primary-1;
                transform: translateY(0%);
            }
        }
    }
}

.btn-skin-4 {
    @include btn-skin-4;
}

/*
 * Button Sizes
 */
@mixin btn-large {
    padding: $btn-large-padding;
    font-size: 26px;
    line-height: 36px;
    min-height: $base-spacing-9;
}

.btn-large {
    @include btn-large;
}

@mixin btn-small {
    padding: $btn-small-padding;
}

.btn-small {
    @include btn-small;
}

@mixin btn-pill {
    @extend %base-button-styles;
    padding: $btn-pill-padding;
    background-color: $btn-pill-bg;
    color: $btn-pill-color;
    border: 1px solid $btn-pill-border-color;
    border-radius: $btn-pill-border-radius;
    min-height: $base-spacing-5;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;

    &:not(.btn-disabled) {
        &:hover,
        &:focus {
            color: $btn-pill-hover-color;
            background-color: $btn-pill-active-bg;
            border-color: $btn-pill-hover-border-color;
        }
    }

    &.active {
        background-color: $btn-pill-active-bg;
        border-color: $btn-pill-active-border-color;
        color: $btn-pill-active-color !important;
    }
}

.btn-pill {
    @include btn-pill;
}

@mixin btn-mobile-nav-tab {
    @extend %base-button-styles;
    padding: $btn-mobile-nav-tab-padding;
    background-color: $btn-mobile-nav-tab-bg;
    border-bottom: 3px solid $btn-mobile-nav-tab-border-color;
    border-radius: 0;

    p {
        @include body-small;
        color: $color-shade-2;
    }

    &:not(.btn-disabled) {
        &:hover,
        &:focus {
            border-color: $btn-mobile-nav-tab-hover-border-color;

            p {
                color: $color-black;
            }
        }
    }

    &.active {
        border-color: $btn-mobile-nav-tab-hover-border-color;
        transition: all 0.2s ease-in-out;

        p {
            color: $color-black;
        }
    }
}

@mixin btn-tag {
    @extend %base-button-styles;
    @include body-small;
    color: $btn-tag-color;
    padding: $btn-tag-padding;
    border: 1px solid $btn-tag-color;

    &:hover,
    &:focus {
        color: $btn-tag-hover-color;
        border-color: $btn-tag-hover-color;
        background-color: $btn-tag-hover-bg;
    }
}

.btn-tag {
    @include btn-tag;
}

/*
 * Buttons with Icons
 */
.btn-icon {
    position: relative;
    text-decoration: none;

    svg {
        fill: $btn-icon-color;
        width: $btn-icon-size;
    }

    &.btn-large {
        svg {
            width: $base-spacing-5;
            height: $base-spacing-5;
        }
    }

    &.btn-prefix {
        svg {
            margin-left: 0;
            margin-right: $base-spacing-1;
        }
    }
}

/*
 * Buttons ghost
 */

.btn-skin-ghost {
    @extend %base-button-styles;
    color: $color-primary-1;
    padding-left: 0;
    padding-right: 0;
    transition: width 0.7s;
    border-radius: 0;

    &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        display: block;
        margin-top: 2px;
        right: 0;
        background: $btn-ghost-color;
        transition: width 0.3s ease;
        -webkit-transition: width 0.3s ease;
        border-radius: 3px;
        bottom: 0;
    }

    &:not(:disabled):not(.btn-disabled) {
        &:hover {
            &::after {
                width: 100%;
                left: 0;
                margin-left: 0;
            }
        }
    }

    &:active,
    &:focus {
        color: $color-primary-2;

        &::after {
            width: 100%;
            left: 0;
            background: $color-primary-2;
        }
    }

    &:disabled {
        color: $color-disabled;
    }

    &.reverse {
        color: $color-white;

        &::after {
            background: $color-primary-2;
        }

        &:not(:disabled):not(.btn-disabled) {
            &:hover {
                color: $color-primary-2;
            }

            &:active,
            &:focus {
                color: $color-primary-2;
            }
        }
    }

    &:disabled,
    &.btn-disabled {
        color: $color-disabled;
    }
}
