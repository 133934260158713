/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }

    @return $number;
}

@function parseint($n) {
    @return $n / ($n * 0 + 1);
}

// Optimised Encoded SVGs
// =====================================================================
// Taken from: http://codepen.io/jakob-e/pen/doMoML
// Further reading: https://codepen.io/tigt/post/optimizing-svgs-in-data-uris
// =====================================================================

//  Function to replace characters in a string
@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

//  Function to create an optimized svg url
//  (may need a few extra replacements)
@function svg-url($svg, $color) {

    // Remove invalid utf-8 param generated by sass-inline-image function
    $svg: str-replace($svg, ';utf8');

    // Chunk up string in order to avoid
    // "SystemStackError: stack level too deep"
    $encoded:'';
    $slice: 2000;
    $index: 0;
    $loops: ceil(str-length($svg) / $slice);

    @for $i from 1 through $loops {
        $chunk: str-slice($svg, $index, $index + $slice - 1);
        $chunk: str-replace($chunk, '"', '\'');
        $chunk: str-replace($chunk, '<', '%3C');
        $chunk: str-replace($chunk, '>', '%3E');
        $chunk: str-replace($chunk, '&', '%26');
        $chunk: str-replace($chunk, 'currentColor', $color);
        $chunk: str-replace($chunk, '#', '%23');
        $encoded: #{$encoded}#{$chunk};
        $index: $index + $slice;
    }

    @return url("#{$encoded}");
}
