// Breadcrumbs
//----------------------------------------
$breadcrumb-margin:                 $base-spacing-1;

$breadcrumb-separator-content:      '/'; // can also use '>', or '|'
$breadcrumb-separator-color:        $color-shade-3;

$breadcrumb-link-color:             $color-primary-1;
$breadcrumb-current-color:          $color-black;

.c-breadcrumbs {
    display: flex;
    align-items: center;

    a,
    span {
        @include body-small;
        text-decoration: none;

        &:first-child {
            margin-left: 0;
        }
    }

    .breadcrumb-separator {
        fill: $color-primary-2;
    }

    .CMSBreadCrumbsLink {
        color: $breadcrumb-link-color;
        text-decoration-color: $breadcrumb-link-color;

        &:hover,
        &:focus {
            color: $color-primary-2;
            text-decoration: underline;
        }
    }

    .CMSBreadCrumbsCurrentItem {
        color: $breadcrumb-current-color;
    }
}

