$cta-height-mobile:     312px;
$cta-height-desktop:    456px;

section.s-three-col-cta {
    padding: 0;
    background-color: $color-primary-1;

    &.padding-top {
        .container {
            padding-top: $base-spacing-4;
        }
    }

    &.padding-bottom {
        .container {
            padding-bottom: $base-spacing-4;
        }
    }

    .container {
        padding: 0;

        > div,
        > a {
            display: flex;
            align-items: center;
            justify-content: center;
            height: $cta-height-mobile;
            background-color: $color-primary-1;
            border-top: 2px solid $color-black;

            .cta-content {
                width: 240px;
            }
        }

        > div {
            h2 {
                color: $color-primary-3;
                width: 100%;
                max-width: 216px; //Set so that Help You wrap onto a new line.

                span {
                    color: $color-white;
                    border-bottom: 2px solid $color-white;
                }
            }
        }

        > a {
            text-decoration: none;

            h2,
            p {
                color: $color-white;
            }

            h2 {
                margin-bottom: $base-spacing-1;
            }

            svg {
                width: $base-spacing-6;
                height: $base-spacing-6;
                margin-bottom: $base-spacing-6;
            }

            &:hover,
            &:focus {
                background-color: $color-primary-shade-6;
            }

            &:first-of-type {
                svg {
                    fill: $color-lifestage-plan;
                }
            }

            &:last-of-type {
                border-bottom: 2px solid $color-black;

                svg {
                    margin-left: -$base-spacing-1;
                    fill: $color-lifestage-build;
                }
            }
        }
    }
}

@media (min-width: $screen-sm) {
    section.s-three-col-cta {
        &.padding-top {
            .container {
                padding-top: $base-spacing-8;
            }
        }

        &.padding-bottom {
            .container {
                padding-bottom: $base-spacing-8;
            }
        }

        .container {
            display: flex;

            > div,
            > a {
                width: 33.33%;
                padding: 0 $base-spacing-3;
                border-bottom: 1px solid $color-black;
            }

            > div {
                border-top: $base-spacing-2 solid $color-lifestage-retire;
            }

            > a {
                border-left: 1px solid $color-black;

                .cta-content {
                    min-height: 212px; // Need this so that the Icon, Headings, and P tags line up across two separate columns. Otherwise they are slightly off because of the vertical centering. Might need to change if the content does.
                }

                &:first-of-type {
                    border-top: $base-spacing-2 solid $color-lifestage-plan;
                }

                &:last-of-type {
                    border-top: $base-spacing-2 solid $color-lifestage-build;
                    border-bottom-width: 1px;
                }
            }
        }
    }
}

@media (min-width: $screen-sm-max) {
    section.s-three-col-cta {
        .container {
            > div {
                h2 {
                    @include display-medium;
                    color: $color-primary-3;
                    text-transform: none;
                }
            }
        }
    }
}

@media (min-width: $screen-md) {
    section.s-three-col-cta {
        .container {
            > div,
            > a {
                height: $cta-height-desktop;
            }
        }
    }
}


@media (min-width: $screen-lg) {
    section.s-three-col-cta {
        .container {
            > div,
            > a {
                border-left: 1px solid $color-black;
            }

            > a:last-of-type {
                border-right: 1px solid $color-black;
            }
        }
    }
}
