// Tables
$table-heading-bg: $color-primary-2;
$table-heading-color: $color-primary-1;

$table-row-bg: $color-white;
$table-row-alt-bg: $color-white;
$table-row-hover-bg: rgba($color-primary-2, 0.06);

$table-cell-padding: 12px $base-spacing-2;
$table-cell-color: $color-shade-3;

.table-wrapper {
    margin-bottom: $base-spacing-3;
    position: relative;

    .table-content {
        overflow-x: auto;
    }

    .scroll {
        overflow: hidden;
        overflow-x: auto;
    }

    .pinned {
        position: absolute;
        left: 0;
        top: 0;
        overflow: hidden;
        z-index: 2;
    }

    .table-responsive &,
    .flip-scroll & {
        overflow-x: hidden;
    }
}

table {
    width: 100%;
    border-collapse: collapse;

    th {
        padding: $table-cell-padding;
        background-color: $table-heading-bg;
        color: $table-heading-color;
        text-align: left;
        border-left: 1px solid $color-white;

        &:first-child {
            border-left: 0 none;
        }
    }

    tr {
        background-color: $table-row-bg;
        border: none;
        border-bottom: 1px solid $table-heading-bg;

        &:nth-child(even) {
            background-color: $table-row-alt-bg;
        }

        &:hover {
            background-color: $table-row-hover-bg;
        }
    }

    td {
        padding: $table-cell-padding;
        color: $table-cell-color;
        min-width: 200px;
        vertical-align: top;
    }
}

@media (min-width: $screen-sm) {
    .table-wrapper {
        table {
            &.equal-coloumn {
                table-layout: fixed;
            }
        }
    }
}

@media (max-width: $screen-sm) {
    .flip-scroll {
        table {
            display: flex;

            thead {
                background-color: $table-heading-bg;
                flex: 0 0 20%;
            }

            tbody {
                display: flex;
                width: 100%;
                position: relative;
                overflow-x: auto;
                white-space: nowrap;

                tr {
                    vertical-align: top;
                    // width: 100%;
                }
            }

            th,
            td {
                display: block;
                text-align: left !important;
            }

            th {
                border-left: 0 none;
            }

            td {
                min-height: 25px;
            }
        }
    }
}

@media (max-width: $screen-xs-max) {
    .table-wrapper {
        &::after {
            content: "";
            height: 100%;
            position: absolute;
            width: 50px;
            background: linear-gradient(90deg, transparent 0%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0.1) 100%);
            top: 0;
            right: 0;
        }
    }
}
