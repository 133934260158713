$checkbox-size:                     $base-spacing-3;
$checkbox-icon:                     'check';
$checkbox-icon-color:               $color-success;
$checkbox-icon-size:                $base-spacing-3;
$checkbox-checked-bg:               transparent;
$checkbox-checked-border:           $color-success;
$checkbox-focus-border:             $color-black;
$checkbox-label-padding:            $checkbox-size + $base-spacing-2;
$checkbox-label-padding-rich-text:  $checkbox-label-padding + $base-spacing-2;
$checkbox-label-height:             $base-spacing-6;

$radio-border-radius:               50%;

@mixin rich-checkbox-radio {
    margin-bottom: $form-layout-desktop-margin-bottom;
    padding: $base-spacing-3;
    padding-left: $checkbox-label-padding-rich-text;
    border: 1px solid $input-border-color;
    border-radius: 4px;

    > *:last-child {
        margin-bottom: 0;
    }

    &::before {
        left: $base-spacing-2;
        top: $base-spacing-3 + $base-spacing-1 / 2;
    }
}

@mixin rich-checked-checkbox {
    border-color: $color-success;
    box-shadow: 0 5px 11px rgba($color-success, 0.2);

    &::after {
        top: $base-spacing-3 + $base-spacing-1 / 2;
        left: $base-spacing-2;
    }
}

.checkbox-wrapper input[type="checkbox"],
.checkboxlist-wrapper input[type="checkbox"],
.radiobutton-wrapper input[type="radio"],
.form-field .ktc-checkbox input[type="checkbox"],
.form-field .ktc-checkbox input[type="checkbox"] + input,
.form-field .ktc-radio input[type="radio"] {
    position: absolute;
    width: $checkbox-size;
    height: $checkbox-size;
    z-index: -1;
    margin: 0;
    opacity: 0;
    cursor: pointer;

    + label {
        position: relative;
        display: block;
        padding-left: $checkbox-label-padding;
        min-height: $checkbox-size;
        line-height: $checkbox-size;
        margin-bottom: $label-margin-bottom;
        cursor: pointer;
        font-weight: normal;

        &.rich-checkbox-radio {
            @include rich-checkbox-radio;
        }
    }

    + label::before {
        content: "";
        width: $checkbox-size;
        height: $checkbox-size;
        box-sizing: border-box;
        position: absolute;
        display: inline-block;
        left: 0;
        border: 1px solid $input-border-color;
        background-color: transparent;
        border-radius: $input-radius;
        transition:
            border-color $input-transition-speed $input-transition-curve,
            background-color $input-transition-speed $input-transition-curve;
    }

    &:focus + label::before {
        border-color: $checkbox-checked-border;
    }

    &:checked + label::before {
        border-color: $checkbox-checked-border;
        background-color: $checkbox-checked-bg;
    }

    // Remove Kentico rendered BR tags
    + label + br {
        display: none;
    }
}

.checkbox-wrapper input[type="checkbox"],
.checkboxlist-wrapper input[type="checkbox"],
.form-field .ktc-checkbox input[type="checkbox"] {
    &:checked + label,
    &:checked + input + label {
        position: relative;

        @include bg-icon($checkbox-icon, after, $color-success) {
            position: absolute;
            top: 0;
            left: 0;
            width: $checkbox-icon-size;
            height: $checkbox-icon-size;
            content: "";
        }

        &.rich-checkbox-radio {
            @include rich-checked-checkbox;
        }
    }
}

.checkbox-wrapper .checkbox-item:last-of-type input + label {
    margin-bottom: 0;
}

.radiobutton-wrapper input[type="radio"],
.form-field .ktc-radio input[type="radio"] {
    + label::before {
        border-radius: $radio-border-radius;
    }

    &:checked + label {
        &::after {
            display: block;
            content: '';
            position: absolute;
            top: 6px;
            left: 6px;
            width: 12px;
            height: 12px;
            background-color: $color-success;
            border-radius: $radio-border-radius;
        }

        &.rich-checkbox-radio {
            border-color: $color-success;
            box-shadow: 0 5px 11px rgba($color-success, 0.2);

            &::after {
                top: $base-spacing-3 + 10px;
                left: $base-spacing-2 + 6px;
            }
        }
    }
}

.radiobutton-wrapper.layout-horizontal .input-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    label {
        margin-right: $base-spacing-3;
    }
}

.checkbox-item,
.radio-item {
    position: relative;

    &:last-of-type {
        input[type="radio"] + label {
            margin-bottom: 0;
        }
    }
}

// Disabled states (because Kentico outputs terrible HTML)
input[type="checkbox"]:disabled + label,
input[type="radio"]:disabled + label {
    cursor: default !important;

    &::before {
        opacity: $input-disabled-opacity;
    }
}

span.checkbox.aspNetDisabled,
span.radio.aspNetDisabled {
    > br {
        display: none;
    }

    span.aspNetDisabled {
        display: block;
        margin-bottom: $label-margin-bottom;
    }
}

// Dancing Goat Form {
.field-validation-error + .ktc-radio-list {
    margin-top: $base-spacing-2;
}

.form-field .ktc-radio-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .ktc-radio {
        margin-right: $base-spacing-4;

        input {
            max-width: $base-spacing-4;
        }

        label {
            margin: 0;
        }
    }

    &.margin-top {
        margin-top: $base-spacing-4;
    }
}

.form-field {
    .ktc-checkbox {
        width: 100%;

        input[type="checkbox"] {
            + input + label {
                @include rich-checkbox-radio;
                margin-bottom: 0;
                margin-top: $base-spacing-2;

                &.has-error {
                    border-color: $color-error;
                }
            }

            &:checked + input + label {
                @include rich-checked-checkbox;
            }
        }
    }

    &.has-error {
        .ktc-checkbox {
            label.error-msg {
                position: relative;
                top: 0;
            }
        }
    }
}

.form-member-join,
.form-custom {
    .radiobutton-wrapper input[type="radio"][data-toggle-val="token"],
    .form-field .ktc-radio input[type="radio"][data-toggle-val="token"] {
        + label {
            background-color: $color-white;
            border: 1px solid $input-border-color;
            color: $color-primary-2;
            display: inline-block;
            font-weight: bold;
            justify-content: center;
            min-height: $checkbox-label-height;
            min-width: 100px;
            margin-bottom: $base-spacing-2;
            padding: 0 $base-spacing-3;
            line-height: $checkbox-label-height - 2;
            text-align: center;
            border-radius: 30px;
            width: 100%;

            &::before {
                content: none;
                // border-radius: 30px;
                // height: 100%;
                // width: 100%;
            }

            > p,
            > h5 {
                display: block;
                margin-bottom: 0;
                width: 100%;
            }
        }

        &:checked + label {
            background-color: $checkbox-checked-border;
            border-color: $checkbox-checked-border;
            color: $color-white;

            // &::before {
            //     border-color: $checkbox-checked-border;
            //     background-color: transparent;
            // }

            // &::after {
            //     border-color: $checkbox-checked-border;
            //     border-radius: 30px;
            //     // background-color: $checkbox-checked-border;
            //     height: 100%;
            //     left: 0;
            //     top: 0;
            //     width: 100%;
            //     z-index: -1;
            //     margin: 0;
            //     padding: 0;
            //     position: absolute;
            // }
        }
    }

    .form-field .ktc-radio-list .ktc-radio {
        margin-right: $base-spacing-2;
    }
}
