.s-text-block {
    .s-text-block-content {
        @include vertical-rhythm;
    }
}

@media (min-width: $screen-sm) {
    .s-text-block {
        .s-text-block-content {
            // @include col(10);
        }
    }
}

@media (min-width: $screen-md) {
    .s-text-block {
        .s-text-block-content {
            // @include col(7);
        }
    }
}
