.s-related-items {
    h2 {
        @include display-medium;
        text-align: center;
        margin-bottom: $base-spacing-8;
        text-transform: none;
    }

    .c-related-items-group {
        margin-top: $base-spacing-8;

        h3 {
            @include display-heading-medium;
            margin-bottom: $base-spacing-4;
        }

        > div {
            border-top: 1px solid $color-shade-2;

            &:last-of-type {
                border-bottom: 1px solid $color-shade-2;
            }
        }

        &:first-of-type {
            margin-top: 0;
        }
    }
}

@media (min-width: $screen-sm) {
    .s-related-items {
        .grid-wrapper {
            @include col(12);
        }
    }
}
