.s-chart-block {
    .container {
        .chart-heading,
        .chart-intro {
            margin-bottom: $base-spacing-3;
        }

        .chart-intro,
        .chart-footer {
            @include vertical-rhythm;
        }

        .chart-wrapper {
            .table-wrapper {
                margin-bottom: 0;
            }
        }

        .chart-footer {
            margin-top: $base-spacing-3;

            p {
                @include body-small;
                margin-bottom: 0;
            }
        }
    }
}

.s-left-header-text-box + .s-chart-block,
.s-left-header-text-box + a + .s-chart-block,
.s-chart-block + .s-chart-block,
.s-chart-block + a + .s-chart-block {
    .grid-wrapper {
        position: relative;

        &::before {
            content: '';
            height: 1px;
            background-color: $color-primary-shade-2;
            position: absolute;
            top: -$base-section-padding;
            left: 0;
            right: 0;
        }
    }
}

@media (min-width: $screen-sm) {
    .s-chart-block {
        .container {
            .chart-heading {
                margin-bottom: $base-spacing-4;
            }

            .chart-intro {
                margin-bottom: $base-spacing-6;
            }
        }
    }

    .s-left-header-text-box + .s-chart-block,
    .s-left-header-text-blox + a + .s-chart-block,
    .s-chart-block + .s-chart-block,
    .s-chart-block + a + .s-chart-block {
        .grid-wrapper {
            &::before {
                top: -$base-section-desktop-padding;
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .s-chart-block {
        .grid-wrapper {
            @include col(12);
        }
    }
}

@media (min-width: $screen-md) {
    .s-chart-block {
        .heading-wrapper {
            @include col(4);
        }
    }
}

//! Debug code, this should be removed when charts are in.
.placeholder-chart {
    width: 100%;
    height: 300px;
    color: $color-white;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-accent-3;

    h1 {
        color: $color-white;
    }
}
