$stage-mobile-image-height:         90px;
$stage-mobile-top-padding:          $stage-mobile-image-height + $base-spacing-8;

.s-how-super-works-diagram {
    padding-top: $base-spacing-10;
    padding-bottom: $base-spacing-10;
    @include bg-theme-1;

    .timeline-desktop {
        display: none;
    }

    .stage-content {
        @include stack();
        padding: $stage-mobile-top-padding $base-spacing-6 $base-spacing-8;
        text-align: center;
        background-repeat: no-repeat;
        background-size: 767px auto;

        img {
            width: $base-spacing-12;
            height: $base-spacing-12;
        }

        h3,
        img {
            margin-bottom: $base-spacing-3;
        }

        p {
            margin-bottom: 0;
        }

        &:first-of-type {
            background-position: top left;
        }

        &:nth-of-type(2) {
            background-position: top center;
        }

        &:last-of-type {
            background-position: top right;
        }
    }
}

.s-landing-intro + .s-how-super-works-diagram,
.s-landing-intro + a + .s-how-super-works-diagram {
    border-top: 1px solid $color-white;
}

@media (max-width: $screen-xs-max) {
    .s-how-super-works-diagram {
        .stage-content:last-of-type {
            padding-bottom: $base-spacing-2;
        }
    }
}

@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    .s-how-super-works-diagram {
        .container-flex {
            .stage-wrapper .stage-content {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .s-how-super-works-diagram {
        .container-flex {
            @include container();

            .timeline-desktop {
                display: block;
                max-height: 115px;
            }

            .stage-wrapper {
                display: grid;
                grid-gap: $base-spacing-3;
                grid-template-columns: 1fr 1fr 1fr;
                @include ie-grid(1fr 1fr 1fr);

                .stage-content {
                    padding-top: $base-spacing-3;
                    padding-bottom: 0;
                    background-image: none !important;
                }
            }
        }
    }
}
