.c-mobile-nav-tabs {
    .tabs-heading {
        ul {
            display: flex;
            justify-content: center;
            flex-wrap: unset;
            padding: 0 $base-spacing-3;

            li {
                @include btn-mobile-nav-tab;
                margin-right: $base-spacing-3;

                p {
                    margin: 0;
                }
            }

            li:last-child {
                margin-right: 0;
                flex-shrink: 2;
            }
        }
    }
}

.c-mobile-nav-tab-content {
    background-color: $color-primary-shade-1;
    flex-grow: 1;
}
