$mobile-search-height: 56px;

.c-mobile-search {
    label {
        @include visually-hidden;
    }

    .form-horizontal {
        display: flex;
        width: 100%;
    }

    input {
        border: none;
        margin-bottom: 0;
        height: $mobile-search-height;

        &:focus {
            border-color: rgba($color-primary-1, 0.3);
        }
    }

    .form-group {
        width: 100%;
    }

    .form-group-submit {
        width: 72px;
        height: $mobile-search-height;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-right: $base-spacing-1;
        cursor: pointer;

        &.has-focus {
            border: 1px solid rgba($color-primary-1, 0.3);
        }

        input {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            padding: 0;
        }

        @include bg-icon('search', after, $color-black) {
            position: relative;
            width: 24px;
            height: 24px;
            content: "";
        }
    }
}
