.c-biography-tile-modal {
    img {
        width: 100%;
        object-fit: cover;
    }

    .biography-modal-content {
        .biography-modal-name {
            @include font-size($display-heading-small-type);
            font-weight: 800;
            color: $color-primary-1;
            margin-top: $base-spacing-3;
        }

        .biography-modal-hastag {
            @include font-size($display-heading-small-type);
            color: $color-primary-1;
            margin-bottom: $base-spacing-3;
        }
    }
}

@media (min-width: $screen-sm) {
    .c-biography-tile-modal {
        .biography-modal-content {
            .biography-modal-name {
                @include font-size($large-type);
                margin-top: unset;
            }

            .biography-modal-hastag {
                @include font-size($large-type);
                margin-bottom: $base-spacing-4;
            }
        }
    }
}
