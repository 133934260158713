.c-video-embed {
    width: 100%;

    &.medium-width {
        max-width: 990px;
        margin: 0 auto;
    }

    .frame-wrapper {
        @include placeholder-ratio-wrapper('16x9');
    }

    iframe,
    object,
    embed {
        @include placeholder-image-item;
    }
}
