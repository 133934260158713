.c-unit-price-summary-table {
    h3 {
        margin-bottom: $base-spacing-5;
    }

    table {
        th {
            text-align: left;
        }
    }
}
