.s-col-biographies {
    .title {
        text-align: center;
        margin-bottom: $base-spacing-6;
    }

    .container .row {
        .c-biography-tile {
            @include stack();
            margin-bottom: $base-spacing-3;
        }
    }
}

@media (min-width: $screen-sm) {
    .s-col-biographies {
        .container .row {
            .c-biography-tile {
                @include col(6);
                margin-bottom: $base-spacing-8;
            }

            .biography-tile-container {
                .title {
                    text-align: left;
                }
            }
        }
    }
}

@media (min-width: $screen-md) {
    .s-col-biographies {
        .container .row {
            .c-biography-tile {
                @include col(3);
            }
        }
    }
}

