// Unit Price Chart
//----------------------------------------
$unit-price-box-shadow:     0 0 6px 0 rgba(0, 0, 0, 0.1);
// $unit-price-latest-padding:                 $base-spacing-2 $base-spacing-2 $base-spacing-1 $base-spacing-2;

$unit-price-current-date-color:             $color-black;
$unit-price-current-value-color:            $color-primary-2;

$unit-price-more-padding:                   $base-spacing-1 0 $base-spacing-1 $base-spacing-2;

.c-unit-price {
    box-shadow: $unit-price-box-shadow;
    width: 100%;
    padding: $base-spacing-3 $base-spacing-2;

    .latest {
        .current-date {
            color: $unit-price-current-date-color;
            @include body-small;
            display: block;
            margin-bottom: $base-spacing-1;
        }

        .current-value {
            display: block;
            @include display-large;
            color: $unit-price-current-value-color;
            margin-bottom: $base-spacing-3;
        }
    }

    .current-percentage {
        display: flex;
        border-top: 1px solid $color-shade-1;
        padding-top: $base-spacing-2;

        div {
            border-left: 1px solid $color-shade-1;
            padding-left: $base-spacing-4;
            width: 100%;

            &:first-child {
                border: 0 none;
                padding-left: 0;
            }
        }

        span {
            display: block;
            @include body-small;
            color: $color-shade-3;

            &:first-child {
                color: $unit-price-current-date-color;
            }
        }
    }

    .more {
        margin-top: $base-spacing-4;
        @include body-normal-heavy;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        svg {
            margin-left: $base-spacing-1;
            flex-shrink: 0;
        }
    }
}
