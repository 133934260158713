$select-icon:                       'caret-down';
$select-icon-color:                 $color-shade-3;
$select-icon-width:                 $base-spacing-3;
$select-icon-padding:               $base-spacing-6;

.select-wrapper,
.form-field {
    select,
    .select {
        appearance: none;
        @include base-input-styles;
        padding-right: $select-icon-padding;

        &:focus {
            outline: none;
            border-color: $input-border-color-focus;
        }

        &:disabled {
            opacity: $input-disabled-opacity;
        }

        option {
            color: $color-black;
        }
    }
}

.select-wrapper .input-wrapper,
.select-wrapper .EditingFormValueCell > div,
.form-field .input-wrapper {
    position: relative;

    @include bg-icon($select-icon, after, $select-icon-color) {
        position: absolute;
        top: $base-spacing-2;
        right: $base-spacing-2;
        width: $select-icon-width;
        height: $select-icon-width;
        content: "";
        text-align: center;
        fill: $select-icon-color;
    }
}

.select-wrapper.select-disabled .EditingFormValueCell > div::after {
    opacity: $input-disabled-opacity;
}

select::-ms-expand {
    display: none;
}

select {
    -webkit-appearance: none;
    appearance: none;

    &[readonly],
    &[readonly="readonly"],
    &[readonly="readonly"]:focus {
        border-color: $color-disabled;
        cursor: no-drop;
        opacity: $input-disabled-opacity;

        option {
            display: none;
        }
    }
}

// Dancing Goat Form
@media (max-width: $screen-md) {
    .form-field .input-wrapper {
        margin-top: $base-spacing-2;
    }

    .form-member-join,
    .form-custom {
        .form-row > div:first-child {
            .has-error .input-wrapper {
                margin-top: 0;
            }
        }

        .has-error .input-wrapper {
            margin-top: $base-spacing-4;
        }
    }
}
