// Accordions
//----------------------------------------
$accordion-icon-color:              $color-primary-2;
$accordion-icon-size:               $base-spacing-6;
$accordion-border-color:            $color-shade-1;

$accordion-heading-padding:         $base-spacing-4 $base-spacing-3;
$accordion-heading-color:           $color-black;
$accordion-heading-bg:              $color-white;
$accordion-heading-hover-bg:        $color-shade-1;

$accordion-item-open-margin-bottom: $base-spacing-3;

$accordion-content-padding:         $base-spacing-3;
$accordion-content-bg:              $color-white;
$accordion-content-transition:      all 200ms cubic-bezier(0.17, 0.04, 0.03, 0.94);

.c-accordion {
    border-bottom: 1px solid $color-primary-shade-2;
    border-top: 1px solid $color-primary-shade-2;

    .accordion-item {
        margin-bottom: 0;
        transition: margin-bottom 0.4s ease;

        .accordion-heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: $accordion-heading-padding;
            border-top: 1px solid $color-primary-shade-2;

            .accordion-title {
                @include display-heading-medium;
                white-space: break-spaces;
                text-transform: none;
                color: $accordion-heading-color;
                margin-bottom: 0;
                padding-right: $base-spacing-1;
            }

            .svg-icon {
                flex-shrink: 0;
                height: $accordion-icon-size;
                width: $accordion-icon-size;
                margin: -$base-spacing-1;
                margin-right: 0;
                padding: $base-spacing-1;
                color: $accordion-icon-color;
                transition: $accordion-content-transition;
                background-color: $color-white;
                border-radius: 50%;
                border: 2px $color-primary-4 solid;
            }

            &:hover,
            &:focus {
                cursor: pointer;

                .svg-icon {
                    color: $color-white;
                    background-color: $color-primary-4;
                }
            }
        }

        .accordion-content {
            display: none;
            padding: $accordion-content-padding;
            width: 100%;
            word-break: break-word;

            .table-wrapper {
                margin-left: -$accordion-content-padding;
                margin-right: -$accordion-content-padding;
            }
        }

        &.open {
            .accordion-heading {
                .svg-icon {
                    transform: rotateZ(180deg);
                    background-color: $color-primary-4;
                    color: $color-white;
                }
            }
        }

        &.with-background-fill {
            .accordion-content {
                background: $color-parchment-shade-1;
            }
        }
    }
}
