.c-superannuation-product-dashboard {
    width: 100%;

    .superannuation-product-legend {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 0 $base-spacing-1;
    }

    .superannuation-product-legend-item {
        display: flex;
        align-items: center;
        margin-right: $base-spacing-2;

        span {
            margin-left: $base-spacing-1;
        }
    }
}

// IE 11 Fix
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .c-superannuation-product-dashboard {
        .superannuation-product-legend + div {
            height: 480px !important;
        }
    }
}
