.s-campaign-form {
    h2 {
        display: block;
        margin-bottom: $base-spacing-4;
    }

    .content-row {
        .ktc-default-section {
            .form-field {
                margin-top: $base-spacing-2;

                .input-wrapper {
                    margin-top: 0;
                }
            }
        }
    }

    .form-field .editing-form-control-nested-control {
        display: block;
    }
}

@media (min-width: $screen-md) {
    .s-campaign-form {
        form {
            align-items: flex-start;
            display: flex;
            height: 90px;
        }

        .content-row {
            @include col(10);
            margin: auto;

            .ktc-default-section {
                align-items: flex-start;
                display: flex;

                .form-field {
                    margin-left: $base-spacing-2;
                    margin-top: 0;
                }
            }

            input[type="submit"] {
                margin-left: $base-spacing-2;
                margin-top: 0;
                max-width: 250px;
                min-height: 56px;
            }
        }
    }
}

// @media (min-width: $screen-md) {
//     .s-campaign-form {
//         .content-row {
//             @include col(10);
//             margin: auto;
//         }
//     }
// }
