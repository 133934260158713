// Convert pixels into rems
// $baseline is set as 16px by default in scss/core/typography/_base-type.scss
@function calculate-rem($size) {
    @return ($size / $baseline) * 1rem;
}

// Convert pixels into unitless value
@mixin line-height($line-height: $base-line-height, $font-size: $base-font-size) {
    line-height: strip-unit($line-height / $font-size);
}

// convert pixels into rem for fonts
// only provides px fallback if legacy-ie is set to true;
@mixin create-font-size($size) {
    font-size: calculate-rem($size);
}

// Utility function for mixin font-size
@mixin make-font-size($flexi-type) {
    // If $flexi-type is a list,
    // include font-size, line-height (optional) and margin-bottom (optional)
    @if type-of($flexi-type) == "list" {
        @include create-font-size(nth($flexi-type, 1));

        @if (length($flexi-type) > 1) {
            line-height: (nth($flexi-type, 2) / nth($flexi-type, 1));
        }

        @if (length($flexi-type) > 2) {
            margin-bottom: nth($flexi-type, 3);
        }
    }
    @else {
        @include create-font-size($flexi-type);
    }
}

// Output sass mapped type variables into responsive typography
// https://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
@mixin font-size($fs-map, $fs-breakpoints: $type-breakpoints) {
    // for responsive typography.
    @each $fs-breakpoint, $fs-type in $fs-map {
        @if $fs-breakpoint == null {
            @include make-font-size($fs-type);
        }
        @else {
            // If $fs-type is a key that exists in
            // $fs-breakpoints, use the value
            @if map-has-key($fs-breakpoints, $fs-breakpoint) {
                $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
            }
            @media screen and (min-width: $fs-breakpoint) {
                @include make-font-size($fs-type);
            }
        }
    }
}
