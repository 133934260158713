.c-anchor {
    @include display-heading-extra-small;
    color: $color-white;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $base-spacing-2 $base-spacing-2;
    text-decoration: none;
    height: 64px;

    &:hover {
        color: $color-white;
    }

    &.active,
    &:active,
    &:focus {
        color: $color-primary-2;
    }

    .anchor-icon {
        color: $color-white;
    }

    &.active .anchor-icon,
    &:active .anchor-icon,
    &:focus .anchor-icon {
        color: $color-primary-2;
    }

    .icon-hover-line {
        display: none;
        color: $color-primary-2;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 120px;
    }

    &.active .icon-hover-line,
    &:active .icon-hover-line,
    &:focus .icon-hover-line,
    &:hover .icon-hover-line {
        display: block;
    }

    .icon-wrapper {
        flex-shrink: 0;
        margin-right: 6px;
        width: 100;
        max-width: $base-spacing-3;
        height: $base-spacing-3;
    }
}

@media (min-width: $screen-md) {
    .c-anchor {
        @include display-heading-small;
        color: $color-white;
        padding: $base-spacing-3 20px;
        height: 80px;

        .icon-wrapper {
            margin-right: 12px;
        }

        .icon-hover-line {
            bottom: $base-spacing-1;
            width: 128px;
        }
    }
}
