$slider-transition:                 opacity 500ms linear;

// Slider
//----------------------------------------
.c-slider {
    .slider-container {
        position: relative;
        padding-bottom: $base-spacing-5;
    }

    .slick-initialized {
        .slick-slide {
            display: block;
        }
    }

    // Needed for placeholder image ratios to work.
    .slick-slide {
        float: left;
        height: 100%;
        min-height: 1px;
        padding: 0;
        transition: $slider-transition !important; // override fade transition timing
        z-index: 0 !important; // override inline z-index of 998

        &.slick-active {
            z-index: 1 !important; // override inline z-index of 999
        }
    }

    .slick-slider {
        overflow: hidden;
    }

    .slider-image-container {
        @include placeholder-ratio-wrapper('16x9');

        img {
            @include placeholder-image-item;
        }
    }

    .slider-container .slick-slider .slick-list .slick-track > * {
        margin: 0 $base-spacing-2;
    }
}

@import "slider-dots";
@import "slider-arrows";
