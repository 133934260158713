@import "risk/c-risk-chart";
@import "unit-price-old/c-unit-price";
@import "investment-returns/c-investment-returns";
@import "asset-allocation/c-asset-allocation";
@import "annual-returns/c-annual-returns";
@import "investment-performance-summary/c-investment-performance-summary";
@import "investment-summary-table/c-investment-summary-table";
@import "investment-summary-chart/c-investment-summary-chart";
@import "unit-price-summary-table/c-unit-price-summary-table";
@import "unit-price-summary-table-old/c-unit-price-summary-table";
@import "unit-price/c-unit-price";
@import "unit-price-table/c-unit-price-table";
@import "superannuation-product-dashboard/c-superannuation-product-dashboard";
@import "portfolio-holdings/c-portfolio-holdings-table";
@import "market-commentary/c-market-commentary";

.c-react-chart {
    .c-loader {
        h5 {
            color: $color-primary-1;
        }
    }
}

.chart-api-error {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    svg {
        width: $base-spacing-7;
        height: $base-spacing-7;
        color: $color-error;
    }

    h5 {
        color: $color-error;
    }
}
