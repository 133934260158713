$banner-mobile-height: 203px;
$banner-tablet-height: 504px;
$banner-desktop-height: 600px;

section.s-banner-statement {
    padding-bottom: 0;

    .background-image {
        background-size: 0 0, 0 0, cover, cover;
        background-position: center, right;
        background-repeat: no-repeat;
        background-color: $color-primary-3;
        height: $banner-mobile-height;
        display: flex;
        position: relative;
        overflow: hidden;

        .image-element {
            object-fit: cover;
            object-position: center right;
            position: relative;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 100vw;
            height: 100%;
        }

        .small-content {
            display: block;
            height: 100%;
        }
    }

    .container {
        margin-bottom: $base-spacing-4;
        position: relative;
    }

    .row {
        h1 {
            @include display-xx-small;
            word-break: break-word;

            div:last-child {
                margin-bottom: $base-spacing-3;
            }
        }

        h3 {
            @include display-small;
        }

        .btn-cta {
            width: 100%;
            text-align: center;
        }

        *:last-child {
            margin-bottom: 0;
        }

        .quick-link-item {
            flex-wrap: nowrap;
            padding: $base-spacing-3;

            h5 {
                margin-left: $base-spacing-2;
                text-align: left;
            }
        }
    }

    .subheading {
        img {
            display: none;

            &.mobile-subheading {
                display: block;
            }
        }
    }

    .banner-content {
        h1 {
            span {
                display: block;
            }
        }

        p {
            @include body-large;
            color: $color-primary-1;
            margin-bottom: 0;
            margin-top: $base-spacing-3;

            + a[class^="btn-"] {
                margin-top: $base-spacing-3;
            }
        }
    }

    .small-content {
        display: none;
    }

    a[class^="btn-"] {
        margin-top: $base-spacing-1;
    }

    .search {
        p {
            @include body-normal-light;
            color: $color-shade-3;
        }

        .search-bar {
            background-color: $color-parchment-shade-1;
            border-radius: $base-spacing-4;
            position: relative;
            height: $base-spacing-6;
            margin-top: $base-spacing-2;
            max-width: 300px;
        }

        .search-overlay,
        .search-inner label,
        .search-close {
            display: none;
        }

        .search-bar-content {
            background-color: $color-parchment-shade-1;
            display: block;
        }

        .search-container {
            padding: 0;
        }

        .search-inner {
            border: 0 none;
        }

        .search-input {
            @include body-normal-medium;
            background-color: $color-parchment-shade-1;
            color: $color-primary-1;
            padding-bottom: 0;
            padding-top: 0;
        }

        .search-icon {
            display: block;
            margin-right: $base-spacing-2;

            svg {
                color: $color-primary-1;
            }
        }
    }
}

@media (min-width: $screen-xxs) {
    section.s-banner-statement {
        .row {
            h1 {
                @include display-banner-large;
            }
        }
    }
}

@media (min-width: $screen-sm) {
    section.s-banner-statement {
        display: flex;
        align-items: center;
        min-height: $banner-tablet-height;
        padding: 0;
        overflow: hidden;
        position: relative;

        .background-image {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-size: cover, cover, 0 0, 0 0;
            z-index: -1;
            padding: 0;
            display: block;

            .image-element {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center right;
                top: 0;
                right: unset;
                transform: none;
                position: absolute;
            }

            .small-content {
                display: none;
            }
        }

        .small-content {
            display: block;
        }

        .container {
            margin-bottom: 0;
        }

        .row {
            padding: $base-spacing-6 * 2 $base-spacing-10;
            width: 100%;

            h1 {
                word-break: keep-all;
            }

            .btn-cta {
                width: auto;
            }

            .small-content {
                margin-top: $base-spacing-6;
            }

            .c-quick-links {
                margin-top: $base-spacing-4;
                flex-wrap: wrap;
                justify-content: flex-start;
            }

            .quick-link-item {
                border: 0 none;
                border-radius: 4px;
                margin-bottom: $base-spacing-1;
                margin-left: $base-spacing-1;
                width: calc(50% - #{$base-spacing-1});

                &:nth-child(2n-1) {
                    margin-left: 0;
                }
            }
        }

        .subheading {
            img {
                display: block;

                &.mobile-subheading {
                    display: none;
                }
            }
        }

        a[class^="btn-"] {
            margin-top: $base-spacing-3;
        }

        &.not-found {
            &::before {
                background-image: linear-gradient(90deg, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 0.5) 75%, rgba(255, 255, 255, 0) 100%);
                content: '';
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
    }
}

@media (min-width: $screen-md) {
    section.s-banner-statement {
        min-height: $banner-desktop-height;

        .row {
            flex-wrap: nowrap;
            margin-left: -$base-spacing-3;
            justify-content: space-between;

            .banner-content {
                @include col(6);

                &.has-links {
                    @include col(12);
                }
            }

            .small-content {
                @include col(5);
                @include col-offset(1);
                margin-top: 0;
                margin-right: 0;
            }

            .c-quick-links {
                flex-wrap: nowrap;
                justify-content: flex-start;
            }

            .quick-link-item {
                flex-wrap: wrap;
                min-width: $quick-link-size;

                &:nth-child(2n-1) {
                    margin-left: $base-spacing-1;
                }

                &:first-child {
                    margin-left: 0;
                }

                h5 {
                    margin-left: 0;
                    text-align: center;
                }
            }
        }

        &.not-found {
            .row {
                .banner-content {
                    @include col(12);
                }
            }
        }
    }
}

@media (min-width: $screen-md) {
    section.s-banner-statement {
        .quick-link-item {
            min-width: 100%;
            max-width: $quick-link-size;
        }
    }
}
