@import "./result-count/fl-result-count";
@import "./sort-by/fl-sort-by";

$toolbar-section-padding: $base-spacing-3 0;

.listing-toolbar {
    padding: $toolbar-section-padding;

    .toolbar-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
