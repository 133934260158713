section.s-anchor {
    padding: 0;
    overflow: hidden;
    position: sticky;
    top: 0;
    z-index: 3;

    .anchor-wrapper {
        background-color: $color-primary-1;
        display: flex;
        align-items: center;
        overflow-y: auto;
        padding: 0 $base-spacing-2;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    a {
        white-space: nowrap;
        width: 100%;

        &:last-child {
            border: 0 none;
        }
    }

    .next-button,
    .prev-button {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: $tab-next-button;
        padding-right: $base-spacing-2;
        z-index: 2;

        &:hover {
            &::after {
                content: none;
            }
        }

        &.hide {
            display: none;
        }
    }

    .next-icon,
    .prev-icon {
        background-color: $color-primary-4;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: $base-spacing-4;
        height: $base-spacing-4;
        align-self: center;

        > .svg-icon {
            color: $color-white;
            fill: $color-white;
        }
    }

    .next-button {
        background: linear-gradient(90deg, rgba($color-primary-1, 0.3) 10%, rgba($color-primary-1, 0.6) 30%, rgba($color-primary-1, 1) 60%);
    }

    .prev-button {
        background: linear-gradient(-90deg, rgba($color-primary-1, 0.3) 10%, rgba($color-primary-1, 0.6) 30%, rgba($color-primary-1, 1) 60%);
        left: 0;
        justify-content: flex-start;
        padding-right: 0;
        padding-left: $base-spacing-2;
    }
}

.s-page-header + .s-anchor {
    margin: -32px $base-spacing-3 0;
    z-index: 200;
    border-radius: 32px;
}

@media (min-width: $screen-md) {
    section.s-anchor {
        .next-icon,
        .prev-icon {
            width: 45px;
            height: 45px;
        }
    }

    .s-page-header + .s-anchor {
        margin: -40px $base-spacing-3 0;
        border-radius: 40px;
    }
}

@media (min-width: $screen-lg) {
    section.s-anchor {
        @include container;
        width: auto;

        .anchor-wrapper {
            border-radius: $base-spacing-5;
        }
    }

    .s-page-header + .s-anchor {
        margin: -40px auto 0;
    }
}
