.sort-by {
    .select-wrapper {
        display: flex;
        align-items: center;

        .label-wrapper {
            margin-right: $base-spacing-2;

            label {
                font-weight: normal;
                margin-bottom: 0;
            }
        }
    }
}
