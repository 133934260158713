$btn-item-width: 204px;
$btn-item-height: 56px;

.c-link-item {
    .item-container {
        display: flex;
        align-items: flex-start;
        padding: $base-spacing-3 0;
    }

    .item-icon {
        flex: 0 0 auto;
        margin-right: $base-spacing-3;
        background-color: $color-secondary-shade-1;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: $base-spacing-9;
        width: $base-spacing-9;

        .svg-icon {
            height: $base-spacing-5;
            width: $base-spacing-5;
            color: $color-primary-1;
            cursor: default;
        }
    }

    .item-content {
        flex-grow: 1;
    }

    .item-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .svg-icon {
            margin-right: $base-spacing-1 / 2;
        }
    }

    .item-title {
        @include display-heading-medium;
        color: $color-black;
        margin-bottom: $base-spacing-3;
        margin-top: 20px;
    }

    .item-read-more-text {
        @include body-normal;
        color: $color-shade-2;
        padding-bottom: $base-spacing-2;

        > p {
            @include body-normal;
            color: $color-shade-2;
        }
    }
}

@media (min-width: $screen-xs) {
    .c-link-item {
        .item-btn {
            min-height: $btn-item-height;
            min-width: $btn-item-width;
            width: $btn-item-width;
        }
    }
}

@media (min-width: $screen-md) {
    .c-link-item {
        .item-content {
            display: flex;
            justify-content: space-between;
        }

        .item-title {
            margin-bottom: 0;
            margin-right: $base-spacing-2;
        }
    }
}

@media (min-width: $screen-md) {
    .c-link-item {
        .item-actions {
            padding-top: $base-spacing-1;
        }
    }
}
