// TEXT STYLES
//----------------------------------------
$body-font-2:           $Avenir-Light;
$body-font-3:           $Avenir-Medium;
$body-font-4:           $Avenir-Roman;
$body-font-5:           $Avenir-Heavy;

$link-color:            $color-primary-1;
$link-color-hover:      $color-primary-1;
$link-color-visited:    $color-primary-3;

// Intro
$intro-type: (
    null: (18px, 24px),
    sm: (24px, 38px)
);

$large-type: (
    null: (22px, 32px)
);

$base-type: (
    null: ($base-font-size, $base-line-height)
);

// Body
$normal-type: (
    null: ($base-font-size, $base-line-height),
    sm: (18px, 28px)
);

// Small
$small-type: (
    null: (15px, 22px)
);

// Used in footer 
$extra-small-type: (
    null: (12px, 20px)
);

$description-type: (
    null: (18px, 28px),
);

$footer-type: (
    null: (16px, 24px)
);

$tag-type: (
    null: (14px, 28px),
);

@mixin body-large {
    @include font-size($large-type);
    font-family: $body-font-4;
}

.body-large {
    @include body-large;
}

@mixin body-normal {
    @include font-size($normal-type);
}

.body-normal {
    @include body-normal;
}

p {
    @include body-normal;
    margin-bottom: $base-spacing-1;
}

@mixin body-normal-heavy {
    @include body-normal;
    font-family: $body-font-5;
}

@mixin body-normal-medium {
    @include body-normal;
    font-family: $body-font-3;
}

@mixin body-normal-light {
    @include body-normal;
    font-family: $body-font-2;
}

@mixin body-light {
    @include font-size($base-type);
    font-family: $body-font-2;
}

@mixin body-caption {
    @include font-size($small-type);
    font-family: $body-font-4;
}

@mixin body-footer {
    @include font-size($footer-type);
    font-family: $body-font-3;
    font-weight: 500;
}

.body-caption {
    @include body-caption;
}

@mixin body-small {
    @include font-size($small-type);
    font-family: $body-font-3;
    color: $color-black;
}

.body-small {
    @include body-small;
}

@mixin body-light-small {
    @include font-size($small-type);
    font-family: $body-font-2;
}

.body-light-small {
    @include body-light-small;
}

@mixin body-extra-small {
    @include font-size($extra-small-type);
    font-family: $body-font-3;
    color: $color-black;
}

.body-extra-small {
    @include body-extra-small;
}

@mixin body-small-heavy {
    @include font-size($small-type);
    font-family: $body-font-5;
    color: $color-black;
}

.body-small-heavy {
    @include body-small-heavy;
}

@mixin body-intro {
    @include font-size($intro-type);
}

.body-intro {
    @include body-intro;
}

@mixin body-description {
    @include font-size($description-type);
}

@mixin tag {
    @include font-size($tag-type);
}

a {
    text-decoration: underline;
    color: $link-color;
    cursor: pointer;

    &:hover,
    &:focus {
        color: $link-color-hover;
    }
}
