// // Lists
// //----------------------------------------
$list-item-padding:                 $base-spacing-3;
$list-item-margin:                  $base-spacing-1;
$list-bullet-width:                 $base-spacing-1;
$list-bullet-height:                $list-bullet-width;
$sublist-bullet-width:              $base-spacing-1;
$sublist-bullet-height:             2px;
$list-bullet-color:                 $color-primary-2;

// Reset lists
//----------------------------------------
@mixin list-reset {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        padding: 0;
        margin-bottom: 0;

        &::before {
            display: none;
            content: '';
        }
    }
}

ul,
ol {
    list-style: none;
    padding-left: 0;
    margin-left: 0;
    @include body-normal;

    li {
        margin-bottom: $list-item-margin;
    }
}

ul {
    li {
        position: relative;
        padding-left: $list-item-padding;

        &::before {
            position: absolute;
            left: 0;
            top: $list-item-margin;
            content: '';
            display: block;
            width: $list-bullet-width;
            height: $list-bullet-height;
            border-radius: 100%;
            background-color: $list-bullet-color;
        }
    }

    // 2nd level
    ul {
        li {
            &::before {
                top: calc(#{$list-item-margin} + 3px);
                width: $sublist-bullet-width;
                height: $sublist-bullet-height;
                border-radius: 0;
            }
        }
    }
}

ol {
    padding-left: $list-item-padding;
    counter-reset: section;
    list-style-position: inside;

    li {
        position: relative;

        &::before {
            position: absolute;
            left: -#{$list-item-padding};
            counter-increment: section;
            content: counters(section, ".") ".";
            color: $list-bullet-color;
            font-weight: normal;
        }
    }

    // 2nd level
    ol {
        margin-top: $list-item-margin;

        li {
            &::before {
                content: counter(section, upper-alpha);
            }
        }
    }
}
