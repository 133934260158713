.s-accordion {
    // styles as needed
}

@media (min-width: $screen-sm) {
    .s-accordion {
        .grid-wrapper {
            @include col(12);
        }
    }
}
