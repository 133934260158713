/*
 * Loader from SpinThatShit Library
 * (https://matejkustec.github.io/SpinThatShit/)
 *
 * NOTE: uses loader02 by default
*/
$loader-color:                      $color-primary-1;
$loader-size:                       $base-spacing-7; //56px
$loader-border-size:                $base-spacing-1; //8px
$loader-gap:                        $base-spacing-2; //16px
$loader-animation-duration:         1s;

@import "../../../node_modules/spinthatshit/src/loaders";

.c-loader {
    display: flex;
    align-items: center;
    flex-direction: column;

    .loader-icon {
        @include loader02;
        margin-bottom: $base-spacing-2;
    }

    .hide & {
        display: none !important;
    }
}
