$section-breaker-mobile-margin-top:             $base-spacing-3; //Should equal 48px
$section-breaker-mobile-margin-bottom:          $base-spacing-6;
$section-breaker-desktop-margin-top:            $base-spacing-2; //Should equal 48px
$section-breaker-desktop-margin-bottom:         $base-spacing-6;

.form-section-breaker {
    @include base-mobile-form-layout;
    border: none;
    border-bottom: 1px solid $color-primary-shade-2;
    margin-top: $section-breaker-mobile-margin-top;
    margin-bottom: $section-breaker-mobile-margin-bottom;
    padding: 0;
}

@media (min-width: $screen-sm) {
    .form-section-breaker {
        @include base-desktop-form-layout;
        margin-top: $section-breaker-desktop-margin-top;
        margin-bottom: $section-breaker-desktop-margin-bottom;
    }
}
