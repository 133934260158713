$btn-item-width: 204px;
$btn-item-height: 56px;

.c-download-item {
    .item-container {
        display: flex;
        align-items: flex-start;
        padding: $base-spacing-3 0;
    }

    .item-icon {
        flex-shrink: 0;
        margin-right: $base-spacing-3;
        background-color: $color-secondary-shade-1;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: $base-spacing-9;
        width: $base-spacing-9;

        .svg-icon {
            height: $base-spacing-5;
            width: $base-spacing-5;
            color: $color-primary-1;
            cursor: default;
        }
    }

    .item-content {
        flex-grow: 1;
    }

    .item-btn {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;

        + .item-btn {
            margin-top: $base-spacing-2;
        }

        > .svg-icon {
            color: $color-primary-1;
            margin-right: $base-spacing-1 / 2;
            flex-shrink: 0;
        }

        &:hover > .svg-icon {
            color: $color-white;
        }

        &:first-child {
            margin-left: 0;
        }
    }

    .item-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .item-title {
        @include display-heading-medium;
        color: $color-black;
        margin-bottom: 0;
        margin-top: 20px;
    }

    .item-read-more-link {
        margin-bottom: $base-spacing-3;

        a {
            color: $color-primary-1;
            text-decoration: none;
        }

        a:hover,
        a:focus {
            color: $color-primary-2;
        }

        a svg {
            color: $color-primary-1;
            vertical-align: text-bottom;
            transition: all 200ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
        }

        a.is-visible svg {
            transform: rotateZ(180deg);
        }

        span {
            color: $color-shade-2;
        }
    }

    .item-read-more-text {
        padding-bottom: $base-spacing-2;

        p {
            @include body-normal;
            color: $color-shade-2;
        }
    }
}

@media (min-width: $screen-xs) {
    .c-download-item {
        .item-btn {
            min-height: $btn-item-height;
            min-width: $btn-item-width;
            width: $btn-item-width;
            margin-left: $base-spacing-2;
            margin-top: 0;

            &:first-child {
                margin-left: 0;
            }

            + .item-btn {
                margin-top: 0;
            }
        }

        .item-actions-wrapper {
            display: flex;
        }
    }
}

@media (min-width: $screen-md) {
    .c-download-item {
        .item-content {
            flex-grow: 1;
            display: flex;
            justify-content: space-between;
        }

        .item-details {
            margin-right: $base-spacing-2;
        }

        .item-read-more-link {
            @include body-normal;
            margin-bottom: 0;
        }

        .item-read-more-text {
            padding-top: $base-spacing-3;
            padding-bottom: 0;

            p:last-of-type {
                margin-bottom: 0;
            }
        }

        .item-actions {
            flex-shrink: 0;
            padding-top: $base-spacing-1;
        }

        .item-actions-wrapper {
            display: flex;
            flex-direction: column;
        }

        .item-btn {
            + .item-btn {
                margin-left: 0;
                margin-top: $base-spacing-2;
            }
        }
    }
}
