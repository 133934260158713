$sitemap-item-padding:       $base-spacing-2;
$sitemap-icon-width:         $base-spacing-4;

$sitemap-border-color:       $color-shade-1;
$sitemap-icon-color:         $color-black;
$sitemap-link-hover-color:   $color-black;

.s-sitemap-kentico {
    ul {
        width: 100%;
        @include list-reset;

        li {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            box-shadow: inset 0 -1px 0 0 $sitemap-border-color;
            font-weight: bold;
            @include display-small;
            padding: 0 0 0 $sitemap-icon-width;

            &:last-child {
                box-shadow: none;
            }

            .svg-icon {
                color: $sitemap-icon-color;
                padding: 0;
                box-sizing: content-box;
                position: absolute;
                left: 0;
                top: $sitemap-item-padding;

                &:hover,
                &:focus {
                    outline: 1px dotted $color-shade-2;
                }
            }

            a {
                padding: $sitemap-item-padding $sitemap-item-padding $sitemap-item-padding 0;
                width: calc(100% - #{$sitemap-icon-width});

                &:hover,
                &:focus {
                    color: $sitemap-link-hover-color;
                }
            }

            &.is-open > .svg-icon {
                transform: rotateZ(180deg);
            }
        }

        ul {
            margin-left: 0;
            box-shadow: inset 0 1px 0 0 $sitemap-border-color;
        }
    }
}
