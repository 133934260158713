$block-fluid-tile-icon: $base-spacing-7;
$block-fluid-tile-icon-margin: $base-spacing-3;

.s-block-fluid {
    .block-heading-wrapper {
        margin-bottom: $base-spacing-6;

        > p {
            margin-top: $base-spacing-6;
        }
    }

    .block-heading {
        @include display-medium;
        width: 100%;
        position: relative;
        padding-bottom: $base-spacing-1;
        text-transform: unset;
    }

    .heading-line {
        position: absolute;
        left: 0;
        top: 100%;
        color: $color-primary-2;
        height: $base-spacing-3;
        width: auto;

        &.style-1 {
            width: 125px;
        }

        &.style-2 {
            width: 184px;
        }

        &.style-3 {
            width: 184px;
        }

        &.style-4 {
            width: 240px;
        }

        &.style-5 {
            width: 298px;
        }

        &.style-6 {
            width: 298px;
        }

        &.style-7 {
            width: 298px;
        }

        &.style-8 {
            width: 282px;
        }

        &.style-9 {
            width: 298px;
        }

        &.style-10 {
            width: 298px;
        }

        &.style-11 {
            width: 341px;
        }

        &.style-12 {
            width: 414px;
        }

        &.style-13 {
            width: 600px;
        }

        &.style-14 {
            width: 700px;
        }
    }

    .button-holder {
        margin-top: $base-spacing-6;
    }
}

@media (min-width: $screen-sm) {
    .s-block-fluid {
        .block-content {
            display: flex;
            column-gap: $base-spacing-2;
            flex-wrap: wrap;

            > * {
                width: calc(50% - 12px);
                margin-bottom: $base-spacing-6;
            }
        }

        .ktc-widget-body-wrapper & {
            .block-content {
                flex-wrap: wrap;

                > * {
                    @include col(3);
                }
            }
        }
    }
}

@media (min-width: $screen-md) {
    .s-block-fluid {
        .block-content {
            flex-wrap: nowrap;
            column-gap: $base-spacing-3;

            > * {
                flex: 1;
                width: auto;
                margin-bottom: 0;
            }
        }

        &.wrap-3 .block-content {
            flex-wrap: wrap;
            row-gap: $base-spacing-6;

            > * {
                flex: unset;
                width: 100%;
                max-width: 31%;
            }
        }
    }
}
