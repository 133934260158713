$subscription-invalid-color: $color-error;
$subscription-success-color: $color-success;

.c-newsletter-signup-form {
    .submit-wrapper {
        display: flex;

        .c-loader {
            margin-left: $base-spacing-3;
        }
    }

    .c-loader,
    .EditingFormErrorLabel {
        display: none;
        opacity: 0;
        transition: all 150ms ease-in-out;

        &.show {
            display: block;
            opacity: 1;
        }
    }

    .EditingFormValueCell .Error + .EditingFormErrorLabel {
        display: block;
        opacity: 1;
    }

    .response-msg {
        color: $subscription-success-color;
        display: none;
        margin-top: $base-spacing-3;
        margin-bottom: 0;
    }

    .response-msg.invalid,
    .response-msg.success {
        display: block;
    }

    .response-msg.invalid {
        color: $subscription-invalid-color;
    }
}
