.c-stats-container {
    padding: $base-spacing-3 0;

    .stats-info-text {
        margin-bottom: $base-spacing-6;
    }

    .stats-after-text {
        margin-top: $base-spacing-6;
    }

    .content-wrapper {
        display: flex;
        gap: $base-spacing-3;
        flex-wrap: wrap;

        > * {
            width: 100%;
            display: block;
        }
    }
}

@media (min-width: $screen-sm) {
    .c-stats-container {
        padding: $base-spacing-5 0;

        .content-wrapper {
            > * {
                width: calc(50% - 12px);
                display: block;

                &.full-width {
                    width: 100%;
                }
            }
        }
    }
}

@media (min-width: $screen-md) {
    .c-stats-container {
        padding: $base-spacing-8 0;

        .content-wrapper {
            gap: $base-spacing-5;

            > * {
                width: calc(50% - 24px);
                display: block;

                &.full-width {
                    width: 100%;
                }
            }
        }
    }
}
